export enum AxesChartName {
  EventsAttendeesChart = 'EventsAttendeesChart',
  EventsSpeakersChart = 'EventsSpeakersChart',
  IndividualCertifiedChart = 'IndividualCertifiedChart',
  IndividualTrainedChart = 'IndividualTrainedChart',
  VulnerabilitiesFixedChart = 'VulnerabilitiesFixedChart',
  MemberGrowthChart = 'MemberGrowthChart',
  NewMembersChart = 'NewMembersChart',
  OrganizationParticipationAttendeesChart = 'OrganizationParticipationAttendeesChart',
  OrganizationParticipationSpeakersChart = 'OrganizationParticipationSpeakersChart',
  EnrollmentChart = 'EnrollmentChart',
  LearnersChart = 'LearnersChart',
  IndividualEnrolledVsCertifiedChart = 'IndividualEnrolledVsCertifiedChart',
  EnrollmentsVsCertificatesIssuedChart = 'EnrollmentsVsCertificatesIssuedChart',
  RegisteredVsAttendedChart = 'RegisteredVsAttendedChart',
  RegisteredProjectsAndSubProjectsChart = 'RegisteredProjectsAndSubProjectsChart',
  WebinarsChart = 'WebinarsChart',
  TotalContributorsChart = 'TotalContributorsChart',
  ActiveContributorsChart = 'ActiveContributorsChart',
  CorporateContributorsChart = 'CorporateContributorsChart',
  IndependentContributorsChart = 'IndependentContributorsChart',
  UnaffiliatedContributorsChart = 'UnaffiliatedContributorsChart',
  EmailTotalParticipantsChart = 'EmailTotalParticipantsChart',
  EmailActiveParticipantsChart = 'EmailActiveParticipantsChart',
  EmailDriftingAwayParticipantsChart = 'EmailDriftingAwayParticipantsChart',
  EmailNewParticipantsChart = 'EmailNewParticipantsChart',
  ImpactByNewContributorsChart = 'ImpactByNewContributorsChart',
  ContributorAcquisitionImpactByNewContributorsChart = 'ContributorAcquisitionImpactByNewContributorsChart',
  ParticipantOrganizationEngagementDriftingAwayChart = 'ParticipantOrganizationEngagementDriftingAwayChart',
  ParticipantOrganizationEngagementNewVsExistingOrgChart = 'ParticipantOrganizationEngagementNewVsExistingOrgChart',
  ParticipantOrganizationEngagementByCorporateVsIndependentChart = 'ParticipantOrganizationEngagementByCorporateVsIndependentChart',
  NewContributorsChart = 'NewContributorsChart',
  TotalUnaffiliatedChart = 'TotalUnaffiliatedChart',
  UnaffiliatedAndActiveChart = 'UnaffiliatedAndActiveChart',
  ContributorStrengthPrContributorChart = 'ContributorStrengthPrContributorChart',
  ContributorStrengthIssueContributorChart = 'ContributorStrengthIssueContributorChart',
  ContributorStrengthUnaffiliatedContributorChart = 'ContributorStrengthUnaffiliatedContributorChart',
  ContributorStrengthNewContributorChart = 'ContributorStrengthNewContributorChart',
  ContributorStrengthDriftingAwayContributorChart = 'ContributorStrengthDriftingAwayContributorChart',
  ContributorStrengthCommitContributorChart = 'ContributorStrengthCommitContributorChart',
  TrendsCommitContributorChart = 'TrendsCommitContributorChart',
  CommitAnalysisCommitTotalCommitsChart = 'CommitAnalysisCommitTotalCommitsChart',
  CommitAnalysisCommitActiveCommitsChart = 'CommitAnalysisCommitActiveCommitsChart',
  CommitAnalysisCommitActiveCommitsContributorsChart = 'CommitAnalysisCommitActiveCommitsContributorsChart',
  CommitAnalysisCommitActiveCommitsOrganizationsChart = 'CommitAnalysisCommitActiveCommitsOrganizationsChart',
  CommitAnalysisCodeVsMergeChart = 'CommitAnalysisCodeVsMergeChart',
  CommitAnalysisPairProgrammingCommitsChart = 'CommitAnalysisPairProgrammingCommitsChart',
  ProjectTrendLOCAdded = 'ProjectTrendLOCAdded',
  ProjectTrendLOCDeleted = 'ProjectTrendLOCDeleted',
  ContributorsChurnedChart = 'ContributorsChurnedChart',
  TotalPRsSubmittedVsMergedChart = 'TotalPRsSubmittedVsMergedChart',
  PRsSubmittedVsMergedChart = 'PRsSubmittedVsMergedChart',
  ActivePRSubmittersChart = 'ActivePRSubmittersChart',
  AverageLeadTimeToMergeChart = 'AverageLeadTimeToMergeChart',
  TimeInReviewChart = 'TimeInReviewChart',
  PREfficiencyForNewContributorsChart = 'PREfficiencyForNewContributorsChart',
  IssueRequestPipelineAvgTimeToResolveChart = 'IssueRequestPipelineAvgTimeToResolveChart',
  NewSubmittersAndAssigneesChart = 'NewSubmittersAndAssigneesChart',
  ActiveRepositoriesChart = 'ActiveRepositoriesChart',
  RepositoriesForks = 'RepositoriesForks',
  TotalIssuesSubmittedVsResolvedChart = 'TotalIssuesSubmittedVsResolvedChart',
  NewIssuesLoggedVsResolvedChart = 'NewIssuesLoggedVsResolvedChart',
  AcknowledgementTimeChart = 'AcknowledgementTimeChart',
  SubmittersVsAssigneesChart = 'SubmittersVsAssigneesChart',
  IssuesUnderActiveDevelopmentVsInBacklogChart = 'IssuesUnderActiveDevelopmentVsInBacklogChart',
  PRWaitingChart = 'PRWaitingChart',
  IssueWaitingChart = 'IssueWaitingChart',
  InActiveRepositoriesChart = 'InActiveRepositoriesChart',
  WatchersVSStarsChart = 'WatchersVSStarsChart',
  FeatureAndBugRequestsChart = 'FeatureAndBugRequestsChart',
  ProjectsAcceptedChart = 'ProjectsAcceptedChart',
  ContributorStrengthContributorsChart = 'ContributorStrengthContributorsChart',
  DocumentTotalParticipantsChart = 'DocumentTotalParticipantsChart',
  DocumentActiveChart = 'DocumentActiveChart',
  DocumentDriftAwayChart = 'DocumentDriftAwayChart',
  DocumentNewParticipantsChart = 'DocumentNewParticipantsChart',
  projectsByHealthSegmentsAll = 'projectsByHealthSegmentsAll',
  projectsByHealthSegmentsGraduated = 'projectsByHealthSegmentsGraduated',
  projectsByHealthSegmentsIncubating = 'projectsByHealthSegmentsIncubating',
  projectsByHealthSegmentsSandbox = 'projectsByHealthSegmentsSandbox',
  DocumentActivitiesChart = 'DocumentActivitiesChart',
  DocumentTotalParticipatingOrganizationsChart = 'DocumentTotalParticipatingOrganizationsChart',
  DocumentActiveOrganizationsChart = 'DocumentActiveOrganizationsChart',
  DocumentDriftAwayOrganizationsChart = 'DocumentDriftAwayOrganizationsChart',
  DocumentNewOrganizationsChart = 'DocumentNewOrganizationsChart',
  // bubble charts start
  EventAttendeesByJobFunctionChart = 'EventAttendeesByJobFunctionChart',
  EventAttendeesByOrganizationChart = 'EventAttendeesByOrganizationChart',
  EventSpeakersByJobFunctionChart = 'EventSpeakersByJobFunctionChart',
  EventSpeakersByOrganizationChart = 'EventSpeakersByOrganizationChart',
  PopularProjectsByMembershipsChart = 'PopularProjectsByMembershipsChart',
  TrainingMostEngagedOrganizationsChart = 'TrainingMostEngagedOrganizationsChart',
  CertificationsMostEngagedOrganizationsChart = 'CertificationsMostEngagedOrganizationsChart',
  PopularProjectsPerWebinarsChart = 'PopularProjectsPerWebinarsChart',
  TechnicalContributionsChart = 'TechnicalContributionsChart',
  EmailMostEngagedOrganizationsChart = 'EmailMostEngagedOrganizationsChart',
  ParticipantOrganizationEngagementByCommitChart = 'ParticipantOrganizationEngagementByCommitChart',
  ParticipantOrganizationEngagementByPRActivityChart = 'ParticipantOrganizationEngagementByPRActivityChart',
  ParticipantOrganizationEngagementByBugFeatureRequestChart = 'ParticipantOrganizationEngagementByBugFeatureRequestChart',
  ContributorStrengthTechnicalContributionChart = 'ContributorStrengthTechnicalContributionChart',
  MostActiveNewOrganizationsChart = 'MostActiveNewOrganizationsChart',
  CommitAnalysisProgrammingLanguagesUsedChart = 'CommitAnalysisProgrammingLanguagesUsedChart',
  ChangesetActivitiesChart = 'ChangesetActivitiesChart',
  DocumentMostEngagedOrganizationsChart = 'DocumentMostEngagedOrganizationsChart',
  DocumentPopularPagesChart = 'DocumentPopularPagesChart',
  DocumentPopularSpacesChart = 'DocumentPopularSpacesChart',
  DocumentTopOrganizationChart = 'DocumentTopOrganizationChart',
  // bubble charts end
}
