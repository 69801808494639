import { GaugeChartApiDataSource } from './api-data-source';
import { MarkPoint, MarkPointConfig } from './mark-point';
import { GaugeChartMockDataSource } from './mock-data-source';

export interface GaugeChartConfig {
  chart: {
    dataCategories: { color: string; percentage: number }[];
    mapValueToCategory?: (value: number) => number;
    markPoint?: any | MarkPoint;
  };
  data?: GaugeChartMockDataSource | GaugeChartApiDataSource;
  markPointConfig?: MarkPointConfig[];
}
