/* eslint-disable prettier/prettier */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivationEnd, NavigationStart, Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { datadogRum } from '@datadog/browser-rum';
import { environment } from '@environment';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map, take, tap } from 'rxjs/operators';
import { UserService } from './core/services';

import { ObservationsService } from './core/services/observations.service';
import { ProjectService } from './core/services/project.service';

@Component({
  selector: 'lfx-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'insights-ui1';
  subscription!: Subscription;
  prevUrl = '';
  isTrendsPage = false;

  private isProjectLoaded: boolean = false;
  private isSingleLoading: boolean = false;

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private router: Router,
    public projectService: ProjectService,
    private observationsService: ObservationsService
  ) {}

  ngOnInit() {
    this.router.events
      .pipe(
        filter(e => e instanceof NavigationStart),
        tap(_ => {
          this.observationsService.resetObservations();
        })
      )
      .subscribe(p => {});
    this.router.events
      .pipe(
        filter(e => e instanceof ActivationEnd),
        // distinctUntilChanged(),
        distinctUntilChanged(() => this.router.url === this.prevUrl),
        tap(_ => {
          this.prevUrl = this.router.url;
        }),
        map(e => (e instanceof ActivationEnd ? e.snapshot.params : {}))
      )
      .subscribe(params => {
        this.projectService.getEnabledConnectors();
        // reset the flag so that subsequent router change will go through the normal handler
        this.isSingleLoading = false;
        this.isProjectLoaded = false;

        this.projectService.projectsLoaded$.subscribe(isLoaded => {
          // TODO: need to find a way to load the projects on it's own if the project summary list is still empty
          if (isLoaded) {
            // skipping this if the flag to handle it separately was set before
            if (!this.isSingleLoading && params.projectSlug) {
              this.projectService.setSelectedProject(params.projectSlug);
              this.isProjectLoaded = true;
            }
          } else if (params.projectSlug) {
            this.isSingleLoading = true;
            // fetching a single project
            this.projectService
              .getProjectSummaryBySlug(params.projectSlug)
              .pipe(take(1))
              .subscribe(response => {
                this.isProjectLoaded = true;
                const project = response?.projectsSummaryData[0];

                this.projectService.setProjectFromSingleQuery(
                  project,
                  params.projectSlug
                );
              });
          }
        });

        if (!params.projectSlug) {
          this.projectService.resetSelectedSlug();
        }

        if (
          this.router.url === '/' ||
          this.router.url.indexOf('/?') >= 0 ||
          this.router.url.includes('trends')
        ) {
          this.isTrendsPage = true;
        } else {
          this.isTrendsPage = false;
        }
      });
    this.checkLFStaff();
    this.loadLFxHeaderScriptAndLinks();
    this.loadLFxFooterScriptAndLinks();

    this.projectService.isSideNavCollapsed.subscribe(isSideNavCollapsed => {
      const routerContainer = document.getElementById('router-container');

      if (routerContainer) {
        if (isSideNavCollapsed) {
          routerContainer.style.maxWidth = 'calc(100vw - 60px)';
          routerContainer.style.marginLeft = '60px';
        } else {
          routerContainer.style.maxWidth = 'calc(100vw - 265px)';
          routerContainer.style.marginLeft = '265px';
        }
      }
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  private checkLFStaff() {
    this.userService.checkUserHasRole('lf-staff').subscribe(res => {
      this.projectService.isLFStaffUser = res?.hasPermission;
    });
  }

  private loadLFxHeaderScriptAndLinks() {
    const script = document.createElement('script');

    script.setAttribute('src', environment.lfxHeaderCDN);
    document.head.appendChild(script);

    const header: any = document.getElementById('lfx-header');

    if (header) {
      header.product = 'LF DA';
      header.docslink =
        'https://docs.linuxfoundation.org/lfx/insights/v2-current';
      header.supportlink =
        'https://jira.linuxfoundation.org/plugins/servlet/desk/portal/4?requestGroup=54';
      header.links = [
        {
          title: 'Enroll Project',
          url: 'https://jira.linuxfoundation.org/plugins/servlet/theme/portal/4/create/341',
          target: '_blank',
        },
      ];

      header.beforeLogout = function () {
        window.localStorage.removeItem('accessIdentity');
      };

      this.authService.user$.subscribe((data: any) => {
        this.loadDataDogScript();

        if (data) {
          header.authuser = data;

          datadogRum.setUser({
            id: data['https://sso.linuxfoundation.org/claims/username'],
          });
        }
      });
    }
  }

  private loadLFxFooterScriptAndLinks() {
    const script = document.createElement('script');

    script.setAttribute('src', environment.lfxFooterCDN);
    document.head.appendChild(script);
    // TODO
    // this.renderer.setProperty(this.footer.nativeElement, 'innerHTML', '<lfx-footer></lfx-footer>');
  }

  private loadDataDogScript() {
    if (!datadogRum.getInitConfiguration()) {
      datadogRum.init({
        applicationId: 'aa2bd567-8ea8-4ef9-a172-a4f8d875d7ab',
        clientToken: 'pub607f753f07bb58097324484336fadc58',
        site: 'datadoghq.com',
        service: 'lfx-insights',
        env: environment.datadogEnv,
        sampleRate: environment.datadogEnv ? 100 : 0,
        premiumSampleRate: environment.datadogEnv ? 100 : 0,
        trackInteractions: true,
        trackFrustrations: true,
        allowedTracingOrigins: environment.traceOrigins,
      });

      datadogRum.startSessionReplayRecording();
    }
  }
}
