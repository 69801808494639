import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  ProjectOrganizationMembershipsServiceGql,
  ProjectOrganizationMembershipsInput,
} from '../gql';
import { ProjectOrganizationMemberships } from '../models';

@Injectable({
  providedIn: 'root',
})
export class ProjectOrganizationMembershipsDataService {
  constructor(
    private projectOrganizationMembershipsServiceGql: ProjectOrganizationMembershipsServiceGql
  ) {}

  getProjectOrganizationMemberships(
    projectOrganizationsInput: ProjectOrganizationMembershipsInput
  ): Observable<ProjectOrganizationMemberships[]> {
    return this.projectOrganizationMembershipsServiceGql.getProjectOrganizationMemberships(
      projectOrganizationsInput
    );
  }

  getContributingOrganizations(): Observable<string[]> {
    return this.projectOrganizationMembershipsServiceGql.getContributingOrganizations();
  }
}
