// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT
import { Injectable } from '@angular/core';
import _ from 'lodash';
import { Observable } from 'rxjs';

import { ProjectHealthServiceGql } from '../gql';

@Injectable({
  providedIn: 'root',
})
export class ProjectHealthService {
  constructor(private projectHealthServiceGql: ProjectHealthServiceGql) {}

  getProjectHealthDetails(projectId: string): Observable<any> {
    return this.projectHealthServiceGql.getProjectHealthDetails(projectId);
  }

  getProjectHealthBestPracticesScore(projectId: string): Observable<any> {
    return this.projectHealthServiceGql.getProjectHealthBestPracticesScore(
      projectId
    );
  }

  getProjectHealthRepositories(projectId: string): Observable<any> {
    return this.projectHealthServiceGql.getProjectHealthRepositories(projectId);
  }

  getProjectHealthRepositoryDetailsByRepositoryId(
    repoId: string
  ): Observable<any> {
    return this.projectHealthServiceGql.getProjectHealthRepositoryDetailsByRepositoryId(
      repoId
    );
  }

  getProjectRepositoryHealth(projectId: string): Observable<any> {
    return this.projectHealthServiceGql.getProjectRepositoryHealth(projectId);
  }
}
