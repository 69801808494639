export class MarkPoint {
  title!: string;
  value!: string;
  isIncrease!: boolean | undefined;
  percentage!: string;
  additionalText?: string;
  enable: boolean = true;
  position?: { left: string; bottom: string };
  constructor() {
    this.position = {
      left: '8px',
      bottom: '20px',
    };
  }
}

export enum ValueType {
  AVG = 'AVG',
  Total = 'Total',
}
export interface MarkPointConfig {
  name: string;
  endingText: string;
  position?: markPointPosition;
  value: ValueType.AVG | ValueType.Total;
  isDriftAway?: boolean;
}

export interface markPointPosition {
  x: string;
  y: string;
}
