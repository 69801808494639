// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { gql } from 'apollo-angular';

export const currentUserQuery = gql`
  query currentUser(
    $withPermissions: Boolean = false
    $withSocialAccounts: Boolean = false
    $withEmails: Boolean = false
    $withAvatar: Boolean = false
    $withRoles: Boolean = false
    $basic: Boolean = true
  ) {
    currentUser(basic: $basic) {
      id
      auth0Id
      username
      firstName
      lastName
      fullName
      primaryEmail
      timezone
      avatarUrl @include(if: $withAvatar)
      roles @include(if: $withRoles)
      emails @include(if: $withEmails) {
        emailAddress
        emailType
        active
        isDeleted
        isPrimary
        isVerified
      }
      permissions @include(if: $withPermissions) {
        context
        actions
        resource
        scopes {
          id
        }
      }
      socialAccounts @include(if: $withSocialAccounts) {
        userId
        provider
        connection
        isSocial
      }
    }
  }
`;
