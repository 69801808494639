<div
  class="
    insigts-side-nav
    border border-lf-gray-7-e0
    bg-lf-gray-20-33
    fixed
    overflow-y-scroll
    h-[calc(100%-103px)]
  "
  [ngClass]="isSideNavCollapsed ? 'w-[60px]' : 'w-[265px]'"
>
  <!-- Commented since the insights logo is appearing on the lfx header component. -->
  <!-- <div
    class="
      h-[54px]
      px-[15px]
      bg-lf-blue-9cc
      text-white
      flex
      text-[16px]
      items-center
    "
  >
    <img src="/assets/icons/insights_icon.svg" />
    <span class="p-[15px] capitalize">Insights</span>
  </div> -->
  <div>
    <!-- GLOBAL MENU SECTION -->
    <!-- Removed as log in ticket: #IN-1926 -->
    <!-- <p class="text-lf-blue ml-5 my-4 text-sm-13 font-semibold">Global</p> -->
    <div *ngFor="let navItem of globalMenu">
      <ng-container
        [ngTemplateOutlet]="menuTemplate"
        [ngTemplateOutletContext]="{ navItem: navItem }"
      ></ng-container>
    </div>

    <!-- ALL PROJECTS SECTION -->
    <!-- Updated as per Nirav's comment ref: #IN-1801 -->
    <div
      class="
        flex
        items-center
        text-[13px]
        h-[38px]
        bg-lf-gray-20-33
        px-[19px]
        text-white text-opacity-70
        cursor-pointer
        hover:bg-lf-blue
        hover:bg-opacity-20
        hover:text-white
        hover:text-opacity-0;
      "
      [ngClass]="{
        'pl-[16px] border-l-3 border-lf-orange-100 text-lf-blue':
          allProjectsMenu[0].active
      }"
      (click)="activateNavItem(allProjectsMenu[0])"
      [routerLink]="allProjectsMenu[0].link"
      queryParamsHandling="merge"
    >
      <div class="flex items-center">
        <div class="w-[40px]">
          <img
            [src]="
              '/assets/icons/' +
              (allProjectsMenu[0].active
                ? allProjectsMenu[0].activeIcon
                : allProjectsMenu[0].icon)
            "
            [title]="allProjectsMenu[0].title || ''"
          />
        </div>
        <span
          [ngClass]="{ 'text-white': allProjectsMenu[0].active }"
          class="collapse-this"
        >
          {{ allProjectsMenu[0].title }}</span
        >
      </div>
    </div>

    <hr class="m-4 border-lf-gray-450 opacity-80" />

    <!-- Removed dropdown ref: #IN-1801 -->

    <!-- <div class="px-[20px]">
      <ng-select
        [items]="options"
        bindLabel="name"
        bindValue="key"
        [clearable]="false"
        [(ngModel)]="selectedOption"
        (change)="changeOption()"
      >
      </ng-select>
    </div> -->

    <ng-container *ngIf="isEventsSelected">
      <label
        class="relative focus-within:text-lf-gray-20-33 block px-5 py-[10px]"
      >
        <svg
          version="1.1"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          width="16px"
          height="16px"
          class="
            pointer-events-none
            w-4
            h-4
            absolute
            top-1/2
            transform
            -translate-y-1/2
            left-4
            ml-3
          "
          xmlns="http://www.w3.org/2000/svg"
        >
          <g transform="matrix(1 0 0 1 -30 -590 )">
            <path
              d="M 15.133928571428571 14.461309523809524  C 15.273809523809524 14.321428571428571  15.273809523809524 14.095238095238095  15.133928571428571 13.955357142857142  L 11.520833333333334 10.342261904761905  C 11.452380952380953 10.273809523809524  11.363095238095237 10.238095238095237  11.267857142857142 10.238095238095237  L 10.875000000000002 10.238095238095237  C 11.812500000000002 9.151785714285714  12.380952380952383 7.738095238095238  12.380952380952383 6.190476190476191  C 12.380952380952381 2.7708333333333335  9.610119047619047 0  6.190476190476191 0  C 2.770833333333334 0  0 2.7708333333333335  0 6.190476190476191  C 0 9.610119047619047  2.7708333333333335 12.380952380952381  6.190476190476191 12.380952380952381  C 7.738095238095238 12.380952380952381  9.151785714285714 11.8125  10.238095238095237 10.875  L 10.238095238095237 11.267857142857142  C 10.238095238095237 11.363095238095237  10.276785714285714 11.45238095238095  10.342261904761905 11.520833333333332  L 13.955357142857142 15.133928571428571  C 14.095238095238095 15.273809523809524  14.321428571428571 15.273809523809524  14.461309523809524 15.133928571428571  L 15.133928571428571 14.461309523809524  Z M 10.952380952380953 6.190476190476191  C 10.952380952380953 8.821428571428571  8.821428571428571 10.952380952380953  6.190476190476191 10.952380952380953  C 3.5595238095238093 10.952380952380953  1.4285714285714286 8.821428571428571  1.4285714285714286 6.190476190476191  C 1.4285714285714286 3.55952380952381  3.5595238095238093 1.4285714285714286  6.190476190476191 1.4285714285714286  C 8.821428571428571 1.4285714285714286  10.952380952380953 3.5595238095238093  10.952380952380953 6.190476190476191  Z "
              fill-rule="nonzero"
              fill="#807f83"
              stroke="none"
              transform="matrix(1 0 0 1 30 590 )"
            />
          </g>
        </svg>

        <ng-select
          [items]="events$ | async"
          placeholder="Search Events..."
          bindLabel="name"
          class="searchable_select"
          (change)="selectEvent($event)"
          (clear)="clearEvent()"
          [clearable]="true"
          [(ngModel)]="selectedEvent"
          [typeahead]="eventsInput$"
          [loading]="eventLoading"
        >
          <ng-template ng-option-tmp let-item="item">
            <div class="px-[12px] py-[5px] text-lf-grey-20-33 text-[13px]">
              {{ item.name }}
            </div>
          </ng-template>
        </ng-select>
      </label>
    </ng-container>

    <ng-container *ngIf="isTrainingAndCertSelected">
      <label
        class="relative focus-within:text-lf-gray-20-33 block px-5 py-[10px]"
      >
        <svg
          version="1.1"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          width="16px"
          height="16px"
          class="
            pointer-events-none
            w-4
            h-4
            absolute
            top-1/2
            transform
            -translate-y-1/2
            left-4
            ml-3
          "
          xmlns="http://www.w3.org/2000/svg"
        >
          <g transform="matrix(1 0 0 1 -30 -590 )">
            <path
              d="M 15.133928571428571 14.461309523809524  C 15.273809523809524 14.321428571428571  15.273809523809524 14.095238095238095  15.133928571428571 13.955357142857142  L 11.520833333333334 10.342261904761905  C 11.452380952380953 10.273809523809524  11.363095238095237 10.238095238095237  11.267857142857142 10.238095238095237  L 10.875000000000002 10.238095238095237  C 11.812500000000002 9.151785714285714  12.380952380952383 7.738095238095238  12.380952380952383 6.190476190476191  C 12.380952380952381 2.7708333333333335  9.610119047619047 0  6.190476190476191 0  C 2.770833333333334 0  0 2.7708333333333335  0 6.190476190476191  C 0 9.610119047619047  2.7708333333333335 12.380952380952381  6.190476190476191 12.380952380952381  C 7.738095238095238 12.380952380952381  9.151785714285714 11.8125  10.238095238095237 10.875  L 10.238095238095237 11.267857142857142  C 10.238095238095237 11.363095238095237  10.276785714285714 11.45238095238095  10.342261904761905 11.520833333333332  L 13.955357142857142 15.133928571428571  C 14.095238095238095 15.273809523809524  14.321428571428571 15.273809523809524  14.461309523809524 15.133928571428571  L 15.133928571428571 14.461309523809524  Z M 10.952380952380953 6.190476190476191  C 10.952380952380953 8.821428571428571  8.821428571428571 10.952380952380953  6.190476190476191 10.952380952380953  C 3.5595238095238093 10.952380952380953  1.4285714285714286 8.821428571428571  1.4285714285714286 6.190476190476191  C 1.4285714285714286 3.55952380952381  3.5595238095238093 1.4285714285714286  6.190476190476191 1.4285714285714286  C 8.821428571428571 1.4285714285714286  10.952380952380953 3.5595238095238093  10.952380952380953 6.190476190476191  Z "
              fill-rule="nonzero"
              fill="#807f83"
              stroke="none"
              transform="matrix(1 0 0 1 30 590 )"
            />
          </g>
        </svg>

        <ng-select
          [items]="trainingAndCerts$ | async"
          placeholder="Search Training..."
          bindLabel="name"
          class="searchable_select"
          (change)="selectedTraining($event)"
          [typeahead]="trainingAndCertsInput$"
          [loading]="trainingAndCertLoading"
        >
          <ng-template ng-option-tmp let-item="item">
            <div class="px-[12px] py-[5px] text-lf-grey-20-33 text-[13px]">
              {{ item.name }}
            </div>
          </ng-template>
        </ng-select>
      </label>
    </ng-container>

    <div *ngIf="isEventsSelected">
      <div *ngFor="let navItem of eventMenu">
        <ng-container
          [ngTemplateOutlet]="menuTemplate"
          [ngTemplateOutletContext]="{ navItem: navItem, level: 0 }"
        >
        </ng-container>
      </div>
    </div>

    <div *ngIf="isTrainingAndCertSelected">
      <div *ngFor="let navItem of trainingMenu">
        <ng-container
          [ngTemplateOutlet]="menuTemplate"
          [ngTemplateOutletContext]="{ navItem: navItem, level: 0 }"
        >
        </ng-container>
      </div>
    </div>

    <ng-container>
      <!-- Project search field -->
      <label
        class="
          relative
          focus-within:text-lf-gray-20-33
          block
          px-5
          py-[10px]
          collapse-this
        "
      >
        <svg
          version="1.1"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          width="16px"
          height="16px"
          class="
            pointer-events-none
            w-4
            h-4
            absolute
            top-1/2
            transform
            -translate-y-1/2
            left-4
            ml-3
          "
          xmlns="http://www.w3.org/2000/svg"
        >
          <g transform="matrix(1 0 0 1 -30 -590 )">
            <path
              d="M 15.133928571428571 14.461309523809524  C 15.273809523809524 14.321428571428571  15.273809523809524 14.095238095238095  15.133928571428571 13.955357142857142  L 11.520833333333334 10.342261904761905  C 11.452380952380953 10.273809523809524  11.363095238095237 10.238095238095237  11.267857142857142 10.238095238095237  L 10.875000000000002 10.238095238095237  C 11.812500000000002 9.151785714285714  12.380952380952383 7.738095238095238  12.380952380952383 6.190476190476191  C 12.380952380952381 2.7708333333333335  9.610119047619047 0  6.190476190476191 0  C 2.770833333333334 0  0 2.7708333333333335  0 6.190476190476191  C 0 9.610119047619047  2.7708333333333335 12.380952380952381  6.190476190476191 12.380952380952381  C 7.738095238095238 12.380952380952381  9.151785714285714 11.8125  10.238095238095237 10.875  L 10.238095238095237 11.267857142857142  C 10.238095238095237 11.363095238095237  10.276785714285714 11.45238095238095  10.342261904761905 11.520833333333332  L 13.955357142857142 15.133928571428571  C 14.095238095238095 15.273809523809524  14.321428571428571 15.273809523809524  14.461309523809524 15.133928571428571  L 15.133928571428571 14.461309523809524  Z M 10.952380952380953 6.190476190476191  C 10.952380952380953 8.821428571428571  8.821428571428571 10.952380952380953  6.190476190476191 10.952380952380953  C 3.5595238095238093 10.952380952380953  1.4285714285714286 8.821428571428571  1.4285714285714286 6.190476190476191  C 1.4285714285714286 3.55952380952381  3.5595238095238093 1.4285714285714286  6.190476190476191 1.4285714285714286  C 8.821428571428571 1.4285714285714286  10.952380952380953 3.5595238095238093  10.952380952380953 6.190476190476191  Z "
              fill-rule="nonzero"
              fill="#807f83"
              stroke="none"
              transform="matrix(1 0 0 1 30 590 )"
            />
          </g>
        </svg>

        <ng-template ng-optgroup-tmp let-item="item">
          {{ item.country || 'Unnamed group' }}
        </ng-template>

        <ng-select
          [placeholder]="'Search Projects…'"
          class="searchable_select"
          (change)="openProject($event)"
          (clear)="redirectToGlobal()"
          [typeahead]="projectsInput$"
          [loading]="projectLoading"
          [(ngModel)]="selectedProject"
        >
          <ng-template ng-label-tmp let-item="item">
            {{ item.name }}
          </ng-template>
          <ng-template
            ngFor
            let-optionType
            [ngForOf]="
              projects
                | lfxFilterItems: 'status':ProjectStatus.Active
                | lfxFilterItems: 'entityType':ProjectEntityType.Internal:true
            "
          >
            <ng-option [value]="optionType">
              <!-- Parent Project Option -->
              <div
                class="px-[12px] py-[5px] text-lf-grey-20-33 text-[13px]"
                [title]="
                  !optionType.isOnBoarded
                    ? 'Project is not configured'
                    : optionType.name
                "
                [ngClass]="{
                  disabled: !optionType.isOnBoarded
                }"
              >
                {{ optionType?.name || '' }}
              </div>
            </ng-option>

            <!-- Child Project Option -->
            <ng-option
              *ngFor="
                let childProject of optionType.projects
                  | lfxFilterItems: 'status':ProjectStatus.Active
                  | lfxFilterItems: 'entityType':ProjectEntityType.Internal:true
              "
              [value]="childProject"
            >
              <div
                class="
                  pr-[12px]
                  py-[5px]
                  text-lf-grey-20-33 text-[13px]
                  pl-[24px]
                "
                [title]="
                  !childProject.isOnBoarded
                    ? 'Project is not configured'
                    : childProject.name
                "
                [ngClass]="{
                  disabled: !childProject.isOnBoarded
                }"
              >
                {{ childProject?.name || '' }}
              </div>
            </ng-option>

            <!-- Grand Child project Option -->
            <ng-template
              ngFor
              let-childProject
              [ngForOf]="optionType?.projects || []"
            >
              <ng-option
                *ngFor="
                  let grandChildProject of childProject?.projects
                    | lfxFilterItems: 'status':ProjectStatus.Active
                    | lfxFilterItems
                      : 'entityType'
                      : ProjectEntityType.Internal
                      : true
                "
                [value]="grandChildProject"
              >
                <div
                  class="
                    pr-[12px]
                    py-[5px]
                    text-lf-grey-20-33 text-[13px]
                    pl-[48px]
                  "
                  [title]="
                    !grandChildProject.isOnBoarded
                      ? 'Project is not configured'
                      : grandChildProject.name
                  "
                  [ngClass]="{
                    disabled: !grandChildProject.isOnBoarded
                  }"
                >
                  {{ grandChildProject?.name || '' }}
                </div>
              </ng-option>
              <!-- Grand Grand Child project Option -->
              <ng-template
                ngFor
                let-grandChildProject
                [ngForOf]="childProject?.projects || []"
              >
                <ng-option
                  *ngFor="
                    let grandGrandChildProject of grandChildProject?.projects
                      | lfxFilterItems: 'status':ProjectStatus.Active
                      | lfxFilterItems
                        : 'entityType'
                        : ProjectEntityType.Internal
                        : true
                  "
                  [value]="grandGrandChildProject"
                >
                  <div
                    class="
                      pr-[12px]
                      py-[5px]
                      text-lf-grey-20-33 text-[13px]
                      pl-[61px]
                    "
                    [title]="
                      !grandGrandChildProject.isOnBoarded
                        ? 'Project is not configured'
                        : grandGrandChildProject.name
                    "
                    [ngClass]="{
                      disabled: !grandGrandChildProject.isOnBoarded
                    }"
                  >
                    {{ grandGrandChildProject?.name || '' }}
                  </div>
                </ng-option>
              </ng-template>
            </ng-template>
          </ng-template>
        </ng-select>
      </label>

      <!-- PROJECT MENU SECTION -->
      <p class="text-lf-blue mx-4 my-4 text-sm-13 font-semibold collapse-this">
        {{ selectedProject?.name }}
      </p>
      <ng-container *ngIf="selectedProject">
        <div *ngFor="let navItem of projectMenu" [hidden]="navItem.hide">
          <ng-container
            [ngTemplateOutlet]="menuTemplate"
            [ngTemplateOutletContext]="{ navItem: navItem, level: 0 }"
          >
          </ng-container>
        </div>
      </ng-container>
    </ng-container>
  </div>

  <div
    class="absolute right-0 bottom-[15px] text-white bg-lf-blue rounded-l-[5px]"
  >
    <span
      class="
        flex
        justify-center
        items-center
        text-sm
        h-10
        w-5
        cursor-pointer
        opacity-90
      "
      (click)="collapseSideNav()"
    >
      <i class="fa-solid fa-chevrons-left" *ngIf="!isSideNavCollapsed"></i>
      <i class="fa-solid fa-chevrons-right" *ngIf="isSideNavCollapsed"></i>
    </span>
  </div>
</div>

<ng-template let-navItem="navItem" let-level="level" #menuTemplate>
  <div
    class="
      flex
      items-center
      text-[13px]
      py-[10px]
      bg-lf-gray-20-33
      px-[19px]
      text-white text-opacity-70
      cursor-pointer
      hover:bg-lf-blue
      hover:bg-opacity-10
      hover:text-white
      hover:text-opacity-100;
    "
    [ngClass]="{
      'pl-[16px] border-l-3 border-lf-orange-100 text-lf-blue bg-lf-blue bg-opacity-20':
        navItem.active && !navItem.subItems?.length,
      'justify-between': navItem.subItems?.length
    }"
    [ngStyle]="{
      'padding-left':
        (level === 0
          ? 19
          : level === 1 && !comingSoonItems.includes(navItem.title)
          ? 55
          : 19 + (level - 1) * 55) + 'px'
    }"
    (click)="activateNavItem(navItem)"
    [routerLink]="navItem.link"
    queryParamsHandling="merge"
  >
    <div class="flex items-center">
      <div
        *ngIf="navItem.activeIcon || navItem.icon"
        [class]="isSideNavCollapsed ? '' : 'min-w-[40px]'"
      >
        <img
          data-bs-toggle="modal"
          data-bs-target="#comingSoonModal"
          *ngIf="comingSoonItems.includes(navItem.title)"
          [src]="
            '/assets/icons/' +
            (navItem.active && !navItem.subItems?.length
              ? navItem.activeIcon
              : navItem.icon)
          "
          title="Coming Soon"
        />
        <img
          *ngIf="!comingSoonItems.includes(navItem.title)"
          [src]="
            '/assets/icons/' +
            (navItem.active && !navItem.subItems?.length
              ? navItem.activeIcon
              : navItem.icon)
          "
          [title]="
            allProjectsMenu[0]?.iconTitle ? allProjectsMenu[0]?.iconTitle : ''
          "
        />
      </div>
      <span [ngClass]="{ 'text-white': navItem.active }" class="collapse-this">
        {{ navItem.title }}</span
      >
    </div>
    <img
      *ngIf="navItem.subItems?.length"
      [src]="
        '/assets/icons/' + (navItem.active ? 'chev_up.svg' : 'chev_down.svg')
      "
      class="collapse-this"
    />
  </div>
  <div
    *ngIf="navItem.subItems?.length && navItem.active && !isSideNavCollapsed"
  >
    <div *ngFor="let subItem of navItem.subItems" [hidden]="subItem.hide">
      <ng-container
        *ngIf="subItem.subItems?.length"
        [ngTemplateOutlet]="menuTemplate"
        [ngTemplateOutletContext]="{ navItem: subItem, level: level + 1 }"
      ></ng-container>
      <div
        *ngIf="!subItem.subItems?.length"
        class="
          flex
          items-center
          text-[13px]
          h-[38px]
          bg-lf-gray-20-33
          pr-[19px]
          text-white text-opacity-70
          cursor-pointer
          hover:bg-lf-blue
          hover:bg-opacity-10
          hover:text-white
          hover:text-opacity-100;
          nav-sub-item
        "
        [ngClass]="
          subItem.active
            ? 'pl-[55px] border-l-3 border-lf-orange-100 text-lf-blue bg-lf-blue bg-opacity-20'
            : 'pl-[58px]'
        "
        [ngStyle]="{
          'padding-left':
            subItem.icon && level === 1
              ? '33px'
              : subItem.icon
              ? '19px'
              : 55 + level * 19 + 'px'
        }"
        (click)="activateSubNavItem(subItem)"
        [routerLink]="subItem.link"
        queryParamsHandling="merge"
      >
        <div class="flex items-center">
          <div
            class="w-[40px]"
            *ngIf="subItem.icon"
            data-bs-toggle="modal"
            data-bs-target="#comingSoonModal"
          >
            <img [src]="'/assets/icons/' + subItem.icon" title="Coming Soon" />
          </div>
          <span [ngClass]="{ 'text-white': subItem.active }">
            {{ subItem.title }}</span
          >
        </div>
      </div>
    </div>
  </div>
  <div
    class="
      modal
      fade
      fixed
      top-0
      left-0
      hidden
      w-full
      h-full
      outline-none
      overflow-x-hidden overflow-y-auto
    "
    id="comingSoonModal"
    tabindex="-1"
    aria-labelledby="projectMembershipsModalLabel"
    aria-modal="true"
    role="dialog"
  >
    <div class="modal-dialog modal-sm relative w-auto pointer-events-none">
      <div
        class="
          modal-content
          border-none
          shadow-lg
          relative
          flex flex-col
          w-full
          pointer-events-auto
          bg-white bg-clip-padding
          rounded-md
          outline-none
          text-current
          p-[20px]
        "
      >
        <div class="text-center">
          <span><strong>Coming Soon!</strong></span>
          <p class="text-[13px] mt-4 font-thin text-gray-600">
            Be sure to check our
            <a href="#" class="text-lf-blue">release schedule</a> for the
            availability of these metrics.
          </p>
        </div>
        <div class="flex items-center justify-end w-full mt-5">
          <button
            class="
              w-full
              h-[45px]
              focus:outline-none
              mb-3
              transition
              duration-150
              bg-gradient-to-bl
              from-lf-blue
              to-lf-blue-550
              text-white
              ease-in-out
              border
              rounded
              px-8
              py-2
              text-sm
            "
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            OK
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
