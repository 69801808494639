import { TableApiDataSource } from './api-data-source';
import { TableMockDataSource } from './mock-data-source';
import { TableOptions } from './table-component';

export interface TableConfig {
  table: {
    tableOptions: TableOptions;
    mapFunc?: (tableData: any[]) => any[];
    limit?: number;
    isPaginatedTable?: boolean;
  };
  data?: TableMockDataSource | TableApiDataSource;
}
