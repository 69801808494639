// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT
export interface RepositoryQueryParams {
  projectId: string;
  timeFilter: string;
  reportDate?: string;
  fromDate?: string;
  toDate?: string;
}

export interface RepositoryTagsQueryParams {
  projectId: string;
}
