import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ObservationItem } from '../models/observations';
import { cloneDeep } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class ObservationsService {
  observations$!: Observable<ObservationItem[]>;
  showWebinarObservation: boolean = true;
  showMemberGrowth: boolean = true;
  private observations: ObservationItem[] = [];

  private observationsSubject = new BehaviorSubject<ObservationItem[]>([]);

  constructor() {
    this.observations$ = this.observationsSubject.asObservable();
  }

  resetObservations() {
    this.observations = [];
    this.observationsSubject.next(this.observations);
  }

  appendObservation(observationItem: ObservationItem) {
    this.observations.push(observationItem);
    this.observationsSubject.next(cloneDeep(this.observations));
  }

  getObservationsByName(chartName: string) {
    const requiredObs = this.observations.find(obs => {
      return obs.name === chartName;
    });

    if (requiredObs) {
      return requiredObs.observations;
    }

    return [];
  }
}
