// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { gql } from 'apollo-angular';

export const projectHealthDetailsQuery = gql`
  query ProjectDetails($projectId: String) {
    projectDetails(projectId: $projectId) {
      lastScan
      description
      maturityLevel
      name
      repositoryUrl
      startDate
      technologySector
      technicalContributions {
        commits
        contributors
        issues
        issuesResTime
        issuesResolved
        linesOfCode
        mergeLeadTime
        pullRequests
        pullRequestsMerged
      }
      communityParticipation {
        chats
        emails
        eventAttendees
        meetingAttendees
        meetings
        webinarAttendees
      }
    }
  }
`;
