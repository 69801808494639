import { PieChartName } from '@lfx/core/models/pie-chart-name';
import { AxesChartName } from '@lfx/core/models/axes-chart-name';

export const chartSeriesNames = {
  newMembers: 'New Members',
  activeMembers: 'Active Members',
  projectGrowth: 'Project Growth',
  virtual: 'Virtual',
  physical: 'In-Person',
  individualstrained: 'Trainees',
  individualscertified: 'Certificants',
  orgParticipation: 'Organization Participation',
  TrainingTotalEnrollments: 'Enrollments',
  fromUnKnownOrganization: 'Unaffiliated',
  fromKnownOrganization: 'Affiliated',
  total_contributors: 'Total Contributors',
  active_contributors: 'Active Contributors',
  new_contributors: 'New Contributors',
  new_contributors_commits: 'Commits by New Contributors',
  commits: 'Total Commits',
  new_organizations: 'New Organizations',
  active_organizations: 'Existing Organizations',
  drifting_away_organizations: 'Drifting Away Organizations',
  total_corporate_contributors: 'Corporate Contributors',
  total_independent_contributors: 'Independent Contributors',
  corporate_contributors: 'Affiliated',
  unaffiliated_contributors: 'Unaffiliated Contributors',
  independent_contributors: 'Independent',
  total_unaffiliated_contributors: 'Unaffiliated Contributors',
  prs: 'PR Contributors',
  issues: 'Issue Contributors',
  total_commits: 'Commits',
  active_commits: 'Commits',
  active_commits_contributors: 'Commit Contributors',
  active_commits_organizations: 'Oragnizations',
  code_commits: 'Code Commits',
  merge_commits: 'Merge Commits',
  drifting_away_contributors: 'Drifting Away Contributors',
  pair_programming_commits: 'Pair Programming Commits',
  'Total Webinars': 'Webinars',
  'Individuals Attended': 'Attended',
  'Individuals Registered': 'Registered',
  'Individuals Registered With Unknown Organizations': 'Unaffiliated',
  'Individuals Registered With Known Organizations': 'Affiliated',
  pr_contributors: [
    {
      chartName: AxesChartName.ActivePRSubmittersChart,
      value: 'Repeat Submitters',
    },
    {
      chartName: AxesChartName.ContributorStrengthContributorsChart,
      value: 'Contributors',
    },
    {
      chartName: 'default',
      value: 'Submitters',
    },
  ],
  'Waiting For Review': [
    {
      chartName: AxesChartName.TimeInReviewChart,
      value: 'Average Time for First Review',
    },
    {
      chartName: AxesChartName.PREfficiencyForNewContributorsChart,
      value: 'Lead Time',
    },
    {
      chartName: 'default',
      value: 'Waiting For Review',
    },
  ],
  'In Review': [
    {
      chartName: AxesChartName.TimeInReviewChart,
      value: 'Average Time for First Approval',
    },
    {
      chartName: 'default',
      value: 'In Review',
    },
  ],
  'Time To Merge': [
    {
      chartName: AxesChartName.PREfficiencyForNewContributorsChart,
      value: 'Time in Review',
    },
    {
      chartName: 'default',
      value: 'Time To Merge',
    },
  ],
  pr_reviewers: 'Reviewers',
  pr_approvers: 'PR Approvers',
  pr_mergers: 'PR Mergers',
  new_pr_contributors: 'New Submitters',
  new_pr_reviewers: 'New Reviewers',
  new_pr_approvers: 'New Approvers',
  new_pr_mergers: 'New Mergers',
  new_issue_submitters: 'New Submitters',
  new_issue_resolvers: 'New Resolvers',
  new_issue_assignees: 'New Assignees',
  new_issue_commenters: 'New Commenters',
  issue_submitters: 'Submitters',
  issue_resolvers: 'Resolvers',
  issue_assignees: 'Assignees',
  issue_commenters: 'Commenters',
  'Non Member': 'Non-Member',
  'Contributions by Affiliated Contributors': [
    {
      chartName: PieChartName.ContributingOrganizationChart,
      value: 'Affiliated',
    },
    {
      chartName: PieChartName.AffiliatedVsUnaffiliatedContributionsChart,
      value: 'Affiliated Contributions',
    },
    {
      chartName: 'default',
      value: 'Contributions by Affiliated Contributors',
    },
  ],
  'Contributions by Unaffiliated Contributors': [
    {
      chartName: PieChartName.ContributingOrganizationChart,
      value: 'Unaffiliated',
    },
    {
      chartName: PieChartName.AffiliatedVsUnaffiliatedContributionsChart,
      value: 'Unaffiliated Contributions',
    },
    {
      chartName: 'default',
      value: 'Contributions by Unaffiliated Contributors',
    },
  ],
  'Contributions by Independent Contributors': [
    {
      chartName: PieChartName.ContributingOrganizationChart,
      value: 'Independent',
    },
    {
      chartName: PieChartName.AffiliatedVsUnaffiliatedContributionsChart,
      value: 'Independent Contributions',
    },
    {
      chartName: 'default',
      value: 'Contributions by Independent Contributors',
    },
  ],
  'Small (less than 100 members)': 'Small (less than 100 Employees)',
  'Medium (100-999 members)': 'Medium (100 - 999 Employees)',
  'Large (1000+ members)': 'Large (1000+ Employees)',
  'Time To Comment': [
    {
      chartName: AxesChartName.AcknowledgementTimeChart,
      value: 'First Acknowledge',
    },
    {
      chartName: 'default',
      value: 'Average Time to First Acknowledge',
    },
  ],
  'Time To Assign': [
    {
      chartName: AxesChartName.AcknowledgementTimeChart,
      value: 'Unassigned',
    },
    {
      chartName: 'default',
      value: 'Average Time Unassigned',
    },
  ],
  'Time To Close': 'Avg. Time to Resolve',
  'Total In Progress Issues': 'In Progress',
  'Total Open Issues': 'In Backlog',
  active_repositories: 'Active Repositories',
  inactive_repositories: 'Inactive Repositories',
  'Submitted Issues': 'New issues logged',
  'Closed Issues': 'Issues Resolved',
  'Total Submitted Issues': 'Submitted Issues',
  'Total Resolved Issues': 'Resolved Issues',
  'PR Submitters': 'Submitters',
  'PR Reviewers': 'Reviewers',
  'Total Submitted PRs': 'Submitted',
  'Total Merged PRs': 'Merged',
  'New PR Submitters': 'New Submitters',
  'Open Issues': 'Open',
  'Resolved Issues': 'Resolved',
  pr_submitters: [
    {
      chartName: AxesChartName.ContributorStrengthPrContributorChart,
      value: 'Submitters',
    },
    {
      chartName: PieChartName.SubmittersVsReviewersChart,
      value: 'Submitters',
    },
    {
      chartName: AxesChartName.ActivePRSubmittersChart,
      value: 'Existing',
    },
    {
      chartName: 'default',
      value: 'Active PRs Submitters',
    },
  ],
  new_pr_submitters: 'New',
  'Submitted PRs': 'Submitted',
  'Merged PRs': 'Merged',
  'Open PRs': 'Open PRs/Changesets',
  issue_contributors: 'Issue Contributors',
  new: [
    {
      chartName: AxesChartName.ProjectsAcceptedChart,
      value: 'Projects',
    },
    {
      chartName: 'default',
      value: 'new',
    },
  ],
  'Contributions by New Organizations': 'New',
  'Contributions by Existing Organizations': 'Existing',
  mostActiveOrganizations: 'Most Active Organizations',
  mostActiveJobFunction: 'Most Active Job Function',
  byMostEngagedOrganizations: 'Organization Enrollments',
  topTenProjects: 'Project Membership',
  top_new_organizations: 'Contributions',
  top_active_organizations_by_commits: 'Organization Commits',
  programming_languages_used: 'Programming Language used',
  top_active_organizations_by_pr_activity: 'Organization PR Activity',
  top_active_organizations_by_bug_activity: 'Organization Bug Activity',
  top_active_organizations_by_changeset_activity:
    'Organization Changeset Activity',
};
