export interface SideMenu {
  title: string;
  icon: string;
  activeIcon: string;
  link?: string;
  subItems?: MenuSubItem[];
  active: boolean;
  iconTitle?: string;
  hide?: boolean;
}
export interface MenuSubItem {
  title: string;
  link?: string;
  icon?: string | undefined;
  activeIcon?: string | undefined;
  active: boolean;
  subItems?: MenuSubItem[];
  iconTitle?: string;
  hide?: boolean;
}
