import { TemplateRef } from '@angular/core';
import { FormControl } from '@angular/forms';

export class TableColumnConfig {
  title!: string;
  key!: string | string[];
  headerCssClass!: string;
  columnCssClass!: string;
  template?: TemplateRef<any>;
  type?: TableTemplates;
  width?: string;
  hide?: boolean;
  defaultValue?: string;
  constructor() {}
}

export class TableOptions {
  columns: TableColumnConfig[] = new Array<TableColumnConfig>();
  isLoading?: boolean;
  sortedBy?: string;
  isDesc?: boolean = true;
  expandTemplate?: TemplateRef<any>;

  constructor(columns?: TableColumnConfig[]) {
    if (columns) {
      this.columns = columns;
    }
  }
}

export enum TableTemplates {
  text = 'text',
  blueText = 'blueText',
  number = 'number',
  username = 'username',
  identityName = 'identityName',
  date = 'date',
  logo = 'logo',
  systemUsername = 'systemUsername',
  organizations = 'organizations',
  expand = 'expand',
  url = 'url',
  changeFromPreviousPeriod = 'changeFromPreviousPeriod',
}

export class PaginationConfig {
  numberOfPages: number;
  numberOfElementsPerPage: number;
  totalElements: number;
  activePageIndex: number;
  items: Array<any>;

  constructor() {
    this.numberOfPages = 1;
    this.totalElements = 10;
    this.numberOfElementsPerPage = 10;
    this.activePageIndex = 1;
    this.items = new Array();
  }
}

export class FilterConfig {
  filterName: string = ''; // API filter name
  filterType!: FilterTypes; // specific filters types
  formControl!: FormControl; // represent the filter
  splitter?: string;

  constructor() {}
}

export enum FilterTypes {
  input = 'input',
  select = 'select',
}
