import { Injectable } from '@angular/core';
import { Event, SearchEventsQueryParams } from '@models';
import { Apollo } from 'apollo-angular';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { searchEventsQuery } from '../queries';

interface SearchEventsResult {
  searchEvents: any[];
}

@Injectable({
  providedIn: 'root',
})
export class EventServiceGql {
  constructor(private apollo: Apollo) {}

  searchEvents(
    searchParamsInput: SearchEventsQueryParams
  ): Observable<Event[]> {
    return this.apollo
      .query<SearchEventsResult>({
        query: searchEventsQuery,
        fetchPolicy: 'network-only',
        variables: {
          searchParamsInput,
        },
      })
      .pipe(
        catchError(error => {
          if (
            error &&
            error.graphQLErrors &&
            error.graphQLErrors[0] &&
            error.graphQLErrors[0].extensions &&
            error.graphQLErrors[0].extensions.code === 'UNAUTHENTICATED'
          ) {
            return throwError(() => new Error('UNAUTHENTICATED'));
          }

          console.log(error);

          return of(null);
        }),
        map((res: any) => {
          if (!res) {
            return null;
          }

          return res.data.searchEvents;
        })
      );
  }
}
