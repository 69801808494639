// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { gql } from 'apollo-angular';

export const projectDetailsQuery = gql`
  query GetProject($projectSfid: String) {
    getProject(projectSFID: $projectSfid) {
      description
      name
      projectLogo
      repositoryUrl
      startDate
      status
      createdDate
      id
      modifiedDate
      projectType
      slug
      projects {
        description
        name
        projectLogo
        repositoryUrl
        startDate
        status
        createdDate
        id
        modifiedDate
        projectType
        slug
      }
      parentHierarchy {
        id
        logoUrl
        name
        slug
        status
        parentHierarchy {
          id
          logoUrl
          name
          slug
          status
        }
      }
    }
  }
`;
