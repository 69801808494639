// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { gql } from 'apollo-angular';

export const organizationQuery = gql`
  query projectOrganizationMemberships(
    $projectOrganizationMembershipsInput: ProjectOrganizationMembershipsInput!
  ) {
    projectOrganizationMemberships(
      projectOrganizationMembershipsInput: $projectOrganizationMembershipsInput
    ) {
      membershipType
      displaySequenceNo
      organizations {
        organizationId
        organizationName
        membershipType
        logoUrl
        projectId
      }
    }
  }
`;
