import { Injectable } from '@angular/core';
import { User } from '@models';
import { Observable } from 'rxjs';
import { UserServiceGql } from '../gql';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private userServiceGql: UserServiceGql) {}

  checkUserHasRole(role: string): Observable<{
    hasPermission: boolean;
  }> {
    return this.userServiceGql.checkUserHasRole(role);
  }

  public getCurrentUser(
    withPermissions = true,
    withSocialAccounts = false,
    withEmails = false,
    withAvatar = false,
    withRoles = true
  ): Observable<User> {
    return this.userServiceGql.getCurrentUser(
      withPermissions,
      withSocialAccounts,
      withEmails,
      withAvatar,
      withRoles
    );
  }
}
