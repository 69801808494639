// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { gql } from 'apollo-angular';

export const projectHealthBestPracticesScoreQuery = gql`
  query GetBestPractices($projectId: String) {
    getBestPractices(projectId: $projectId) {
      metrics {
        bestPractices
        documentation
        global
        legal
        license
        security
      }
    }
  }
`;
