import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { EventServiceGql } from '../gql';
import { Event, SearchEventsQueryParams } from '../models';

@Injectable({
  providedIn: 'root',
})
export class EventService {
  selectedEvent$!: Observable<Event | undefined>;
  selectedName$!: Observable<string | undefined>;

  private selectedEventSubject = new BehaviorSubject<Event | undefined>(
    undefined
  );

  private selectedNameSubject = new BehaviorSubject<string | undefined>(
    undefined
  );

  constructor(private eventServiceGql: EventServiceGql) {
    this.selectedEvent$ = this.selectedEventSubject.asObservable();
    this.selectedName$ = this.selectedNameSubject.asObservable();
  }

  searchEvents(searchParams: SearchEventsQueryParams): Observable<Event[]> {
    return this.eventServiceGql.searchEvents(searchParams);
  }

  setSelectedEvent(eventName: string) {
    if (eventName && eventName.length > 0) {
      this.searchEvents({ name: [eventName] })
        .pipe(
          catchError(() => of([])) // empty list on error
        )
        .subscribe(response => {
          const event = response && response[0];

          if (event) {
            this.selectedNameSubject.next(eventName);
          } else {
            this.resetSelectedName();
            this.resetSelectedEvent();
          }

          this.selectedEventSubject.next(event);
        });
    } else {
      this.resetSelectedName();
      this.resetSelectedEvent();
    }
  }

  resetSelectedName() {
    this.selectedNameSubject.next(undefined);
  }

  resetSelectedEvent() {
    this.selectedEventSubject.next(undefined);
    this.resetSelectedName();
  }
}
