// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { gql } from 'apollo-angular';

export const searchCoursesQuery = gql`
  query SearchCourses($name: String) {
    searchCourses(name: $name) {
      id
      tiId
      code
      description
      name
      technology
      type
    }
  }
`;
