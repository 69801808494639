import { TemplateRef } from '@angular/core';

export interface Tab {
  id: string;
  name: string;
  description?: string;
  footer?: Footer[];
  footerItems?: Footer[]; // A place where we can manipulate the footer passed from the components into other items
  chartTableName: any;
}

export interface Footer {
  text: string;
  link: string;
  external?: boolean;
  queryParams?: {};
  description?: string;
}
