import { environment as defaultEnvironment } from './environment.defaults';

export const environment = {
  ...defaultEnvironment,
  auth0: {
    domain: 'linuxfoundation-dev.auth0.com',
    clientId: 'Ljg9aIFFxsmWr7f7dD4dE1EmEs9u7h3T', // DEV client ID - do not change
    redirectUri: `${window.location.origin}`,
    audience: 'https://api-gw.dev.platform.linuxfoundation.org/',
  },
  graphqlUrl: 'https://lfx-insights-bff.dev.platform.linuxfoundation.org/',
  subscriptionsWebsocketUrl:
    'wss://lfx-insights-bff.dev.platform.linuxfoundation.org',
  lfxHeaderCDN: 'https://cdn.dev.platform.linuxfoundation.org/lfx-header-v2.js',
  lfxFooterCDN: 'https://cdn.dev.platform.linuxfoundation.org/lfx-footer.js',
  communityCDN:
    'https://lfx-cm.dev.platform.linuxfoundation.org/assets/cm-ui.js',
  pccUrl: 'https://pcc.dev.platform.linuxfoundation.org/',
  orgDashboardUrl: 'https://lfx.v2.dev.platform.linuxfoundation.org/',
  datadogEnv: 'development',
  traceOrigins: [
    'https://cm-bff.dev.platform.linuxfoundation.org',
    'https://lfx-insights-bff.dev.platform.linuxfoundation.org',
  ],
  ldClientKey: '635a07fef25953111ab85ead',
};
