// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { gql } from 'apollo-angular';

export const funnelChartQuery = gql`
  query FunnelChart($funnelChartInput: FunnelChartInput!) {
    funnelChart(funnelChartInput: $funnelChartInput) {
      dataPoints {
        name
        value
      }
      annotation {
        changeFromPreviousPeriod
        totalValue
        averageValue
      }
      observations
    }
  }
`;
