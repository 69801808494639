import { PieChartApiDataSource } from './api-data-source';
import { MarkPointConfig } from './mark-point';
import { PieChartMockDataSource } from './mock-data-source';

export interface PieChartConfig {
  chart: {
    dataLimit?: number;
    dataLimitText?: string;
    markPoint?: any;
    center?: string[];
    height?: number;
    legend?: {
      top?: string;
      width?: number | string;
      left?: number | string | boolean;
      bottom?: number | string | boolean;
      orient?: string;
      icon?: string;
      itemWidth?: number;
      itemHeight?: number;
    };
    color?: string[];
    textOnlyMarkPoint?: boolean;
  };
  data?: PieChartMockDataSource | PieChartApiDataSource;
  markPointConfig?: MarkPointConfig[];
}
