import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ExpandableStatsServiceGql, ExpandableStatsInput } from '../gql';
import { ExpandableStats } from '../models';
import { ProjectService } from './project.service';

@Injectable({
  providedIn: 'root',
})
export class ExpandableStatsDataService {
  constructor(
    private expandableStatsServiceGql: ExpandableStatsServiceGql,
    private projectService: ProjectService,
    private router: Router
  ) {}

  getExpandableStats(
    expandableStatsInput: ExpandableStatsInput
  ): Observable<ExpandableStats[]> {
    const isGlobalPage =
      this.router.url === '/' ||
      this.router.url.indexOf('/?') >= 0 ||
      this.router.url.indexOf('/global/') >= 0
        ? true
        : false;

    if (isGlobalPage) {
      return this.expandableStatsServiceGql.getExpandableStats(
        expandableStatsInput
      );
    }

    return this.projectService.isProjectOnBoardedAndConfigured(
      this.expandableStatsServiceGql.getExpandableStats(expandableStatsInput)
    );
  }
}
