import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';

import { PageFooterComponent, SideNavComponent } from './components';
import { FilterItemsPipe, ShortNumberPipe } from './pipes';

@NgModule({
  declarations: [
    SideNavComponent,
    ShortNumberPipe,
    PageFooterComponent,
    FilterItemsPipe,
  ],
  imports: [
    CommonModule,
    RouterModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [SideNavComponent, PageFooterComponent],
  providers: [ShortNumberPipe, DatePipe, FilterItemsPipe],
})
export class CoreModule {}
