// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT
export interface ProjectsSummaryQueryParams {
  myProjects?: boolean;
  filter?: string;
  view?: string;
  excludeCategories?: boolean;
  pageSize?: number;
  offset?: number;
  orderBy?: 'createddate' | 'name' | 'status';
}
