export interface RepositoryMetrics {
  bestPractices: number;
  documentation: number;
  global: number;
  license: number;
  security: number;
}

export interface Repository {
  lastScan: number;
  repositoryId: string;
  repositoryName: string;
  repositoryUrl: string;
  status: string;
  metrics: RepositoryMetrics;
  repoDetails?: any;
  repoLoading: boolean;
}
