import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'lfxFilterItems',
})
export class FilterItemsPipe implements PipeTransform {
  transform(
    items: any[] | undefined,
    attributeName: string,
    value: string,
    notEqual?: boolean
  ): any {
    if (notEqual) {
      return items?.filter(i => i[attributeName] !== value);
    }

    return items?.filter(i => i[attributeName] === value);
  }
}
