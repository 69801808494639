// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT
import { Injectable } from '@angular/core';
import {
  Project,
  ProjectsSummaryQueryParams,
  ProjectsWithStatsQueryParams,
  RepositoryQueryParams,
  RepositoryTagsQueryParams,
  SearchProjectsQueryParams,
} from '@models';
import { Apollo } from 'apollo-angular';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import {
  projectConnectorsQuery,
  projectsSummaryQuery,
  projectsWithStatsQuery,
  searchProjectsQuery,
  projectRepositoriesQuery,
  projectDetailsQuery,
  projectRepositoriesTagsQuery,
  enabledConnectorsQuery,
} from '../queries';

interface SearchProjectResult {
  searchProjects: any[];
}

interface ProjectsSummaryResult {
  projectsSummary: any;
}

interface ProjectDetailsResult {
  projectDetails: any;
}

interface ProjectsWithStatsResult {
  projectsWithStats: any;
}

interface ProjectConnectorsResult {
  projectConnectors: any;
}

interface ProjectRepositoriesResult {
  projectRepositories: any;
}

@Injectable({
  providedIn: 'root',
})
export class ProjectServiceGql {
  constructor(private apollo: Apollo) {}

  searchProjects(
    searchParamsInput: SearchProjectsQueryParams
  ): Observable<Project[]> {
    return this.apollo
      .query<SearchProjectResult>({
        query: searchProjectsQuery,
        fetchPolicy: 'network-only',
        variables: {
          searchParamsInput,
        },
      })
      .pipe(
        catchError(error => {
          if (
            error &&
            error.graphQLErrors &&
            error.graphQLErrors[0] &&
            error.graphQLErrors[0].extensions &&
            error.graphQLErrors[0].extensions.code === 'UNAUTHENTICATED'
          ) {
            return throwError(() => new Error('UNAUTHENTICATED'));
          }

          console.log(error);

          return of(null);
        }),
        map((res: any) => {
          if (!res) {
            return null;
          }

          return res.data.searchProjects;
        })
      );
  }

  getProjectsSummary(
    projectsSummaryInput: ProjectsSummaryQueryParams
  ): Observable<any> {
    return this.apollo
      .query<ProjectsSummaryResult>({
        query: projectsSummaryQuery,
        fetchPolicy: 'no-cache',
        variables: {
          projectsSummaryInput,
        },
      })
      .pipe(
        catchError(error => {
          if (
            error &&
            error.graphQLErrors &&
            error.graphQLErrors[0] &&
            error.graphQLErrors[0].extensions &&
            error.graphQLErrors[0].extensions.code === 'UNAUTHENTICATED'
          ) {
            return throwError(() => new Error('UNAUTHENTICATED'));
          }

          return of(null);
        }),
        map((res: any) => {
          if (!res) {
            return null;
          }

          return res.data.projectsSummary;
        })
      );
  }

  getProjectsWithStats(
    projectsWithStatsInput?: ProjectsWithStatsQueryParams
  ): Observable<any> {
    return this.apollo
      .query<ProjectsWithStatsResult>({
        query: projectsWithStatsQuery,
        fetchPolicy: 'network-only',
        variables: {
          projectsWithStatsInput,
        },
      })
      .pipe(
        catchError(error => {
          if (
            error &&
            error.graphQLErrors &&
            error.graphQLErrors[0] &&
            error.graphQLErrors[0].extensions &&
            error.graphQLErrors[0].extensions.code === 'UNAUTHENTICATED'
          ) {
            return throwError(() => new Error('UNAUTHENTICATED'));
          }

          return of(null);
        }),
        map((res: any) => {
          if (!res) {
            return null;
          }

          return res.data.projectsWithStats;
        })
      );
  }

  getProjectConnectors(salesforceId: string): Observable<any> {
    return this.apollo
      .query<ProjectConnectorsResult>({
        query: projectConnectorsQuery,
        fetchPolicy: 'network-only',
        variables: {
          salesforceId,
        },
      })
      .pipe(
        catchError(error => {
          if (
            error &&
            error.graphQLErrors &&
            error.graphQLErrors[0] &&
            error.graphQLErrors[0].extensions &&
            error.graphQLErrors[0].extensions.code === 'UNAUTHENTICATED'
          ) {
            return throwError(() => new Error('UNAUTHENTICATED'));
          }

          return of(null);
        }),
        map((res: any) => {
          if (!res) {
            return null;
          }

          return res.data.projectConnectors;
        })
      );
  }

  getEnabledConnectors(): Observable<any> {
    return this.apollo
      .query<ProjectConnectorsResult>({
        query: enabledConnectorsQuery,
        fetchPolicy: 'network-only',
      })
      .pipe(
        catchError(error => {
          if (
            error &&
            error.graphQLErrors &&
            error.graphQLErrors[0] &&
            error.graphQLErrors[0].extensions &&
            error.graphQLErrors[0].extensions.code === 'UNAUTHENTICATED'
          ) {
            return throwError(() => new Error('UNAUTHENTICATED'));
          }

          return of(null);
        }),
        map((res: any) => {
          if (!res) {
            return null;
          }

          return res.data.getEnabledConnectors;
        })
      );
  }

  getProjectRepositories(
    projectRepositoriesInput: RepositoryQueryParams
  ): Observable<any> {
    return this.apollo
      .query<ProjectRepositoriesResult>({
        query: projectRepositoriesQuery,
        fetchPolicy: 'network-only',
        variables: {
          projectRepositoriesInput,
        },
      })
      .pipe(
        catchError(error => {
          if (
            error &&
            error.graphQLErrors &&
            error.graphQLErrors[0] &&
            error.graphQLErrors[0].extensions &&
            error.graphQLErrors[0].extensions.code === 'UNAUTHENTICATED'
          ) {
            return throwError(() => new Error('UNAUTHENTICATED'));
          }

          return of(null);
        }),
        map((res: any) => {
          if (!res) {
            return null;
          }

          return res.data.projectRepositories;
        })
      );
  }

  getProjectRepositoriesTags(
    projectRepositoriesTagsInput: RepositoryTagsQueryParams
  ): Observable<any> {
    return this.apollo
      .query<ProjectRepositoriesResult>({
        query: projectRepositoriesTagsQuery,
        fetchPolicy: 'network-only',
        variables: {
          projectRepositoriesTagsInput,
        },
      })
      .pipe(
        catchError(error => {
          if (
            error &&
            error.graphQLErrors &&
            error.graphQLErrors[0] &&
            error.graphQLErrors[0].extensions &&
            error.graphQLErrors[0].extensions.code === 'UNAUTHENTICATED'
          ) {
            return throwError(() => new Error('UNAUTHENTICATED'));
          }

          return of(null);
        }),
        map((res: any) => {
          if (!res) {
            return null;
          }

          return res.data.projectRepositoriesTags;
        })
      );
  }

  getProjectDetails(projectSfid: string): Observable<any> {
    return this.apollo
      .query<ProjectDetailsResult>({
        query: projectDetailsQuery,
        fetchPolicy: 'network-only',
        variables: {
          projectSfid,
        },
      })
      .pipe(
        catchError(error => {
          if (
            error &&
            error.graphQLErrors &&
            error.graphQLErrors[0] &&
            error.graphQLErrors[0].extensions &&
            error.graphQLErrors[0].extensions.code === 'UNAUTHENTICATED'
          ) {
            return throwError(() => new Error('UNAUTHENTICATED'));
          }

          return of(null);
        }),
        map((res: any) => {
          if (!res) {
            return null;
          }

          return res.data.getProject;
        })
      );
  }
}
