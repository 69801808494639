import { Injectable } from '@angular/core';
import { TableName } from '@models';

import { BehaviorSubject, Observable } from 'rxjs';
import { PaginationService } from './pagination.service';
import { TableService } from './table.service';

@Injectable({
  providedIn: 'root',
})
export class SortByService {
  sortBy$!: Observable<{ sortBy: String; tableName: TableName } | undefined>;

  sortByMap: Map<TableName, { sortBy: string; tableName: TableName }> =
    new Map();

  private sortBySubject = new BehaviorSubject<
    { sortBy: String; tableName: TableName } | undefined
  >(undefined);

  constructor(
    private tableService: TableService,
    private paginationService: PaginationService
  ) {
    this.sortBy$ = this.sortBySubject.asObservable();
  }

  sortByChange(param: { sortBy: string; tableName: TableName }) {
    this.paginationService.resetPagination(param.tableName);
    this.sortByMap.set(param.tableName, param);
    this.tableService.updateActiveTable(param.tableName);
    this.sortBySubject.next(param);
  }

  getSortByTableName(tableName: TableName) {
    return this.sortByMap.get(tableName);
  }
}
