// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { gql } from 'apollo-angular';

export const projectHealthRepositoryDetailsQuery = gql`
  query Query($repoId: String) {
    projectHealthRepositoryDetailsByRepositoryId(repoId: $repoId)
  }
`;
