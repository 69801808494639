import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@environment';
import { Observable } from 'rxjs';

import {
  AxesChartInput,
  AxesChartServiceGql,
  BubbleChartInput,
  BubbleChartServiceGql,
  FunnelChartInput,
  FunnelChartServiceGql,
  GaugeChartInput,
  GaugeChartServiceGql,
  PieChartInput,
  PieChartServiceGql,
  RadarChartInput,
  RadarChartServiceGql,
  WordChartInput,
  WordChartServiceGql,
  WorldMapChartInput,
  WorldMapChartServiceGql,
} from '../gql';
import {
  AxesChart,
  BubbleChart,
  FunnelChart,
  GaugeChart,
  PieChart,
  RadarChart,
  RadarChartName,
  WordChart,
  WorldMapChart,
} from '../models';
import { ProjectService } from './project.service';
@Injectable({
  providedIn: 'root',
})
export class ChartsDataService {
  isProdEnvironment = environment.datadogEnv === 'production';

  constructor(
    private axesChartServiceGql: AxesChartServiceGql,
    private bubbleChartServiceGql: BubbleChartServiceGql,
    private funnelChartServiceGql: FunnelChartServiceGql,
    private pieChartServiceGql: PieChartServiceGql,
    private wordChartServiceGql: WordChartServiceGql,
    private worldMapChartServiceGql: WorldMapChartServiceGql,
    private radarChartServiceGql: RadarChartServiceGql,
    private gaugeChartServiceGql: GaugeChartServiceGql,
    private router: Router,
    private projectService: ProjectService
  ) {}

  getAxesChart(axesChartInput: AxesChartInput): Observable<AxesChart> {
    const isGlobalPage =
      this.router.url === '/' ||
      this.router.url.indexOf('/?') >= 0 ||
      this.router.url.indexOf('/global/') >= 0
        ? true
        : false;
    const applyRepositoriesFilter = this.checkPath();

    if (!applyRepositoriesFilter) {
      axesChartInput.chartsQueryParams.repositoryIds = [];
      axesChartInput.chartsQueryParams.repositoryTags = [];
    }

    if (isGlobalPage) {
      axesChartInput.chartsQueryParams.projectId = undefined;

      return this.axesChartServiceGql.getAxesChart(axesChartInput);
    }

    return this.projectService.isProjectOnBoardedAndConfigured(
      this.axesChartServiceGql.getAxesChart(axesChartInput)
    );
  }

  getBubbleChart(bubbleChartInput: BubbleChartInput): Observable<BubbleChart> {
    const isGlobalPage =
      this.router.url === '/' ||
      this.router.url.indexOf('/?') >= 0 ||
      this.router.url.indexOf('/global/') >= 0
        ? true
        : false;

    const applyRepositoriesFilter = this.checkPath();

    if (!applyRepositoriesFilter) {
      bubbleChartInput.chartsQueryParams.repositoryIds = [];
      bubbleChartInput.chartsQueryParams.repositoryTags = [];
    }

    if (isGlobalPage) {
      bubbleChartInput.chartsQueryParams.projectId = undefined;

      return this.bubbleChartServiceGql.getBubbleChart(bubbleChartInput);
    }

    return this.projectService.isProjectOnBoardedAndConfigured(
      this.bubbleChartServiceGql.getBubbleChart(bubbleChartInput)
    );
  }

  getFunnelChart(funnelChartInput: FunnelChartInput): Observable<FunnelChart> {
    const isGlobalPage =
      this.router.url === '/' ||
      this.router.url.indexOf('/?') >= 0 ||
      this.router.url.indexOf('/global/') >= 0
        ? true
        : false;
    const applyRepositoriesFilter = this.checkPath();

    if (!applyRepositoriesFilter) {
      funnelChartInput.chartsQueryParams.repositoryIds = [];
      funnelChartInput.chartsQueryParams.repositoryTags = [];
    }

    if (isGlobalPage) {
      funnelChartInput.chartsQueryParams.projectId = undefined;

      return this.funnelChartServiceGql.getFunnelChart(funnelChartInput);
    }

    return this.projectService.isProjectOnBoardedAndConfigured(
      this.funnelChartServiceGql.getFunnelChart(funnelChartInput)
    );
  }

  getPieChart(pieChartInput: PieChartInput): Observable<PieChart> {
    const isGlobalPage =
      this.router.url === '/' ||
      this.router.url.indexOf('/?') >= 0 ||
      this.router.url.indexOf('/global/') >= 0
        ? true
        : false;
    const applyRepositoriesFilter = this.checkPath();

    if (!applyRepositoriesFilter) {
      pieChartInput.chartsQueryParams.repositoryIds = [];
      pieChartInput.chartsQueryParams.repositoryTags = [];
    }

    if (isGlobalPage) {
      pieChartInput.chartsQueryParams.projectId = undefined;

      return this.pieChartServiceGql.getPieChart(pieChartInput);
    }

    return this.projectService.isProjectOnBoardedAndConfigured(
      this.pieChartServiceGql.getPieChart(pieChartInput)
    );
  }

  getGaugeChart(gaugeChartInput: GaugeChartInput): Observable<GaugeChart> {
    const isGlobalPage =
      this.router.url === '/' ||
      this.router.url.indexOf('/?') >= 0 ||
      this.router.url.indexOf('/global/') >= 0
        ? true
        : false;
    const applyRepositoriesFilter = this.checkPath();

    if (!applyRepositoriesFilter) {
      gaugeChartInput.chartsQueryParams.repositoryIds = [];
      gaugeChartInput.chartsQueryParams.repositoryTags = [];
    }

    if (isGlobalPage) {
      gaugeChartInput.chartsQueryParams.projectId = undefined;

      return this.gaugeChartServiceGql.getGaugeChart(gaugeChartInput);
    }

    return this.projectService.isProjectOnBoardedAndConfigured(
      this.gaugeChartServiceGql.getGaugeChart(gaugeChartInput)
    );
  }

  getRadarChart(radarChartInput: RadarChartInput): Observable<RadarChart> {
    const isGlobalPage =
      this.router.url === '/' ||
      this.router.url.indexOf('/?') >= 0 ||
      this.router.url.indexOf('/global/') >= 0
        ? true
        : false;
    const applyRepositoriesFilter = this.checkPath();

    if (!applyRepositoriesFilter) {
      radarChartInput.chartsQueryParams.repositoryIds = [];
      radarChartInput.chartsQueryParams.repositoryTags = [];
    }

    if (isGlobalPage) {
      radarChartInput.chartsQueryParams.projectId = undefined;

      return this.radarChartServiceGql.getRadarChart(radarChartInput);
    }

    return this.projectService.isProjectOnBoardedAndConfigured(
      this.radarChartServiceGql.getRadarChart(radarChartInput)
    );
  }

  getWordChart(wordChartInput: WordChartInput): Observable<WordChart> {
    const isGlobalPage =
      this.router.url === '/' ||
      this.router.url.indexOf('/?') >= 0 ||
      this.router.url.indexOf('/global/') >= 0
        ? true
        : false;
    const applyRepositoriesFilter = this.checkPath();

    if (!applyRepositoriesFilter) {
      wordChartInput.chartsQueryParams.repositoryIds = [];
      wordChartInput.chartsQueryParams.repositoryTags = [];
    }

    if (isGlobalPage) {
      wordChartInput.chartsQueryParams.projectId = undefined;

      return this.wordChartServiceGql.getWordChart(wordChartInput);
    }

    return this.projectService.isProjectOnBoardedAndConfigured(
      this.wordChartServiceGql.getWordChart(wordChartInput)
    );
  }

  getWorldMapChart(
    worldMapChartInput: WorldMapChartInput
  ): Observable<WorldMapChart> {
    const isGlobalPage =
      this.router.url === '/' ||
      this.router.url.indexOf('/?') >= 0 ||
      this.router.url.indexOf('/global/') >= 0
        ? true
        : false;
    const applyRepositoriesFilter = this.checkPath();

    if (!applyRepositoriesFilter) {
      worldMapChartInput.chartsQueryParams.repositoryIds = [];
      worldMapChartInput.chartsQueryParams.repositoryTags = [];
    }

    if (isGlobalPage) {
      worldMapChartInput.chartsQueryParams.projectId = undefined;

      return this.worldMapChartServiceGql.getWorldMapChart(worldMapChartInput);
    }

    return this.projectService.isProjectOnBoardedAndConfigured(
      this.worldMapChartServiceGql.getWorldMapChart(worldMapChartInput)
    );
  }

  private checkPath() {
    const isPath =
      window?.location?.pathname?.endsWith('/code-base/repositories') ||
      window?.location?.pathname?.includes('/code-velocity/');

    return isPath || false;
  }
}
