import { Injectable } from '@angular/core';
import { PaginationConfig, TableName } from '@models';

import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { TableService } from './table.service';

@Injectable({
  providedIn: 'root',
})
export class PaginationService {
  pagination$!: Observable<
    { pagination: PaginationConfig; tableName: TableName } | undefined
  >;

  private paginationSubject = new BehaviorSubject<
    { pagination: PaginationConfig; tableName: TableName } | undefined
  >(undefined);

  private paginationMap: Map<TableName, PaginationConfig> = new Map();

  constructor(private tableService: TableService) {
    this.pagination$ = this.paginationSubject.asObservable();
  }

  paginationChange(param: {
    pagination: PaginationConfig;
    tableName: TableName;
  }) {
    this.paginationMap.set(param.tableName, param.pagination);
    this.tableService.updateActiveTable(param.tableName);
    this.paginationSubject.next(param);
  }

  resetPagination(tableName: TableName) {
    const pagination = this.paginationMap.get(tableName);

    if (pagination) {
      pagination.activePageIndex = 1;
      this.paginationMap.set(tableName, pagination);
      this.tableService.updateActiveTable(tableName);
    }
  }

  getPaginationByTableName(tableName: TableName) {
    return this.paginationMap.get(tableName);
  }
}
