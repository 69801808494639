export const CompareToOptions = ['Previous year', 'Previous period'];

export const DateRangeFiltersNames = [
  // { name: 'Year to Date', key: 'YTD' },
  // { name: 'Quarter to Date', key: 'QTD' },
  // { name: 'Month to Date', key: 'MTD' },
  // { name: 'Last 7 Days', key: '7D' },
  { name: 'Last 30 Days', key: '30D' },
  // { name: 'Last 60 Days', key: '60D' },
  { name: 'Last 90 Days', key: '90D' },
  // { name: 'Last 6 Months', key: '6M' },
  { name: 'Past 1 Year', key: '1Y' },
  // { name: 'Past 2 Years', key: '2Y' },
  { name: 'Past 3 Years', key: '3Y' },
  // { name: 'Past 5 Years', key: '5Y' },
  { name: 'Past 10 Years', key: '10Y' },
];
