// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { gql } from 'apollo-angular';

export const searchProjectsQuery = gql`
  query SearchProjects($searchParamsInput: SearchProjectsQueryParamsInput!) {
    searchProjects(searchParamsInput: $searchParamsInput) {
      id
      name
      slug
      description
      projectType
      type
      enabledServices
      parent
      projectLogo
      endDate
      foundation {
        id
        logoUrl
        name
        slug
      }
    }
  }
`;
