export const industryFilters: string[] = [
  'Technology Hardware, Storage & Periph...',
  'Renewable Electricity',
  'Internet Software & Services',
  'Diversified Financial Services',
  'Commercial Services & Supplies',
  'Aerospace & Defense',
  'Professional Services',
  'Automotive',
  'Household Durables',
  'Electrical Equipment',
  'Hotels, Restaurants & Leisure',
  'Media',
  'Communications Equipment',
  'Consumer Discretionary',
  'Education Services',
  'Capital Markets',
  'Health Care Providers & Services',
  'Real Estate',
  'Retailing',
  'Transportation',
  'Banks',
  'Tech Hardware, Storage & Peripherals',
  'Diversified Telecommunication Services',
  'Air Freight & Logistics',
  'Pharmaceuticals',
  'Gas Utilities',
  'Diversified Consumer Services',
  'Not For Profit',
  'Utilities',
  'Family Services',
  'Construction & Engineering',
  'Food Products',
  'Insurance',
  'Biotechnology',
  'Textiles, Apparel & Luxury Goods',
  'Industrial Conglomerates',
  'Electric Utilities',
  'Trading Companies & Distributors',
  'Machinery',
  'Airlines',
  'Software',
  'Consumer Staples',
  'Distributors',
  'Building Materials',
  'Chemicals',
  'Government agencies',
  'Containers & Packaging',
  'Metals & Mining',
  'Consumer Goods',
  'Specialized Consumer Services',
  'Leisure Products',
  'IT Services',
  // 'Nonprofit Institutions',
  'Specialty Retail',
  'Marine',
  'Other',
  'Banking',
  'Education',
  'al Products',
  'Manufacturing - Durables',
  'Road & Rail',
  'Electronic Equipment, Instruments & C...',
  'Paper & Forest Products',
  'Beverages',
  'Non-Profit',
  'No Industry',
  'Industrials',
  'Membership Organizations',
  'Federal',
  'Religious Organizations',
  'Wholesale',
  'Health Care Equipment & Supplies',
  'Business associations',
  'Wireless Telecommunication Services',
  'Electronic Equipment & Instruments',
  'Semiconductors & Semiconductor Equipment',
  'Test',
];
