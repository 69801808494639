import { Injectable } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { environment } from '@environment';
import {
  initialize,
  LDClient,
  LDFlagSet,
  LDUser,
} from 'launchdarkly-js-client-sdk';
import * as _ from 'lodash';
import { NgxPermissionsService } from 'ngx-permissions';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagManagerService {
  public ldClient!: LDClient;
  public flags!: LDFlagSet;
  public flagChange: Subject<any> = new Subject<any>();
  flagChange$ = this.flagChange.asObservable();

  constructor(
    private ngxPermissionsService: NgxPermissionsService,
    private authService: AuthService
  ) {}

  initialize() {
    return new Observable(observer => {
      if (this.flags) {
        observer.next(this.flags);
        observer.complete();

        return;
      }

      let userDef: LDUser;

      this.authService.user$.subscribe(userProfile => {
        if (userProfile && userProfile.nickname) {
          // Only a `key` is required for non-anonymous
          userDef = {
            key: userProfile.nickname,
            firstName: userProfile.given_name,
            lastName: userProfile.family_name,
            email: userProfile.email,
            avatar: userProfile.picture,
          };
        } else {
          userDef = { anonymous: true, key: 'anonymous' };
        }

        this.ldClient = initialize(environment.ldClientKey, userDef);
        this.ldClient.on('ready', () => {
          this.updateFlags();
          observer.next(this.flags);
          observer.complete();
        });
        this.ldClient.on('change', () => {
          this.updateFlags();
        });
      });
    });
  }

  setFlag(key: string, value: any) {
    if (value) {
      if (value !== true) {
        if (_.isPlainObject(value)) {
          // This is a JSON value
          if (_.isEmpty(value)) {
            // If empty, disable feature
            this.ngxPermissionsService.addPermission('!feature_' + key); // Force a falsey value
          } else {
            // Set general feature enabled
            this.ngxPermissionsService.addPermission('feature_' + key); // Still a truthy value
            // Set each inner property individually
            Object.keys(value).forEach(p =>
              this.setFlag(`${key}_${p}`, value[p])
            );
          }
        } else {
          this.ngxPermissionsService.addPermission('feature_' + key); // Still a truthy value
          this.ngxPermissionsService.addPermission(`feature_${key}_${value}`);
        }
      } else {
        this.ngxPermissionsService.addPermission('feature_' + key); // Actual boolean = true
      }
    } else {
      this.ngxPermissionsService.addPermission('!feature_' + key); // Falsey value
    }
  }

  updateFlags() {
    if (this.flags) {
      // Clear existing flags
      const perms = this.ngxPermissionsService.getPermissions();

      const flagKeys = Object.keys(perms).filter(
        k => k.startsWith('feature_') || k.startsWith('!feature_')
      );

      flagKeys.forEach(k => this.ngxPermissionsService.removePermission(k));
    }

    // Set the new flags
    this.flags = this.ldClient.allFlags();

    for (const key of Object.keys(this.flags)) {
      const value = this.flags[key];

      this.setFlag(key, value);
    }

    // eslint-disable-next-line no-console
    console.debug('(flags)', this.flags);
    this.flagChange.next(this.flags);
  }
}
