// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { gql } from 'apollo-angular';

export const radarChartQuery = gql`
  query RadarChart($radarChartInput: RadarChartInput!) {
    radarChart(radarChartInput: $radarChartInput) {
      dataPoints {
        name
        value
      }
      observations
    }
  }
`;
