// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import _ from 'lodash';
import {
  BehaviorSubject,
  combineLatest,
  EMPTY,
  Observable,
  of,
  Subject,
} from 'rxjs';
import { first, map } from 'rxjs/operators';

import { ProjectServiceGql } from '../gql';
import {
  Project,
  ProjectsSummaryQueryParams,
  ProjectsWithStatsQueryParams,
  RepositoryQueryParams,
  RepositoryTagsQueryParams,
  SearchProjectsQueryParams,
} from '../models';

@Injectable({
  providedIn: 'root',
})
export class ProjectService {
  selectedProject$!: Observable<Project | undefined>;
  selectedSlug$!: Observable<string | undefined>;
  projectSlugData$: Observable<{
    selectedProject: Project | undefined;
    selectedSlug: string | undefined;
  }>;

  projectHasGitConnectorOnly = new BehaviorSubject<boolean>(false);
  projectConnectors = new BehaviorSubject<string[]>([]);

  selectedRepositories$!: Observable<string[]>;
  selectedRepositoriesTags$!: Observable<string[]>;
  projectSummaryData: any[] = [];
  enabledConnectors: any[] = [];
  nonExistProjectSubject = new Subject();
  repoFilterProjectChange = new Subject();

  isSideNavCollapsed = new BehaviorSubject<boolean>(false);
  projectsLoaded$!: Observable<any>;

  isLFStaffUser: boolean = false;

  private selectedProjectSubject = new BehaviorSubject<Project | undefined>(
    undefined
  );

  private selectedSlugSubject = new BehaviorSubject<string | undefined>(
    undefined
  );

  private repositoriesSubject = new BehaviorSubject<any>(undefined);

  private selectedRepositoriesSubject = new BehaviorSubject<string[]>([]);
  private selectedRepositoriesTagsSubject = new BehaviorSubject<string[]>([]);

  private selectedProjectLastValue?: Project;
  private projectsLoaded = new BehaviorSubject<any>(undefined);

  constructor(private projectServiceGql: ProjectServiceGql) {
    this.selectedProject$ = this.selectedProjectSubject.asObservable();
    this.selectedSlug$ = this.selectedSlugSubject.asObservable();
    this.projectsLoaded$ = this.projectsLoaded.asObservable();

    this.projectSlugData$ = combineLatest([
      this.selectedProject$,
      this.selectedSlug$,
    ]).pipe(
      map(([selectedProject, selectedSlug]) => {
        return { selectedProject, selectedSlug };
      })
    );
    this.selectedRepositories$ =
      this.selectedRepositoriesSubject.asObservable();

    this.selectedRepositoriesTags$ =
      this.selectedRepositoriesTagsSubject.asObservable();
  }

  setSelectedProject(projectSlug: string) {
    if (this.projectSummaryData?.length) {
      const project = this.getProjectBySlug(
        projectSlug,
        this.projectSummaryData
      );

      if (project) {
        this.handleGetProjectResponse(project, projectSlug);

        return;
      }
    }
    this.getProjectsSummary({ filter: 'slug eq ' + projectSlug }).subscribe(
      response => {
        const project = response?.projectsSummaryData[0];

        this.setProjectFromSingleQuery(project, projectSlug);
      }
    );
  }

  setProjectFromSingleQuery(project: any, projectSlug: string) {
    if (project) {
      this.setParentAndRootSlugForSubProjects(project, null);
      // Move categories projects to separate projects array
      this.moveCategoriesToProjects(null, project, project?.categories);

      this.handleOnBoardedFlag(project);
      this.handleGetProjectResponse(project, projectSlug);
    } else {
      this.nonExistProjectSubject.next(true);
    }
  }

  resetSelectedSlug() {
    this.selectedSlugSubject.next(undefined);
  }

  resetSelectedProject() {
    this.selectedProjectLastValue = undefined;
    this.selectedProjectSubject.next(undefined);
  }

  searchProjects(
    searchParams: SearchProjectsQueryParams
  ): Observable<Project[]> {
    return this.projectServiceGql.searchProjects(searchParams);
  }

  getProjectsSummary(
    projectsSummaryParams: ProjectsSummaryQueryParams
  ): Observable<any> {
    return this.projectServiceGql.getProjectsSummary(projectsSummaryParams);
  }

  getProjectsWithStats(
    projectsWithStatsParams?: ProjectsWithStatsQueryParams
  ): Observable<any> {
    return this.projectServiceGql.getProjectsWithStats(projectsWithStatsParams);
  }

  getProjectConnectors(salesforceId: string): Observable<any> {
    return this.projectServiceGql.getProjectConnectors(salesforceId).pipe(
      map(project => {
        let connectors = project?.projectConnectorsData?.enabledConnectors;
        let isConfigured = true;

        if (
          connectors &&
          connectors.length &&
          (connectors.includes('git') ||
            connectors.includes('github') ||
            connectors.includes('gitlab'))
        ) {
          isConfigured = true;
        } else {
          isConfigured = false;
        }

        // this.isConfiguredSubject.next(isConfigured);

        return isConfigured;
      })
    );
  }

  getEnabledConnectors() {
    if (!this.enabledConnectors?.length) {
      this.projectServiceGql
        .getEnabledConnectors()
        .pipe(first())
        .subscribe(res => {
          this.enabledConnectors = res?.connectors;

          this.hasProjectHasGitConnectorOnly(
            this.selectedProjectLastValue?.id || ''
          );
          this.fillProjectConnectors(this.selectedProjectLastValue?.id || '');
        });
      this.selectedProject$.subscribe(p => {
        if (!this.enabledConnectors?.length) {
          return;
        }
        this.hasProjectHasGitConnectorOnly(p?.id || '');
        this.fillProjectConnectors(p?.id || '');
      });
    }
  }

  hasProjectHasGitConnectorOnly(
    projectId: string,
    allowBehaviorSubjectChange = true
  ) {
    if (!projectId) return false;

    const projectEnabledConnectors = this.enabledConnectors?.find(
      x => x.projectId === projectId
    );

    const hasGitConnectorOnly =
      projectEnabledConnectors?.enabledConnectors?.length === 1 &&
      projectEnabledConnectors?.enabledConnectors?.includes('git');

    if (allowBehaviorSubjectChange) {
      this.projectHasGitConnectorOnly.next(hasGitConnectorOnly);
    }

    return hasGitConnectorOnly;
  }

  fillProjectConnectors(projectId: string) {
    if (!projectId) return;

    const projectEnabledConnectors = this.enabledConnectors?.find(
      x => x.projectId === projectId
    );

    this.projectConnectors.next(
      projectEnabledConnectors
        ? projectEnabledConnectors?.enabledConnectors?.map((x: any) => x.trim())
        : []
    );
  }

  itHasGitOrGerritOnly() {
    let connectors = this.projectConnectors.getValue();

    if (
      connectors.length === 1 &&
      (connectors.includes('git') || connectors.includes('gerrit'))
    ) {
      return true;
    } else if (
      connectors.length === 2 &&
      connectors.includes('git') &&
      connectors.includes('gerrit')
    ) {
      return true;
    }

    return false;
  }

  getProjectLastValue() {
    return this.selectedProjectLastValue;
  }

  getProjectRepositories(
    projectRepositoriesInput: RepositoryQueryParams
  ): Observable<any> {
    return this.projectServiceGql.getProjectRepositories(
      projectRepositoriesInput
    );
  }

  getProjectRepositoriesTags(
    projectRepositoriesTagsInput: RepositoryTagsQueryParams
  ): Observable<any> {
    return this.projectServiceGql.getProjectRepositoriesTags(
      projectRepositoriesTagsInput
    );
  }

  setRepositories(repositories: any) {
    this.repositoriesSubject.next(repositories);
  }

  getRepositories(): [] {
    return this.repositoriesSubject.value;
  }

  setSelectedRepositories(repositoryIds: string[]) {
    this.selectedRepositoriesSubject.next(repositoryIds);
  }

  setSelectedRepositoriesTags(repositoryTags: string[]) {
    this.selectedRepositoriesTagsSubject.next(repositoryTags);
  }

  getSelectedRepositories() {
    return this.selectedRepositoriesSubject.value;
  }

  getSelectedRepositoriesTags() {
    return this.selectedRepositoriesTagsSubject.value;
  }

  getProjectDetails(projectSfid: string): Observable<any> {
    return this.projectServiceGql.getProjectDetails(projectSfid);
  }

  getProjects() {
    if (this.projectSummaryData?.length) {
      return of(this.projectSummaryData);
    } else {
      return this.getProjectsSummary({
        filter: '',
        pageSize: 9999,
        orderBy: 'name',
      }).pipe(
        map(response => {
          const projectsSummaryData = response?.projectsSummaryData;

          if (projectsSummaryData) {
            projectsSummaryData.forEach((project: Project) => {
              this.setParentAndRootSlugForSubProjects(project, project);

              if (project?.categories?.length > 0) {
                this.moveCategoriesToProjects(
                  project,
                  project,
                  project.categories
                );
              }

              this.handleOnBoardedFlag(project);
            });
          }

          // remove projects which have not onboarded. ticket #IN-1677
          this.projectSummaryData = projectsSummaryData?.filter(
            (x: any) => x.isOnBoarded
          );
          this.projectsLoaded.next(this.projectSummaryData);

          return this.projectSummaryData || [];
        })
      );
    }
  }

  getProjectSummaryBySlug(slug: string) {
    return this.getProjectsSummary({
      filter: `slug eq ${slug}`,
      pageSize: 1,
      orderBy: 'name',
    });
    // .pipe(
    //   map(response => {
    //     console.log(response);
    //   })
    // );
  }

  handleOnBoardedFlag(project: Project) {
    project.isOnBoarded = this.checkIsProjectOnBoarded(project);

    if (project.projects) {
      // remove projects which have not onboarded. ticket #IN-1677

      project.projects.forEach(subProject => {
        this.handleOnBoardedFlag(subProject);
      });

      project.projects = project.projects.filter(
        x => x.enabledServices.includes('Insights') || x.projects?.length
      );
    }
  }

  filterProjects(projects: any[], search: string) {
    return _.orderBy(
      _.compact(
        _.map(projects, p => {
          if (
            p.name.toLocaleLowerCase().includes(search.toLowerCase()) ||
            (p.slug &&
              p.slug.toLocaleLowerCase().includes(search.toLowerCase()))
          ) {
            // loop through this parent's projects and filter that as well
            if (p.projects) {
              p.projects = this.filterProjects(p.projects, search);
            }

            return p;
          } else if (p.projects) {
            const filteredList: any = this.filterProjects(p.projects, search);

            if (_.isEmpty(filteredList)) {
              return null;
            }
            p.projects = filteredList;

            return p;
          }

          return null;
        })
      ),
      'name'
    );
  }

  isProjectOnBoardedAndConfigured(observable: Observable<any>) {
    if (!this.selectedProjectLastValue?.isOnBoarded) {
      return EMPTY;
    }

    return observable;
  }

  checkIsProjectOnBoarded(project: Project | undefined): boolean {
    if (!project) {
      return false;
    }

    if (project.enabledServices.includes('Insights')) {
      return true;
    }

    return !!(
      project.projects &&
      project.projects.some(p => this.checkIsProjectOnBoarded(p))
    );
  }

  private getProjectBySlug(
    projectSlug: string,
    projects: Project[]
  ): Project | undefined {
    for (const project of projects) {
      if (project.slug === projectSlug) {
        return project;
      } else if (project.projects) {
        const p = this.getProjectBySlug(projectSlug, project.projects);

        if (p) {
          return p;
        }
      }
    }

    return undefined;
  }

  private handleGetProjectResponse(project: Project, projectSlug: string) {
    if (project) {
      this.selectedSlugSubject.next(projectSlug);
    } else {
      this.resetSelectedSlug();
    }

    if (this.selectedProjectLastValue != project) {
      this.repoFilterProjectChange.next();
    }
    this.selectedProjectLastValue = project;

    this.selectedProjectSubject.next(project);
  }

  private moveCategoriesToProjects(
    rootProject: Project | null,
    parentProject: Project,
    categories: any[]
  ) {
    if (!parentProject.projects?.length) {
      parentProject.projects = [];
    } else {
      this.setParentAndRootSlugForSubProjects(parentProject, rootProject);
    }

    categories?.forEach((category: any) => {
      category.projects?.forEach((categoryProject: any) => {
        if (rootProject) {
          categoryProject.rootSlug = rootProject.slug;
        }
        categoryProject.parentSlug = parentProject.slug;

        this.setParentAndRootSlugForCategoryProjects(
          categoryProject,
          rootProject
        );

        parentProject.projects?.push(categoryProject);

        if (categoryProject?.categories?.length) {
          this.moveCategoriesToProjects(
            rootProject,
            categoryProject,
            categoryProject.categories
          );
        }
      });

      // sort projects
      if (parentProject && parentProject?.projects?.length) {
        parentProject.projects = _.sortBy(parentProject?.projects, [
          p => p.name.toLowerCase(),
        ]);
      }
    });
  }

  private setParentAndRootSlugForSubProjects(
    parentProject: Project,
    rootProject: Project | null
  ) {
    parentProject.projects?.forEach(project => {
      if (rootProject) {
        project.rootSlug = rootProject.slug;
      }
      project.parentSlug = parentProject.slug;

      if (project.projects) {
        this.setParentAndRootSlugForSubProjects(project, rootProject);
      }
    });
  }

  private setParentAndRootSlugForCategoryProjects(
    parentProject: Project,
    rootProject: Project | null
  ) {
    parentProject.projects?.forEach(project => {
      if (rootProject) {
        project.rootSlug = rootProject.slug;
      }
      project.parentSlug = parentProject.slug;

      if (project.projects) {
        this.setParentAndRootSlugForSubProjects(project, rootProject);
      }
    });
  }
}
