import { NgModule } from '@angular/core';
import {
  ApolloClientOptions,
  ApolloLink,
  InMemoryCache,
} from '@apollo/client/core';
import { onError } from '@apollo/client/link/error';
import { environment } from '@environment';
import { APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { RetryLink } from '@apollo/client/link/retry';
import { generalConstants } from './config';

const uri = environment.graphqlUrl;

const error = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(error => {
      console.log('[GraphQL error]:', error);
    });
  }

  if (networkError) {
    console.log('[Network error]:', networkError);
  }
});

const retry = new RetryLink({
  attempts: (count, operation, error) => {
    operation.variables.retry = true;

    return count <= generalConstants.numberOfRetryTimes && !!error;
  },
  delay: (count, operation) => {
    operation.variables.retry = true;

    return count * 1000 * Math.random();
  },
});

export function createApollo(httpLink: HttpLink): ApolloClientOptions<any> {
  const http = ApolloLink.from([error, retry, httpLink.create({ uri })]);

  return {
    link: http,
    cache: new InMemoryCache(),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
      },
      query: {
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
      },
    },
  };
}

@NgModule({
  exports: [ApolloModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink],
    },
  ],
})
export class GraphQLModule {}
