// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT
export interface Event {
  id: string;
  name: string;
  createdDate: string;
  cventId: string;
  CventLink: string;
  cventSandbox: boolean;
  description: string;
  embassy: string;
  endDate: string;
  eventURL: string;
  locationAddress: string;
  locationCity: string;
  locationCountry: string;
  locationName: string;
  locationState: string;
  locationZip: string;
  projectId: string;
  projectName: string;
  registrationUrl: string;
  startDate: string;
  status: string;
}
