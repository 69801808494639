export enum TableName {
  TopTenAttendeesTable = 'TopTenAttendeesTable',
  TopTenSpeakersTable = 'TopTenSpeakersTable',
  MostEngagedOrganizationsTable = 'MostEngagedOrganizationsTable',
  TopTenMostPopularWebinarsTable = 'TopTenMostPopularWebinarsTable',
  TopTenMostFrequentAttendeesTable = 'TopTenMostFrequentAttendeesTable',
  TopTenMostEngagedIndividualsTable = 'TopTenMostEngagedIndividualsTable',
  MostEngagedIndividualsTable = 'MostEngagedIndividualsTable',
  TopTenContributorsTable = 'TopTenContributorsTable',
  TopTenParticipatingOrganizationsTable = 'TopTenParticipatingOrganizationsTable',
  EmailTopTenEngagedIndividualsTable = 'EmailTopTenEngagedIndividualsTable',
  ProjectMembershipsTable = 'ProjectMembershipsTable',
  OrganizationLeaderboardTable = 'OrganizationLeaderboardTable',
  TopTenParticipatingNewOrganizationsTable = 'TopTenParticipatingNewOrganizationsTable',
  ActiveTrainingProgramsTable = 'ActiveTrainingProgramsTable',
  PopularCertificationProgramsTable = 'PopularCertificationProgramsTable',
  ParticipantTopTenEngagedOrganizationTable = 'ParticipantTopTenEngagedOrganizationTable',
  TopTenMostActiveUnaffiliatedContributorsTable = 'TopTenMostActiveUnaffiliatedContributorsTable',
  CommitAnalysisContributorLeaderboardTable = 'CommitAnalysisContributorLeaderboardTable',
  CommitAnalysisOrganizationLeaderboardTable = 'CommitAnalysisOrganizationLeaderboardTable',
  TopTenContributorsChurnedTable = 'TopTenContributorsChurnedTable',
  PullRequestPipeLineTopTenParticipatingOrganizationsTable = 'PullRequestPipeLineTopTenParticipatingOrganizationsTable',
  IssueRequestPipeOrganizationLeaderboardTable = 'IssueRequestPipeOrganizationLeaderboardTable',
  RepositoriesThatNeedAttentionTable = 'RepositoriesThatNeedAttentionTable',
  CodeBaseRepositoriesTable = 'CodeBaseRepositoriesTable',
  TopTenActiveContributorsTable = 'TopTenActiveContributorsTable',
  TopTenNewContributorsTable = 'TopTenNewContributorsTable',
  TopTenPRContributorsTable = 'TopTenPRContributorsTable',
  Top10EngagedIndividualsTable = 'Top10EngagedIndividualsTable',
  PageStatisticsTable = 'PageStatisticsTable',
  MostActiveOrganizationsTable = 'MostActiveOrganizationsTable',
}
