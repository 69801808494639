// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { gql } from 'apollo-angular';

export const projectRepositoriesQuery = gql`
  query ProjectRepositories(
    $projectRepositoriesInput: ProjectRepositoriesQueryParamsInput
  ) {
    projectRepositories(projectRepositoriesInput: $projectRepositoriesInput) {
      repositoriesData
      metaData
    }
  }
`;

export const projectRepositoriesTagsQuery = gql`
  query projectRepositoriesTags(
    $projectRepositoriesTagsInput: ProjectRepositoriesTagsQueryParamsInput
  ) {
    projectRepositoriesTags(
      projectRepositoriesTagsInput: $projectRepositoriesTagsInput
    ) {
      repositoriesData
    }
  }
`;
