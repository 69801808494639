export interface ChartsQueryParams {
  timeFilter: string;
  reportDate?: string;
  fromDate?: string;
  toDate?: string;
  projectId?: string;
  filter?: any;
  pageSize?: number;
  offset?: number;
  orderBy?: string;
  locType?: string;
  repositoryIds?: string[];
  repositoryTags?: string[];
}
