// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { first, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { Observable, throwError, timer } from 'rxjs';
import {
  catchError,
  delay,
  delayWhen,
  mergeMap,
  retryWhen,
  take,
  tap,
} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptorService implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return this.authService.isAuthenticated$.pipe(
      first(),
      switchMap(isAuthenticated => {
        if (!isAuthenticated) {
          // INFO: bypass anonymous users that can see Home page.
          const tokenReq = req.clone({
            setHeaders: { Authorization: 'Bearer ' },
          });

          return next.handle(tokenReq);
        }

        return this.getAuth0Token(req, next).pipe(
          catchError(err => {
            return throwError(err);
          }),
          // INFO: fix for refresh token by ignoreCache: true.
          retryWhen(errors => errors.pipe(delay(1000), take(3)))
        );
      })
    );
  }

  private getAuth0Token(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return this.authService.getAccessTokenSilently().pipe(
      mergeMap(token => {
        const tokenReq = req.clone({
          setHeaders: { Authorization: `Bearer ${token}` },
        });

        return next.handle(tokenReq).pipe(
          // INFO: fix for retry when bff timeout
          retryWhen(errors =>
            errors.pipe(
              tap((err: HttpErrorResponse) => {
                const errStatus = err.statusText;
                const errCode = err.status;
                const isBffTimeoutError =
                  errStatus.toLowerCase().includes('unknown error') &&
                  errCode === 0;

                const isHttpError = err instanceof HttpErrorResponse;

                if (!isHttpError || !isBffTimeoutError) {
                  throw err;
                }
              }),
              delayWhen(() => timer(500)),
              take(2)
            )
          )
        );
      })
    );
  }
}
