import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';
import { AuthInterceptorService } from '@interceptors';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'projects',
    pathMatch: 'full',
  },
  {
    path: '',
    loadChildren: () =>
      import('./modules/trends/trends.module').then(m => m.TrendsModule),
    // canActivate: [AuthGuard],
  },
  {
    path: 'global/project-trends',
    loadChildren: () =>
      import('./modules/trends/trends.module').then(m => m.TrendsModule),
    // canActivate: [AuthGuard],
  },
  {
    path: 'global/event-analytics',
    loadChildren: () =>
      import('./modules/event-analytics/event-analytics.module').then(
        e => e.EventAnalyticsModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'global/training-and-certification-analytics',
    loadChildren: () =>
      import(
        './modules/training-and-certification-analytics/training-and-certification-analytics.module'
      ).then(e => e.TrainingAndCertificationAnalyticsModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'global/organization-contribution-index',
    loadChildren: () =>
      import(
        './modules/global-organization-oss-index/global-organization-oss-index.module'
      ).then(e => e.GlobalOrganizationOssIndexModule),
    // canActivate: [AuthGuard],
  },
  {
    path: 'global/organization-oss-index',
    redirectTo: 'global/organization-contribution-index',
  },
  {
    path: 'global/membership-analytics',
    loadChildren: () =>
      import('./modules/membership-analytics/membership-analytics.module').then(
        e => e.MembershipAnalyticsModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'global/community',
    loadChildren: () =>
      import('./modules/community/community.module').then(
        e => e.CommunityModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: ':projectSlug/community',
    loadChildren: () =>
      import('./modules/community/community.module').then(
        e => e.CommunityModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: ':projectSlug/technical-contributors',
    loadChildren: () =>
      import(
        './modules/technical-contributors/technical-contributors.module'
      ).then(e => e.TechnicalContributorsModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'projects',
    loadChildren: () =>
      import('./modules/projects/projects.module').then(m => m.ProjectsModule),
    // canActivate: [AuthGuard],
  },
  {
    path: 'global/webinar-analytics',
    loadChildren: () =>
      import('./modules/webinar-analytics/webinar-analytics.module').then(
        w => w.WebinarAnalyticsModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: ':projectSlug/code-velocity',
    loadChildren: () =>
      import('./modules/code-velocity/code-velocity.module').then(
        e => e.CodeVelocityModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: ':projectSlug/code-base',
    loadChildren: () =>
      import('./modules/code-base/code-base.module').then(
        e => e.CodeBaseModule
      ),
  },
  // {
  //   path: ':projectSlug/email',
  //   loadChildren: () =>
  //     import('./modules/email/email.module').then(e => e.EmailModule),
  //   canActivate: [AuthGuard],
  // },
  {
    path: ':projectSlug/documentation',
    loadChildren: () =>
      import('./modules/documentation/documentation.module').then(
        e => e.DocumentationModule
      ),
    canActivate: [AuthGuard],
  },
  // {
  //   path: ':projectSlug/chat',
  //   loadChildren: () =>
  //     import('./modules/chat/chat.module').then(c => c.ChatModule),
  //   canActivate: [AuthGuard],
  // },
  {
    path: ':projectSlug/ecosystem-analytics',
    loadChildren: () =>
      import('./modules/ecosystem-analytics/ecosystem-analytics.module').then(
        c => c.EcosystemAnalyticsModule
      ),
    canActivate: [AuthGuard],
  },
  // {
  //   path: ':projectSlug/package-downloads',
  //   loadChildren: () =>
  //     import('./modules/package-downloads/package-downloads.module').then(
  //       c => c.PackageDownloadsModule
  //     ),
  //   canActivate: [AuthGuard],
  // },
  // {
  //   path: ':projectSlug/earned-media',
  //   loadChildren: () =>
  //     import('./modules/earned-media/earned-media.module').then(
  //       c => c.EarnedMediaModule
  //     ),
  //   canActivate: [AuthGuard],
  // },
  // {
  //   path: ':projectSlug/social-media',
  //   loadChildren: () =>
  //     import('./modules/social-media/social-media.module').then(
  //       s => s.SocialMediaModule
  //     ),
  //   canActivate: [AuthGuard],
  // },
  {
    path: ':projectSlug/overview',
    loadChildren: () =>
      import('./modules/project-health/project-health.module').then(
        e => e.ProjectHealthModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: '**',
    redirectTo: 'projects',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      paramsInheritanceStrategy: 'always',
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true,
    },
  ],
})
export class AppRoutingModule {}
