// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { gql } from 'apollo-angular';

export const wordChartQuery = gql`
  query WordChart($wordChartInput: WordChartInput!) {
    wordChart(wordChartInput: $wordChartInput) {
      dataPoints {
        name
        value
      }
      observations
    }
  }
`;

export const wordChartWithoutValueQuery = gql`
  query WordChart($wordChartInput: WordChartInput!) {
    wordChart(wordChartInput: $wordChartInput) {
      dataPoints {
        name
      }
      observations
    }
  }
`;
