// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { gql } from 'apollo-angular';

export const tabCardsQuery = gql`
  query tabCards($tabCardsInput: TabCardsInput!) {
    tabCards(tabCardsInput: $tabCardsInput) {
      title
      value
      changeFromPreviousPeriod
    }
  }
`;
