import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ShortUrlServiceGql } from '../gql';
import { Event, SearchEventsQueryParams } from '../models';

@Injectable({
  providedIn: 'root',
})
export class ShortUrlService {
  constructor(private shortUrlServiceGql: ShortUrlServiceGql) {}

  shortenUrl(url: string): Observable<any> {
    return this.shortUrlServiceGql.shortenUrl(url);
  }
}
