import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { shortenUrlQuery } from '../queries';

interface ShortenUrlResult {
  shortenUrl: any;
}

@Injectable({
  providedIn: 'root',
})
export class ShortUrlServiceGql {
  constructor(private apollo: Apollo) {}

  shortenUrl(url: string): Observable<any> {
    return this.apollo
      .query<ShortenUrlResult>({
        query: shortenUrlQuery,
        fetchPolicy: 'network-only',
        variables: {
          url,
        },
      })
      .pipe(
        catchError(error => {
          if (
            error &&
            error.graphQLErrors &&
            error.graphQLErrors[0] &&
            error.graphQLErrors[0].extensions &&
            error.graphQLErrors[0].extensions.code === 'UNAUTHENTICATED'
          ) {
            return throwError(() => new Error('UNAUTHENTICATED'));
          }

          console.log(error);

          return of(null);
        }),
        map((res: any) => {
          if (!res) {
            return null;
          }

          return res.data.shortenUrl;
        })
      );
  }
}
