import * as Highcharts from 'highcharts';

import { BubbleChartApiDataSource } from './api-data-source';
import { MarkPoint } from './mark-point';
import { BubbleChartMockDataSource } from './mock-data-source';

export interface BubbleChartConfig {
  chart: {
    type: 'multi-series' | 'single-series';
    width?: number;
    options?: Highcharts.Options;
    legend?: {
      show: boolean;
      type: 'built-in' | 'custom';
    };
    markPoint?: MarkPoint;
    othersThreshold?: number;
  };
  data?: BubbleChartMockDataSource | BubbleChartApiDataSource;
}
