import { YAxisData } from './axes-chart';
import { DataPoint } from './data-point';

export interface MockDataSource {
  source: 'mock';
}

export interface TableMockDataSource extends MockDataSource {
  mockData?: {
    data: any[];
  };
}
export interface AxesChartMockDataSource extends MockDataSource {
  mockData?: {
    xAxisData: string[];
    yAxisData: YAxisData[];
  };
}

export interface KeyValueChartMockDataSource extends MockDataSource {
  mockData?: {
    dataPoints: DataPoint[];
  };
}
export interface BubbleChartMockDataSource
  extends KeyValueChartMockDataSource {}

export interface WordChartMockDataSource extends KeyValueChartMockDataSource {}

export interface FunnelChartMockDataSource
  extends KeyValueChartMockDataSource {}
export interface WorldMapChartMockDataSource
  extends KeyValueChartMockDataSource {}

export interface PieChartMockDataSource extends KeyValueChartMockDataSource {}

export interface RadarChartMockDataSource extends KeyValueChartMockDataSource {}

export interface GaugeChartMockDataSource extends MockDataSource {
  mockData?: {
    value: number;
  };
}
