import { Injectable } from '@angular/core';
import { ChartsQueryParams, WorldMapChartName, WorldMapChart } from '@models';
import { Apollo } from 'apollo-angular';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { worldMapChartQuery } from '../queries';

interface WorldMapChartResult {
  mapChart: WorldMapChart;
}

export interface WorldMapChartInput {
  mapChartName: WorldMapChartName;
  chartsQueryParams: ChartsQueryParams;
}

@Injectable({
  providedIn: 'root',
})
export class WorldMapChartServiceGql {
  constructor(private apollo: Apollo) {}

  getWorldMapChart(
    worldMapChartInput: WorldMapChartInput
  ): Observable<WorldMapChart> {
    return this.apollo
      .query<WorldMapChartResult>({
        query: worldMapChartQuery,
        fetchPolicy: 'network-only',
        variables: {
          worldMapChartInput: worldMapChartInput,
        },
      })
      .pipe(
        catchError(error => {
          if (
            error &&
            error.graphQLErrors &&
            error.graphQLErrors[0] &&
            error.graphQLErrors[0].extensions &&
            error.graphQLErrors[0].extensions.code === 'UNAUTHENTICATED'
          ) {
            return throwError(() => new Error('UNAUTHENTICATED'));
          }

          console.log(error);

          return of(null);
        }),
        map((res: any) => {
          if (!res) {
            return null;
          }

          return res.data.mapChart;
        })
      );
  }
}
