// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { gql } from 'apollo-angular';

export const worldMapChartQuery = gql`
  query WorldMapChart($worldMapChartInput: MapChartInput!) {
    mapChart(mapChartInput: $worldMapChartInput) {
      dataPoints {
        name
        value
      }
      annotation {
        changeFromPreviousPeriod
        totalValue
        averageValue
      }
      observations
    }
  }
`;
