// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { gql } from 'apollo-angular';

export const pieChartQuery = gql`
  query PieChart($pieChartInput: PieChartInput!) {
    pieChart(pieChartInput: $pieChartInput) {
      dataPoints {
        name
        value
      }
      annotation {
        changeFromPreviousPeriod
        totalValue
        averageValue
      }
      observations
    }
  }
`;
