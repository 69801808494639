export const environment = {
  production: false,
  auth0: {
    clientId: 'bYWuMkUoO0wMql5FxT83MdKSgRgTEcK8', // DEV client ID - do not change
    domain: 'linuxfoundation-dev.auth0.com',
    audience: 'https://api-gw.dev.platform.linuxfoundation.org/',
    redirectUri: `${window.location.origin}`,
  },
  graphqlUrl: 'http://localhost:5000',
  subscriptionsWebsocketUrl: 'ws://localhost:5000',
  lfxHeaderCDN: 'https://cdn.dev.platform.linuxfoundation.org/lfx-header-v2.js',
  lfxFooterCDN: 'https://cdn.dev.platform.linuxfoundation.org/lfx-footer.js',
  communityCDN: 'http://localhost:4200/assets/cm-ui.js',
  pccUrl: 'https://pcc.dev.platform.linuxfoundation.org/',
  orgDashboardUrl: 'https://lfx.v2.dev.platform.linuxfoundation.org/',
  datadogEnv: '', // temporarily set to 'local' to test RUM locally
  traceOrigins: [],
  ldClientKey: '635a07fef25953111ab85ead',
};
