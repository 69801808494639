// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { gql } from 'apollo-angular';

export const searchEventsQuery = gql`
  query SearchEvents($searchParamsInput: SearchEventsQueryParamsInput) {
    searchEvents(searchParamsInput: $searchParamsInput) {
      id
      name
      createdDate
      cventId
      CventLink
      cventSandbox
      description
      embassy
      endDate
      eventURL
      locationAddress
      locationCity
      locationCountry
      locationName
      locationState
      locationZip
      projectId
      projectName
      registrationUrl
      startDate
      status
    }
  }
`;
