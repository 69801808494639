// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT
export interface Course {
  id: string;
  tiId: string;
  code: string;
  description: string;
  name: string;
  technology: string;
  type: string;
}
