export interface Organization {
  organizationId: string;
  organizationName: string;
  membershipType: string;
  logoUrl: string;
  projectId: string;
}

export interface ProjectOrganizationMemberships {
  membershipType: string;
  organizations: Organization[];
  pagination: {
    totalElements: number;
    activePageIndex: number;
    numberOfPages: number;
  };
}
