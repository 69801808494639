// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { gql } from 'apollo-angular';

export const expandableStatsQuery = gql`
  query expandableStats($expandableStatsInput: ExpandableStatsInput!) {
    expandableStats(expandableStatsInput: $expandableStatsInput) {
      name
      changeFromPreviousPeriod
      value
    }
  }
`;
