import { AxesChartApiDataSource } from './api-data-source';
import { AxesChartMockDataSource } from './mock-data-source';
import * as Highcharts from 'highcharts';
import { MarkPointConfig } from './mark-point';

export interface AxesChartConfig {
  chart: EBarChartConfig | ELineChartConfig | WaterFallChartConfig;
  data?: AxesChartMockDataSource | AxesChartApiDataSource;
  markPointConfig?: MarkPointConfig[];
}

export interface CommonChartConfig {
  showLegend?: boolean;
  yAxisName?: string;
  height?: number;
  limit?: number;
}

export interface EBarChartConfig extends CommonChartConfig {
  kind: 'bar';
  type: 'normal' | 'stacked' | 'reversedStacked';
  direction?: 'vertical' | 'horizontal';
  barColors?: {
    defaultColor: string;
    colors: {
      value: number[];
      color: string;
    }[];
  };
  barWidth?: number;
}

export interface ELineChartConfig extends CommonChartConfig {
  kind: 'line';
  type: 'normal' | 'area' | 'stacked';
  symbolSize?: number;
  symbol: string;
}

export interface WaterFallChartConfig extends CommonChartConfig {
  kind: 'waterfall';
  options?: Highcharts.Options;
}
