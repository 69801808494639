export interface User {
  id: string;
  auth0Id: string;
  type: string;
  firstName: string;
  lastName: string;
  fullName: string;
  avatarUrl: string;
  timezone: string;
  emails: Email[];
  username: string;
  modifiedDate: string;
  createdDate: string;
  permissions: UserPermission[];
  socialAccounts?: Identity[];
  roles: string[];
}

interface Email {
  emailAddress: string;
  emailType: string;
  active: boolean;
  isDeleted: boolean;
  isPrimary: boolean;
  isVerified?: boolean;
}

interface UserPermission {
  context: string;
  actions: string[];
  allowed: boolean;
  resource: string;
  scopes: UserScope[];
}

interface UserScope {
  id: string[];
  role: string;
  type: string;
}

enum SocialAuthProviders {
  google = 'google-oauth2',
  github = 'github',
  facebook = 'facebook',
  linkedin = 'linkedin',
}

interface Identity {
  userId: string;
  provider: SocialAuthProviders;
  connection: string;
  isSocial: boolean;
}
