<footer>
  <div class="text-xs-11 h-4 font-open-sans text-center w-full">
    <ng-container
      *ngFor="let item of links"
      [ngTemplateOutlet]="linkTemplate"
      [ngTemplateOutletContext]="{ $implicit: item }"
    >
    </ng-container>
  </div>
</footer>

<ng-template #linkTemplate let-item>
  <!-- Displays link -->
  <a
    *ngIf="item.isClickable"
    class="text-primary px-1"
    target="_blank"
    [href]="item.url"
    >{{ item.name }}</a
  >
  <!-- Displays separator -->
  <span *ngIf="!item.isClickable && !item.isText" class="text-gray-9f9f px-1">{{
    item.name
  }}</span>
  <!-- Displays Text -->
  <span *ngIf="!item.isClickable && item.isText" class="px-1">{{
    item.name
  }}</span>
</ng-template>
