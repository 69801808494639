import { AxesChartName } from '@lfx/core/models/axes-chart-name';
import { BubbleChartName } from '@lfx/core/models/bubble-chart-name';
import { FunnelChartName } from '@lfx/core/models/funnel-chart-name';
import { GaugeChartName } from '@lfx/core/models/gauge-chart-name';
import { PieChartName } from '@lfx/core/models/pie-chart-name';
import { RadarChartName } from '@lfx/core/models/radar-chart-name';
import { TableName } from '@lfx/core/models/table-name';
import { WordChartName } from '@lfx/core/models/word-chart-name';
import { WorldMapChartName } from '@lfx/core/models/world-map-chart-name';

export const howIsCalculatedUrlsConstants: {
  [
    index:
      | AxesChartName
      | BubbleChartName
      | PieChartName
      | FunnelChartName
      | GaugeChartName
      | WorldMapChartName
      | WordChartName
      | RadarChartName
      | TableName
      | string
  ]: string | Object;
} = {
  ////////////////////////
  // #region Accordion
  ////////////////////////

  ContributorGrowthRetention: {
    '/global/project-trends':
      'https://docs.linuxfoundation.org/lfx/insights/v2-current/project-trends/contributor-growth-and-retention',
    '/trends':
      'https://docs.linuxfoundation.org/lfx/insights/v2-current/all-projects/project-analytics/trends/contributor-growth-and-retention',
  },

  // #endregion Accordion

  ////////////////////////
  // #region Axes Charts
  ////////////////////////

  [AxesChartName.EventsAttendeesChart]: {
    '/global/project-trends':
      'https://docs.linuxfoundation.org/lfx/insights/v2-current/project-trends/event-attendees',
    '/global/event-analytics/attendees':
      'https://docs.linuxfoundation.org/lfx/insights/v2-current/event-analytics/event-attendees#event-attendees',
    // missing selected project trends page
  },

  [AxesChartName.EventsSpeakersChart]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/event-analytics/event-speakers#event-speakers',

  [AxesChartName.IndividualCertifiedChart]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/project-trends/trainees-and-certified',

  [AxesChartName.IndividualTrainedChart]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/project-trends/trainees-and-certified',

  [AxesChartName.VulnerabilitiesFixedChart]: '',

  [AxesChartName.MemberGrowthChart]: {
    '/global/project-trends':
      'https://docs.linuxfoundation.org/lfx/insights/v2-current/project-trends/member-growth',

    '/global/membership-analytics/overview':
      'https://docs.linuxfoundation.org/lfx/insights/v2-current/membership-analytics/overview/member-growth',
  },
  [AxesChartName.NewMembersChart]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/membership-analytics/overview/new-members',

  [AxesChartName.OrganizationParticipationAttendeesChart]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/event-analytics/organization-participation#attendees',

  [AxesChartName.OrganizationParticipationSpeakersChart]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/event-analytics/organization-participation#speakers',

  [AxesChartName.EnrollmentChart]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/training-and-certification-analytics/training-analytics/enrollments',

  [AxesChartName.LearnersChart]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/training-and-certification-analytics/training-analytics/trainees',

  [AxesChartName.IndividualEnrolledVsCertifiedChart]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/training-and-certification-analytics/certification-analytics/individuals-enrolled-vs.-certified',

  [AxesChartName.EnrollmentsVsCertificatesIssuedChart]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/training-and-certification-analytics/certification-analytics/enrollments-vs.-certificates-issued',

  [AxesChartName.RegisteredVsAttendedChart]: {
    '/global/project-trends':
      'https://docs.linuxfoundation.org/lfx/insights/v2-current/project-trends/webinars-and-attendees',
    '/global/webinar-analytics':
      'https://docs.linuxfoundation.org/lfx/insights/v2-current/webinar-analytics/attendance',
  },

  [AxesChartName.RegisteredProjectsAndSubProjectsChart]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/membership-analytics/overview/projects',

  [AxesChartName.WebinarsChart]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/project-trends/webinars-and-attendees',

  [AxesChartName.TotalContributorsChart]: {
    '/global/project-trends':
      'https://docs.linuxfoundation.org/lfx/insights/v2-current/project-trends/contributor-strength',

    // project link

    '/trends':
      'https://docs.linuxfoundation.org/lfx/insights/v2-current/all-projects/project-analytics/trends/contributor-strength',

    // project link

    '/technical-contributors/contributor-strength':
      'https://docs.linuxfoundation.org/lfx/insights/v2-current/all-projects/project-analytics/technical-contributors/contributor-strength/total-contributors',
  },

  [AxesChartName.ActiveContributorsChart]: {
    '/global/project-trends':
      'https://docs.linuxfoundation.org/lfx/insights/v2-current/project-trends/active-contributors-and-drifting-away',

    // project link

    '/trends':
      'https://docs.linuxfoundation.org/lfx/insights/v2-current/all-projects/project-analytics/trends/active-contributors-and-drifting-away',
  },
  [AxesChartName.CorporateContributorsChart]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/all-projects/project-analytics/technical-contributors/contributor-strength/corporate-contributors-and-independent',

  [AxesChartName.IndependentContributorsChart]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/all-projects/project-analytics/technical-contributors/contributor-strength/corporate-contributors-and-independent',

  [AxesChartName.UnaffiliatedContributorsChart]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/all-projects/project-analytics/technical-contributors/contributor-strength/unaffiliated',

  [AxesChartName.EmailTotalParticipantsChart]: '',

  [AxesChartName.EmailActiveParticipantsChart]: '',

  [AxesChartName.EmailDriftingAwayParticipantsChart]: '',

  [AxesChartName.EmailNewParticipantsChart]: '',

  [AxesChartName.ImpactByNewContributorsChart]: {
    '/global/project-trends':
      'https://docs.linuxfoundation.org/lfx/insights/v2-current/project-trends/contributor-growth-and-impact',

    // project link

    '/trends':
      'https://docs.linuxfoundation.org/lfx/insights/v2-current/all-projects/project-analytics/trends/contributor-growth-and-impact',
  },
  [AxesChartName.ContributorAcquisitionImpactByNewContributorsChart]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/all-projects/project-analytics/technical-contributors/contributor-acquisition/impact-by-new-contributors',

  [AxesChartName.ParticipantOrganizationEngagementDriftingAwayChart]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/all-projects/project-analytics/technical-contributors/organizations/new-vs-existing-and-drifted-away',

  [AxesChartName.ParticipantOrganizationEngagementNewVsExistingOrgChart]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/all-projects/project-analytics/technical-contributors/organizations/new-vs-existing-and-drifted-away',

  [AxesChartName.ParticipantOrganizationEngagementByCorporateVsIndependentChart]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/all-projects/project-analytics/technical-contributors/organizations/contributor-type',

  [AxesChartName.NewContributorsChart]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/all-projects/project-analytics/technical-contributors/contributor-acquisition/new-contributors',

  [AxesChartName.TotalUnaffiliatedChart]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/all-projects/project-analytics/technical-contributors/unaffiliated-contributors/unaffiliated',

  [AxesChartName.UnaffiliatedAndActiveChart]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/all-projects/project-analytics/technical-contributors/unaffiliated-contributors/unaffiliated-and-active',

  [AxesChartName.ContributorStrengthPrContributorChart]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/all-projects/project-analytics/technical-contributors/contributor-strength/pr-contributors',

  [AxesChartName.ContributorStrengthIssueContributorChart]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/all-projects/project-analytics/technical-contributors/contributor-strength/issue-contributors',

  [AxesChartName.ContributorStrengthNewContributorChart]: {
    '/global/project-trends':
      'https://docs.linuxfoundation.org/lfx/insights/v2-current/project-trends/contributor-growth-and-impact',

    // project link

    '/trends':
      'https://docs.linuxfoundation.org/lfx/insights/v2-current/all-projects/project-analytics/trends/contributor-growth-and-impact',

    // project link

    '/technical-contributors/contributor-strength':
      'https://docs.linuxfoundation.org/lfx/insights/v2-current/all-projects/project-analytics/technical-contributors/contributor-strength/new-and-drifting-away',
  },
  [AxesChartName.ContributorStrengthDriftingAwayContributorChart]: {
    '/global/project-trends':
      'https://docs.linuxfoundation.org/lfx/insights/v2-current/project-trends/active-contributors-and-drifting-away',

    // project link

    '/trends':
      'https://docs.linuxfoundation.org/lfx/insights/v2-current/all-projects/project-analytics/trends/active-contributors-and-drifting-away',

    // project link

    '/technical-contributors/contributor-strength':
      'https://docs.linuxfoundation.org/lfx/insights/v2-current/all-projects/project-analytics/technical-contributors/contributor-strength/new-and-drifting-away',
  },
  [AxesChartName.ContributorStrengthCommitContributorChart]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/all-projects/project-analytics/technical-contributors/contributor-strength/commit-contributors',
  [AxesChartName.ContributorStrengthContributorsChart]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/all-projects/project-analytics/technical-contributors/contributor-strength/contributors',

  [AxesChartName.TrendsCommitContributorChart]: {
    '/global/project-trends':
      'https://docs.linuxfoundation.org/lfx/insights/v2-current/project-trends/commits',

    // project link

    '/trends':
      'https://docs.linuxfoundation.org/lfx/insights/v2-current/all-projects/project-analytics/trends/commits',
  },

  [AxesChartName.CommitAnalysisCommitTotalCommitsChart]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/all-projects/project-analytics/code-velocity/commit-analysis#total-number-of-commits',

  [AxesChartName.CommitAnalysisCommitActiveCommitsChart]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/all-projects/project-analytics/code-velocity/commit-analysis#commit-trends',

  [AxesChartName.CommitAnalysisCommitActiveCommitsContributorsChart]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/all-projects/project-analytics/code-velocity/commit-analysis#contributors-commits',

  [AxesChartName.CommitAnalysisCommitActiveCommitsOrganizationsChart]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/all-projects/project-analytics/code-velocity/commit-analysis#organization-commits',

  [AxesChartName.CommitAnalysisCodeVsMergeChart]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/all-projects/project-analytics/code-velocity/commit-analysis#code-changes',

  [AxesChartName.CommitAnalysisPairProgrammingCommitsChart]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/all-projects/project-analytics/code-velocity/commit-analysis#pair-programming-commits',

  [AxesChartName.ProjectTrendLOCAdded]: {
    '/global/project-trends':
      'https://docs.linuxfoundation.org/lfx/insights/v2-current/project-trends/loc-added-and-loc-deleted',

    // project link

    '/trends':
      'https://docs.linuxfoundation.org/lfx/insights/v2-current/all-projects/project-analytics/trends/loc-added-and-loc-deleted',
  },
  [AxesChartName.ProjectTrendLOCDeleted]: {
    '/global/project-trends':
      'https://docs.linuxfoundation.org/lfx/insights/v2-current/project-trends/loc-added-and-loc-deleted',

    // project link

    '/trends':
      'https://docs.linuxfoundation.org/lfx/insights/v2-current/all-projects/project-analytics/trends/loc-added-and-loc-deleted',
  },

  [AxesChartName.ContributorsChurnedChart]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/all-projects/project-analytics/technical-contributors/contributor-churn/contributors-churned',

  [AxesChartName.TotalPRsSubmittedVsMergedChart]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/all-projects/project-analytics/code-velocity/pull-request-pipeline#total-number-of-pull-requests',

  [AxesChartName.PRsSubmittedVsMergedChart]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/all-projects/project-analytics/code-velocity/pull-request-pipeline#pull-request-trends',

  [AxesChartName.ActivePRSubmittersChart]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/all-projects/project-analytics/code-velocity/pull-request-pipeline#active-submitters',

  [AxesChartName.AverageLeadTimeToMergeChart]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/all-projects/project-analytics/code-velocity/pull-request-pipeline#time-to-merge',

  [AxesChartName.TimeInReviewChart]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/all-projects/project-analytics/code-velocity/pull-request-pipeline#time-in-review',

  [AxesChartName.PREfficiencyForNewContributorsChart]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/all-projects/project-analytics/code-velocity/pull-request-pipeline#new-contributor-efficiency',

  [AxesChartName.IssueRequestPipelineAvgTimeToResolveChart]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/all-projects/project-analytics/code-velocity/issue-request-pipeline#average-time-to-resolve',

  [AxesChartName.ActiveRepositoriesChart]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/all-projects/project-analytics/code-base/repositories#active-repositories',

  [AxesChartName.RepositoriesForks]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/all-projects/project-analytics/code-base/repositories#forks',

  [AxesChartName.NewSubmittersAndAssigneesChart]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/all-projects/project-analytics/code-velocity/issue-request-pipeline#new-contributors',

  [AxesChartName.TotalIssuesSubmittedVsResolvedChart]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/all-projects/project-analytics/code-velocity/issue-request-pipeline#issue-history',

  [AxesChartName.NewIssuesLoggedVsResolvedChart]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/all-projects/project-analytics/code-velocity/issue-request-pipeline#new-issues',

  [AxesChartName.AcknowledgementTimeChart]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/all-projects/project-analytics/code-velocity/issue-request-pipeline#efficiency',

  [AxesChartName.SubmittersVsAssigneesChart]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/all-projects/project-analytics/code-velocity/issue-request-pipeline#contributor-type',

  [AxesChartName.IssuesUnderActiveDevelopmentVsInBacklogChart]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/all-projects/project-analytics/code-velocity/issue-request-pipeline#backlog',

  [AxesChartName.PRWaitingChart]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/all-projects/project-analytics/code-base/repositories#open-prs',

  [AxesChartName.IssueWaitingChart]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/all-projects/project-analytics/code-base/repositories#open-issues',

  [AxesChartName.InActiveRepositoriesChart]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/all-projects/project-analytics/code-base/repositories#inactive-repositories',

  [AxesChartName.WatchersVSStarsChart]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/all-projects/project-analytics/code-base/repositories#watchers-vs-stars',

  [AxesChartName.FeatureAndBugRequestsChart]: {
    '/global/project-trends':
      'https://docs.linuxfoundation.org/lfx/insights/v2-current/project-trends/issue-history',

    // project link

    '/trends':
      'https://docs.linuxfoundation.org/lfx/insights/v2-current/all-projects/project-analytics/trends/issue-history',
  },
  //#endregion

  ////////////////////////
  // #region Bubble Charts
  ////////////////////////

  [BubbleChartName.EventAttendeesByJobFunctionChart]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/event-analytics/event-attendees#event-attendees-by-job-function',

  [BubbleChartName.EventAttendeesByOrganizationChart]: '',

  [BubbleChartName.EventSpeakersByJobFunctionChart]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/event-analytics/event-speakers#event-speakers-by-job-function',

  [BubbleChartName.EventSpeakersByOrganizationChart]: {
    '/global/event-analytics/attendees':
      'https://docs.linuxfoundation.org/lfx/insights/v2-current/event-analytics/event-attendees#event-attendees-by-organizations',

    '/global/event-analytics/speakers':
      'https://docs.linuxfoundation.org/lfx/insights/v2-current/event-analytics/event-speakers#event-speakers-by-organizations',
  },

  [BubbleChartName.PopularProjectsByMembershipsChart]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/membership-analytics/overview/popular-projects',

  [BubbleChartName.TrainingMostEngagedOrganizationsChart]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/training-and-certification-analytics/training-analytics/organizations-by-training-enrollment',
  // 'https://docs.linuxfoundation.org/lfx/insights/v2-current/training-and-certification-analytics/training-analytics/trainees/trainees-by-geography-and-by-job-functions',

  [BubbleChartName.CertificationsMostEngagedOrganizationsChart]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/training-and-certification-analytics/certification-analytics/most-engaged-organizations',

  [BubbleChartName.PopularProjectsPerWebinarsChart]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/webinar-analytics/project-popularity',

  [BubbleChartName.TechnicalContributionsChart]: '',

  [BubbleChartName.EmailMostEngagedOrganizationsChart]: '',

  [BubbleChartName.ParticipantOrganizationEngagementByCommitChart]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/all-projects/project-analytics/technical-contributors/organizations/commits',

  [BubbleChartName.ParticipantOrganizationEngagementByPRActivityChart]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/all-projects/project-analytics/technical-contributors/organizations/pr-activities',

  [BubbleChartName.ParticipantOrganizationEngagementByBugFeatureRequestChart]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/all-projects/project-analytics/technical-contributors/organizations/bugs-and-new-feature-requests',

  [BubbleChartName.MostActiveNewOrganizationsChart]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/all-projects/project-analytics/technical-contributors/contributor-acquisition/most-active-new-organizations',

  [BubbleChartName.CommitAnalysisProgrammingLanguagesUsedChart]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/all-projects/project-analytics/code-velocity/commit-analysis#programming-languages',

  // #endregion

  ////////////////////////
  // #region Gauge Charts
  ////////////////////////

  [GaugeChartName.CommitToMergeLeadTimeChart]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/all-projects/project-analytics/code-velocity/commit-analysis#commit-to-merge-time',

  // #endregion

  ////////////////////////
  // #region Radar Charts
  ////////////////////////

  [RadarChartName.ContributorStrengthDeveloperCohortsChart]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/all-projects/project-analytics/technical-contributors/contributor-strength/developer-cohorts',

  [RadarChartName.ContributorStrengthDevelopersEngagedChart]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/all-projects/project-analytics/technical-contributors/contributor-strength/developers-engaged-in-multiple-projects',

  [RadarChartName.CodingDaysChart]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/all-projects/project-analytics/code-velocity/commit-analysis#code-by-day',

  [RadarChartName.CodeVelocityIssueResolutionEfficiency]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/all-projects/project-analytics/code-velocity/issue-request-pipeline#issue-resolution-efficiency',

  //#endregion

  ////////////////////////
  // #region Map Charts
  ////////////////////////

  [WorldMapChartName.CertifiedIndividualsByCountriesChart]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/training-and-certification-analytics/certification-analytics/certified-individuals-by-countries',

  [WorldMapChartName.LearnersAcrossTheGlobeChart]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/training-and-certification-analytics/training-analytics/trainees/trainees-by-geography-and-by-job-functions',

  [WorldMapChartName.WebinarsAttendedByCountriesChart]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/webinar-analytics/attendee-geo-locations',

  [WorldMapChartName.EventsAttendeesByCountryChart]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/event-analytics/event-attendees#attendees-by-geography',

  [WorldMapChartName.EventsSpeakersByCountryChart]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/event-analytics/event-speakers#speakers-by-geo-locations',

  // #endregion

  ////////////////////////
  // #region Word Charts
  ////////////////////////

  [WordChartName.EventAttendeesByJobLevelChart]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/event-analytics/event-attendees#attendees-by-job-level',

  [WordChartName.EventSpeakersByJobLevelChart]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/event-analytics/event-speakers#speakers-by-job-level',

  [WordChartName.PopularProgramsByEnrollmentsChart]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/training-and-certification-analytics/training-analytics/popular-programs',

  // #endregion

  ////////////////////////
  // #region Funnel Charts
  ////////////////////////

  [FunnelChartName.PRRequestPipeline]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/all-projects/project-analytics/code-velocity/pull-request-pipeline#request-pipeline',

  [FunnelChartName.PRCycleTime]: {
    '/global/project-trends':
      'https://docs.linuxfoundation.org/lfx/insights/v2-current/all-projects/project-analytics/code-velocity/pull-request-pipeline#request-pipeline',

    // project link

    '/trends':
      'https://docs.linuxfoundation.org/lfx/insights/v2-current/all-projects/project-analytics/trends/pr-cycle-time',

    '/code-velocity/pull-request-pipeline':
      'https://docs.linuxfoundation.org/lfx/insights/v2-current/all-projects/project-analytics/code-velocity/pull-request-pipeline#cycle-time',
  },
  [FunnelChartName.PipelineTimeChart]: {
    '/global/project-trends':
      'https://docs.linuxfoundation.org/lfx/insights/v2-current/project-trends/pipeline',

    // project link

    '/trends':
      'https://docs.linuxfoundation.org/lfx/insights/v2-current/all-projects/project-analytics/trends/pipeline',
  },
  [FunnelChartName.IssuesResolutionPipelineChart]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/all-projects/project-analytics/code-velocity/issue-request-pipeline#resolution-pipeline',

  //#endregion

  ////////////////////////
  // #region Tables
  ////////////////////////

  [TableName.TopTenAttendeesTable]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/event-analytics/event-attendees#top-10-attendees',

  [TableName.TopTenSpeakersTable]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/event-analytics/event-speakers#top-10-speakers',

  [TableName.MostEngagedOrganizationsTable]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/event-analytics/organization-participation#organization-participation-leaderboard',

  [TableName.TopTenMostPopularWebinarsTable]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/webinar-analytics/most-popular',

  [TableName.TopTenMostFrequentAttendeesTable]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/webinar-analytics/top-attendees',

  [TableName.TopTenMostEngagedIndividualsTable]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/training-and-certification-analytics/training-analytics/top-trainees',

  [TableName.MostEngagedIndividualsTable]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/training-and-certification-analytics/certification-analytics/most-engaged-individuals',

  [TableName.TopTenContributorsTable]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/all-projects/project-analytics/technical-contributors/contributor-strength/top-10-contributors-and-top-10-participating-organizations',

  [TableName.TopTenParticipatingOrganizationsTable]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/all-projects/project-analytics/technical-contributors/contributor-strength/top-10-contributors-and-top-10-participating-organizations',

  [TableName.EmailTopTenEngagedIndividualsTable]: '',

  [TableName.ProjectMembershipsTable]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/membership-analytics/project-memberships',

  [TableName.OrganizationLeaderboardTable]: '',

  [TableName.TopTenParticipatingNewOrganizationsTable]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/all-projects/project-analytics/technical-contributors/contributor-acquisition/top-10-new-contributors-and-top-10-new-participating-organizations',

  [TableName.ActiveTrainingProgramsTable]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/training-and-certification-analytics/training-analytics/most-popular-programs',

  [TableName.PopularCertificationProgramsTable]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/training-and-certification-analytics/certification-analytics/popular-certification-programs',

  [TableName.ParticipantTopTenEngagedOrganizationTable]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/all-projects/project-analytics/technical-contributors/organizations/top-10-most-engaged-organizations',

  [TableName.TopTenMostActiveUnaffiliatedContributorsTable]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/all-projects/project-analytics/technical-contributors/unaffiliated-contributors/top-10-most-active-unaffiliated-contributors',

  [TableName.CommitAnalysisContributorLeaderboardTable]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/all-projects/project-analytics/code-velocity/commit-analysis#top-10-contributors',

  [TableName.CommitAnalysisOrganizationLeaderboardTable]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/all-projects/project-analytics/code-velocity/commit-analysis#top-10-contributors',

  [TableName.TopTenContributorsChurnedTable]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/all-projects/project-analytics/technical-contributors/contributor-churn/most-engaged-contributors-who-drifted-away',

  [TableName.PullRequestPipeLineTopTenParticipatingOrganizationsTable]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/all-projects/project-analytics/code-velocity/pull-request-pipeline#top-10-contributors',

  [TableName.IssueRequestPipeOrganizationLeaderboardTable]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/all-projects/project-analytics/code-velocity/issue-request-pipeline#top-10-organizations',
  [TableName.RepositoriesThatNeedAttentionTable]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/all-projects/project-analytics/code-base/repositories-that-need-attention',

  [TableName.CodeBaseRepositoriesTable]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/all-projects/project-analytics/code-base/repositories#repositories',

  [TableName.TopTenActiveContributorsTable]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/all-projects/project-analytics/code-velocity/issue-request-pipeline#top-10-contributors',

  [TableName.TopTenNewContributorsTable]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/all-projects/project-analytics/technical-contributors/contributor-acquisition/top-10-new-contributors-and-top-10-new-participating-organizations',

  [TableName.TopTenPRContributorsTable]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/all-projects/project-analytics/code-velocity/pull-request-pipeline#top-10-contributors',

  // #endregion

  ////////////////////////
  // #region Pie Charts
  ////////////////////////

  [PieChartName.EventAttendeesByGenderChart]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/event-analytics/event-attendees#attendees-by-gender',

  [PieChartName.EventAttendeesByIndustryChart]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/event-analytics/event-attendees#event-attendees-by-industry',

  [PieChartName.EventSpeakersByGenderChart]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/event-analytics/event-speakers#speakers-by-gender',

  [PieChartName.EventSpeakersByIndustryChart]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/event-analytics/event-speakers#event-speakers-by-industry',

  [PieChartName.MembersByIndustryChart]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/membership-analytics/overview/industries',

  [PieChartName.MembersByOrganizationSizeChart]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/membership-analytics/overview/employee-size',

  [PieChartName.OrganizationAttendingEventsByMembershipChart]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/event-analytics/organization-participation#organization-attending-events-by-membership',

  [PieChartName.OrganizationSpeakingAtEventsByMembershipChart]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/event-analytics/organization-participation#organization-speaking-at-events-by-membership',

  [PieChartName.OrganizationAttendingEventsBySizeChart]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/event-analytics/organization-participation#organizations-attending-events-by-size',

  [PieChartName.OrganizationSpeakingAtEventsBySizeChart]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/event-analytics/organization-participation#organizations-speaking-at-events-by-size',

  [PieChartName.TrainingEnrollmentsByTechnologyChart]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/training-and-certification-analytics/training-analytics/popular-technologies',

  [PieChartName.CertificationsEnrollmentsByTechnologyChart]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/training-and-certification-analytics/certification-analytics/popular-certifications-by-enrollments-and-enrollments-by-technology',

  [PieChartName.EnrollmentsByOrganizationSizeChart]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/training-and-certification-analytics/training-analytics/organizations-by-size',

  [PieChartName.EnrollmentsByJobFunctionChart]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/training-and-certification-analytics/training-analytics/trainees/trainees-by-geography-and-by-job-functions',

  [PieChartName.PopularCertificationsByEnrollmentsChart]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/training-and-certification-analytics/certification-analytics/popular-certifications-by-enrollments-and-enrollments-by-technology',

  [PieChartName.CertifiedIndividualsByJobFunctionChart]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/training-and-certification-analytics/certification-analytics/certified-individuals-by-job-function',

  [PieChartName.EngagedOrganizationsBySizeChart]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/training-and-certification-analytics/certification-analytics/most-engaged-organizations/engaged-organization-by-size',

  [PieChartName.WebinarsByTechnologySectorChart]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/webinar-analytics/technology',

  [PieChartName.AttendeesFromKnownAndUnknownOrganizationsChart]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/webinar-analytics/affiliated-attendees',

  [PieChartName.EmailParticipantsByOrgAffiliationsChart]: '',

  [PieChartName.ContributionsByNewAndExistingContributorsChart]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/all-projects/project-analytics/technical-contributors/contributor-acquisition/contributor-impact-comparison',

  [PieChartName.ContributionsByParticipatingOrganizationsChart]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/all-projects/project-analytics/technical-contributors/contributor-acquisition/contributions-by-organizations',
  [PieChartName.NewOrganizationsBySizeChart]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/all-projects/project-analytics/technical-contributors/contributor-acquisition/employee-size',

  [PieChartName.ParticipantOrganizationEngagementBySizeChart]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/all-projects/project-analytics/technical-contributors/organizations/organization-size',

  [PieChartName.ParticipantOrganizationEngagementByTechSectorChart]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/all-projects/project-analytics/technical-contributors/organizations/technology-sector',

  [PieChartName.ParticipantOrganizationEngagementByMembershipChart]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/all-projects/project-analytics/technical-contributors/organizations/membership',

  [PieChartName.ActiveOrganizationsByTechnologyChart]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/organization-contribution-index#active-organization-by-industry',

  [PieChartName.ActiveOrganizationsBySizeChart]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/organization-contribution-index#active-organization-by-size',

  [PieChartName.ParticipatingOrganizationsChart]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/all-projects/project-analytics/technical-contributors/contributor-acquisition/organizations',

  [PieChartName.AffiliatedVsUnaffiliatedContributionsChart]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/all-projects/project-analytics/technical-contributors/unaffiliated-contributors/affiliated-vs.-unaffiliated-contributions',

  [PieChartName.CommitAnalysisDocumentationCommitsChart]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/all-projects/project-analytics/code-velocity/commit-analysis#documentation',

  [PieChartName.SubmittersVsReviewersChart]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/all-projects/project-analytics/code-velocity/pull-request-pipeline#contributor-type',

  [PieChartName.ContributorStrengthActiveOrganizationChart]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/all-projects/project-analytics/technical-contributors/contributor-strength/active-organizations',

  [PieChartName.ContributorStrengthMemberOrganizationChart]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/all-projects/project-analytics/technical-contributors/contributor-strength/contributors-from-member-organizations',

  [PieChartName.ProgrammingLanguageDistributionChart]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/all-projects/project-analytics/code-base/repositories#programming-language-distribution',

  [PieChartName.ContributingOrganizationChart]: {
    '/global/project-trends':
      'https://docs.linuxfoundation.org/lfx/insights/v2-current/project-trends/contribution-affiliation',

    '/trends':
      'https://docs.linuxfoundation.org/lfx/insights/v2-current/all-projects/project-analytics/trends/contribution-affiliation',
  },

  [AxesChartName.DocumentTotalParticipantsChart]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/all-projects/project-analytics/documentation/participant-analytics#total-participants',
  [AxesChartName.DocumentActiveChart]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/all-projects/project-analytics/documentation/participant-analytics#active-and-drifting-away',

  [AxesChartName.DocumentDriftAwayChart]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/all-projects/project-analytics/documentation/participant-analytics#active-and-drifting-away',

  [AxesChartName.DocumentNewParticipantsChart]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/all-projects/project-analytics/documentation/participant-analytics#new-participants',

  [PieChartName.DocumentParticipantsByOrgAffiliationsChart]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/all-projects/project-analytics/documentation/participant-analytics#participants-by-org-affiliations',

  [AxesChartName.DocumentMostEngagedOrganizationsChart]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/all-projects/project-analytics/documentation/participant-analytics#most-engaged-organizations',

  [TableName.Top10EngagedIndividualsTable]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/all-projects/project-analytics/documentation/participant-analytics#top-10-engaged-individuals',

  ////
  [AxesChartName.DocumentActivitiesChart]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/all-projects/project-analytics/documentation/activity-analysis#document-activities',

  [RadarChartName.DocumentMostActiveDaysChart]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/all-projects/project-analytics/documentation/activity-analysis#most-active-days',

  [AxesChartName.DocumentPopularPagesChart]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/all-projects/project-analytics/documentation/activity-analysis#popular-pages-and-popular-stages',

  [AxesChartName.DocumentPopularSpacesChart]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/all-projects/project-analytics/documentation/activity-analysis#popular-pages-and-popular-stages',

  ////
  [AxesChartName.DocumentTotalParticipatingOrganizationsChart]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/all-projects/project-analytics/documentation/organization-participation#total-participating-organizations',

  [AxesChartName.DocumentActiveOrganizationsChart]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/all-projects/project-analytics/documentation/organization-participation#active-and-drifting-away',

  [AxesChartName.DocumentDriftAwayOrganizationsChart]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/all-projects/project-analytics/documentation/organization-participation#active-and-drifting-away',

  [AxesChartName.DocumentNewOrganizationsChart]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/all-projects/project-analytics/documentation/organization-participation#new-organizations',

  [PieChartName.DocumentOrganizationsBySizeChart]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/all-projects/project-analytics/documentation/organization-participation#active-organizations-by-size',

  [AxesChartName.DocumentTopOrganizationChart]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/all-projects/project-analytics/documentation/organization-participation#top-organizations-by-active-participants',

  [TableName.MostActiveOrganizationsTable]:
    'https://docs.linuxfoundation.org/lfx/insights/v2-current/all-projects/project-analytics/documentation/organization-participation#most-active-organizations',

  // #endregion Pie Charts
};
