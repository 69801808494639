import { Injectable } from '@angular/core';
import { FeatureFlagManagerService } from '@services';

@Injectable({
  providedIn: 'root',
})
export class InitService {
  constructor(private featureFlagManagerService: FeatureFlagManagerService) {}

  init() {
    return this.featureFlagManagerService.initialize();
  }
}
