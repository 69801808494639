export enum BubbleChartName {
  EventAttendeesByJobFunctionChart = 'EventAttendeesByJobFunctionChart',
  EventAttendeesByOrganizationChart = 'EventAttendeesByOrganizationChart',
  EventSpeakersByJobFunctionChart = 'EventSpeakersByJobFunctionChart',
  EventSpeakersByOrganizationChart = 'EventSpeakersByOrganizationChart',
  PopularProjectsByMembershipsChart = 'PopularProjectsByMembershipsChart',
  TrainingMostEngagedOrganizationsChart = 'TrainingMostEngagedOrganizationsChart',
  CertificationsMostEngagedOrganizationsChart = 'CertificationsMostEngagedOrganizationsChart',
  PopularProjectsPerWebinarsChart = 'PopularProjectsPerWebinarsChart',
  TechnicalContributionsChart = 'TechnicalContributionsChart',
  EmailMostEngagedOrganizationsChart = 'EmailMostEngagedOrganizationsChart',
  ParticipantOrganizationEngagementByCommitChart = 'ParticipantOrganizationEngagementByCommitChart',
  ParticipantOrganizationEngagementByPRActivityChart = 'ParticipantOrganizationEngagementByPRActivityChart',
  ParticipantOrganizationEngagementByBugFeatureRequestChart = 'ParticipantOrganizationEngagementByBugFeatureRequestChart',
  ContributorStrengthTechnicalContributionChart = 'ContributorStrengthTechnicalContributionChart',
  MostActiveNewOrganizationsChart = 'MostActiveNewOrganizationsChart',
  CommitAnalysisProgrammingLanguagesUsedChart = 'CommitAnalysisProgrammingLanguagesUsedChart',
  ChangesetActivitiesChart = 'ChangesetActivitiesChart',
  DocumentMostEngagedOrganizationsChart = 'DocumentMostEngagedOrganizationsChart',
  DocumentPopularPagesChart = 'DocumentPopularPagesChart',
  DocumentPopularSpacesChart = 'DocumentPopularSpacesChart',
  DocumentTopOrganizationChart = 'DocumentTopOrganizationChart',
}
