import { Injectable } from '@angular/core';
import { ChartsQueryParams, WordChartName, WordChart } from '@models';
import { Apollo } from 'apollo-angular';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { wordChartQuery, wordChartWithoutValueQuery } from '../queries';

interface WordChartResult {
  wordChart: WordChart;
}

export interface WordChartInput {
  wordChartName: WordChartName;
  chartsQueryParams: ChartsQueryParams;
}

@Injectable({
  providedIn: 'root',
})
export class WordChartServiceGql {
  constructor(private apollo: Apollo) {}

  getWordChart(wordChartInput: WordChartInput): Observable<WordChart> {
    return this.apollo
      .query<WordChartResult>({
        query:
          wordChartInput.wordChartName ===
          WordChartName.PopularProgramsByEnrollmentsChart
            ? wordChartWithoutValueQuery
            : wordChartQuery,
        fetchPolicy: 'network-only',
        variables: {
          wordChartInput: wordChartInput,
        },
      })
      .pipe(
        catchError(error => {
          if (
            error &&
            error.graphQLErrors &&
            error.graphQLErrors[0] &&
            error.graphQLErrors[0].extensions &&
            error.graphQLErrors[0].extensions.code === 'UNAUTHENTICATED'
          ) {
            return throwError(() => new Error('UNAUTHENTICATED'));
          }

          console.log(error);

          return of(null);
        }),
        map((res: any) => {
          if (!res) {
            return null;
          }

          return res.data.wordChart;
        })
      );
  }
}
