export enum PieChartName {
  EventAttendeesByGenderChart = 'EventAttendeesByGenderChart',
  EventAttendeesByIndustryChart = 'EventAttendeesByIndustryChart',
  EventSpeakersByGenderChart = 'EventSpeakersByGenderChart',
  EventSpeakersByIndustryChart = 'EventSpeakersByIndustryChart',
  MembersByIndustryChart = 'MembersByIndustryChart',
  MembersByOrganizationSizeChart = 'MembersByOrganizationSizeChart',
  OrganizationAttendingEventsByMembershipChart = 'OrganizationAttendingEventsByMembershipChart',
  OrganizationSpeakingAtEventsByMembershipChart = 'OrganizationSpeakingAtEventsByMembershipChart',
  OrganizationAttendingEventsBySizeChart = 'OrganizationAttendingEventsBySizeChart',
  OrganizationSpeakingAtEventsBySizeChart = 'OrganizationSpeakingAtEventsBySizeChart',
  TrainingEnrollmentsByTechnologyChart = 'TrainingEnrollmentsByTechnologyChart',
  EnrollmentsByOrganizationSizeChart = 'EnrollmentsByOrganizationSizeChart',
  EnrollmentsByJobFunctionChart = 'EnrollmentsByJobFunctionChart',
  CertificationsEnrollmentsByTechnologyChart = 'CertificationsEnrollmentsByTechnologyChart',
  PopularCertificationsByEnrollmentsChart = 'PopularCertificationsByEnrollmentsChart',
  CertifiedIndividualsByJobFunctionChart = 'CertifiedIndividualsByJobFunctionChart',
  EngagedOrganizationsBySizeChart = 'EngagedOrganizationsBySizeChart',
  WebinarsByTechnologySectorChart = 'WebinarsByTechnologySectorChart',
  AttendeesFromKnownAndUnknownOrganizationsChart = 'AttendeesFromKnownAndUnknownOrganizationsChart',
  EmailParticipantsByOrgAffiliationsChart = 'EmailParticipantsByOrgAffiliationsChart',
  ContributionsByNewAndExistingContributorsChart = 'ContributionsByNewAndExistingContributorsChart',
  ContributionsByParticipatingOrganizationsChart = 'ContributionsByParticipatingOrganizationsChart',
  NewOrganizationsBySizeChart = 'NewOrganizationsBySizeChart',
  ParticipantOrganizationEngagementBySizeChart = 'ParticipantOrganizationEngagementBySizeChart',
  ParticipantOrganizationEngagementByTechSectorChart = 'ParticipantOrganizationEngagementByTechSectorChart',
  ParticipantOrganizationEngagementByMembershipChart = 'ParticipantOrganizationEngagementByMembershipChart',
  ActiveOrganizationsByTechnologyChart = 'ActiveOrganizationsByTechnologyChart',
  ActiveOrganizationsBySizeChart = 'ActiveOrganizationsBySizeChart',
  ParticipatingOrganizationsChart = 'ParticipatingOrganizationsChart',
  AffiliatedVsUnaffiliatedContributionsChart = 'AffiliatedVsUnaffiliatedContributionsChart',
  CommitAnalysisDocumentationCommitsChart = 'CommitAnalysisDocumentationCommitsChart',
  SubmittersVsReviewersChart = 'SubmittersVsReviewersChart',
  ContributorStrengthActiveOrganizationChart = 'ContributorStrengthActiveOrganizationChart',
  ContributorStrengthMemberOrganizationChart = 'ContributorStrengthMemberOrganizationChart',
  ProgrammingLanguageDistributionChart = 'ProgrammingLanguageDistributionChart',
  ContributingOrganizationChart = 'ContributingOrganizationChart',
  DocumentParticipantsByOrgAffiliationsChart = 'DocumentParticipantsByOrgAffiliationsChart',
  ProjectsByHealthScoreAll = 'ProjectsByHealthScoreAll',
  ProjectsByHealthScoreGraduated = 'ProjectsByHealthScoreGraduated',
  ProjectsByHealthScoreIncubating = 'ProjectsByHealthScoreIncubating',
  ProjectsByHealthScoreSandbox = 'ProjectsByHealthScoreSandbox',
  DocumentOrganizationsBySizeChart = 'DocumentOrganizationsBySizeChart',
}
