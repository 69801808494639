import { RadarChartApiDataSource } from './api-data-source';
import { RadarChartMockDataSource } from './mock-data-source';

export interface RadarChartConfig {
  chart: {
    markPoint?: any;
    seriesName: string;
  };
  data?: RadarChartMockDataSource | RadarChartApiDataSource;
}
