import { Injectable } from '@angular/core';
import { User } from '@models';
import { Apollo } from 'apollo-angular';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { currentUserQuery, CheckUserRoleQuery } from '../queries';

interface CurrentUserResult {
  currentUser: User;
}

interface HasRoleResult {
  hasPermission: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class UserServiceGql {
  constructor(private apollo: Apollo) {}

  getCurrentUser(
    withPermissions = true,
    withSocialAccounts = false,
    withEmails = false,
    withAvatar = false,
    withRoles = true
  ): Observable<User> {
    return this.apollo
      .query<CurrentUserResult>({
        query: currentUserQuery,
        fetchPolicy: 'network-only',
        variables: {
          withPermissions,
          withSocialAccounts,
          withEmails,
          withAvatar,
          withRoles,
          basic: !withPermissions,
        },
      })
      .pipe(
        catchError(error => {
          if (
            error &&
            error.graphQLErrors &&
            error.graphQLErrors[0] &&
            error.graphQLErrors[0].extensions &&
            error.graphQLErrors[0].extensions.code === 'UNAUTHENTICATED'
          ) {
            return throwError(() => new Error('UNAUTHENTICATED'));
          }

          console.log(error);

          return of(null);
        }),
        map((res: any) => {
          if (!res) {
            return null;
          }

          return res.data.currentUser;
        })
      );
  }

  checkUserHasRole(role: string): Observable<HasRoleResult> {
    return this.apollo
      .query<HasRoleResult>({
        query: CheckUserRoleQuery,
        fetchPolicy: 'network-only',
        variables: {
          checkUserRoleInput: role,
        },
      })
      .pipe(
        catchError(error => {
          if (
            error &&
            error.graphQLErrors &&
            error.graphQLErrors[0] &&
            error.graphQLErrors[0].extensions &&
            error.graphQLErrors[0].extensions.code === 'UNAUTHENTICATED'
          ) {
            return throwError(() => new Error('UNAUTHENTICATED'));
          }

          console.log(error);

          return of(null);
        }),
        map((res: any) => {
          if (!res) {
            return null;
          }

          return res.data.checkUserRoles;
        })
      );
  }
}
