// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT
export interface ProjectsWithStatsQueryParams {
  filterBy?: string;
  pageSize?: number;
  offset?: number;
  orderBy?: string;
  technology?: string;
  withProjectsFilterValues: boolean;
}
