// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { gql } from 'apollo-angular';

export const projectsWithStatsQuery = gql`
  query ProjectsWithStats(
    $projectsWithStatsInput: ProjectsWithStatsQueryParamsInput
  ) {
    projectsWithStats(projectsWithStatsInput: $projectsWithStatsInput) {
      projectsWithStatsData
      pagination
      filters {
        technology {
          name
          value
        }
      }
    }
  }
`;
