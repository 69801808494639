// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { gql } from 'apollo-angular';

export const shortenUrlQuery = gql`
  query ShortenUrl($url: String) {
    shortenUrl(url: $url) {
      url
      alias
      tags
      domain
      deleted
      archived
      createdAt
      expiresAt
      tinyUrl
    }
  }
`;
