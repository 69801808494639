import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { environment } from '@environment';
import { CourseService } from '@lfx/core/services/course.service';
import { EventService } from '@lfx/core/services/event.service';
import { ProjectService } from '@lfx/core/services/project.service';
import {
  Event,
  MenuSubItem,
  Project,
  ProjectEntityType,
  ProjectStatus,
  SideMenu,
} from '@models';
import { cloneDeep } from 'lodash';
import { concat, Observable, of, Subject, Subscription } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  first,
  map,
  switchMap,
  tap,
} from 'rxjs/operators';

@Component({
  selector: 'lfx-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
})
export class SideNavComponent implements OnInit, OnDestroy {
  // Removed dropdown ref: #IN-1801
  // options = [
  // {
  //   name: 'Projects',
  //   key: 'projects',
  // },
  // {
  //   name: 'Events',
  //   key: 'events',
  // },
  // {
  //   name: 'Training & Certification',
  //   key: 'trainingAndCertification',
  // },
  // ];

  comingSoonItems = [
    'Chat',
    'Email',
    'Package Downloads',
    'Earned Media',
    'Social Media',
  ];

  selectedOption: string = '';
  globalMenu: SideMenu[] = [
    {
      title: 'Organization Contribution Index',
      icon: 'normal_global_organization_oss_index_icon.svg',
      activeIcon: 'normal_global_organization_oss_index_icon.svg',
      link: '/global/organization-contribution-index',
      active: false,
    },
    {
      title: 'Project Trends',
      icon: 'normal_project_trends_icon.svg',
      activeIcon: 'selected_project_trends_icon.svg',
      link: '/global/project-trends',
      active: false,
    },
    {
      title: 'Event Analytics',
      icon: 'normal_events_analytics_icon.svg',
      activeIcon: 'selected_events_analytics_icon.svg',
      link: undefined,
      active: false,
      subItems: [
        {
          title: 'Attendees',
          link: '/global/event-analytics/attendees',
          active: false,
        },
        {
          title: 'Speakers',
          link: '/global/event-analytics/speakers',
          active: false,
        },
        {
          title: 'Organizations',
          link: '/global/event-analytics/organizations',
          active: false,
        },
      ],
    },
    {
      title: 'Webinar Analytics',
      icon: 'normal_webinar_icon.svg',
      activeIcon: 'selected_webinar_icon.svg',
      link: '/global/webinar-analytics',
      active: false,
    },
    {
      title: 'Training & Certification Analytics',
      icon: 'normal_training_certification_icon.svg',
      activeIcon: 'selected_training_certification_icon.svg',
      link: undefined,
      active: false,
      subItems: [
        {
          title: 'Training',
          link: '/global/training-and-certification-analytics/training',
          active: false,
        },
        {
          title: 'Certification',
          link: '/global/training-and-certification-analytics/certification',
          active: false,
        },
      ],
    },
    {
      title: 'Membership Analytics',
      icon: 'normal_membership_analytics_icon.svg',
      activeIcon: 'selected_membership_analytics_icon.svg',
      link: undefined,
      active: false,
      subItems: [
        {
          title: 'Overview',
          link: '/global/membership-analytics/overview',
          active: false,
        },
        {
          title: 'Project Memberships',
          link: '/global/membership-analytics/project-memberships',
          active: false,
        },
      ],
    },
  ];

  allProjectsMenu: SideMenu[] = [
    {
      title: 'All Projects',
      icon: 'normal_projects_icon.svg',
      activeIcon: 'selected_projects_icon.svg',
      link: '/projects',
      active: true,
    },
  ];

  projectMenu: SideMenu[] = [];
  eventMenu: SideMenu[] = [];
  trainingMenu: SideMenu[] = [];

  selectedProject: Project | undefined;
  projectSubscription: Subscription;

  selectedEvent: Event | undefined;

  projectLoading = false;
  projectsInput$ = new Subject<string>();
  // projects$ = new Observable<Project[]>();
  // projects$ = new Observable<any>();
  projects: any;

  eventLoading = false;
  eventsInput$ = new Subject<string>();
  events$ = new Observable<any[]>();

  trainingAndCertLoading = false;
  trainingAndCertsInput$ = new Subject<string>();
  trainingAndCerts$ = new Observable<any[]>();

  ProjectStatus = ProjectStatus;
  ProjectEntityType = ProjectEntityType;
  isSideNavCollapsed = false;

  constructor(
    private router: Router,
    public projectService: ProjectService,
    public eventService: EventService,
    public courseService: CourseService,
    public authService: AuthService
  ) {
    this.setTrainingMenu();
    this.setEventMenu();

    /** INFO: removing the projectConnectors subscription here as that's already being handled below
     * Additionally, the heirarchy of loading should be projects first then get the connectors
     */
    this.projectSubscription = this.projectService.selectedProject$.subscribe(
      project => {
        if (!project) {
          this.selectedProject = undefined;

          return;
        }

        if (this.selectedProject?.slug === project.slug) {
          this.setProjectMenu(project);
          this.handleRouterChange(this.router.url);

          return;
        }
        this.selectedProject = project;

        this.setProjectMenu(project);
        this.handleRouterChange(this.router.url);
      }
    );

    this.eventService.selectedEvent$.subscribe(event => {
      if (!event) {
        this.selectedEvent = undefined;

        return;
      }

      if (this.selectedEvent === event) {
        return;
      }

      this.selectedEvent = event;
      this.handleRouterChange('/global/community/events/people/attendees');
    });
  }

  public get isEventsSelected(): boolean {
    return this.selectedOption === 'events';
  }

  public get isTrainingAndCertSelected(): boolean {
    return this.selectedOption === 'trainingAndCertification';
  }

  ngOnInit(): void {
    // this.selectedOption = this.options[0].key;

    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.handleRouterChange(event.url);

        if (event.url.includes('community/events')) {
          this.selectedOption = 'events';
        }

        if (event.url.includes('community/training-and-certification')) {
          this.selectedOption = 'trainingAndCertification';
        }
      }
    });

    this.loadProjects();
    this.loadEvents();
    this.loadTraining();
  }

  changeOption() {
    switch (this.selectedOption) {
      case 'events':
        this.router.navigate(['/global/community/events/people/attendees'], {
          queryParamsHandling: 'merge',
        });
        this.projectService.resetSelectedProject();
        break;
      case 'trainingAndCertification':
        this.router.navigate(
          [
            '/global/community/training-and-certification/organization/leaderboard',
          ],
          {
            queryParamsHandling: 'merge',
          }
        );
        this.projectService.resetSelectedProject();
        this.eventService.resetSelectedEvent();
        break;
      case 'projects':
        this.eventService.resetSelectedEvent();
        break;
      default:
        this.projectService.resetSelectedProject();
        this.eventService.resetSelectedEvent();
        this.router.navigate([''], { queryParamsHandling: 'merge' });
        break;
    }
  }

  loadProjects() {
    concat(
      this.projectService.getProjects().pipe(catchError(() => of([]))), // default items
      this.projectsInput$.pipe(
        distinctUntilChanged(),
        debounceTime(300),
        tap(() => (this.projectLoading = true)),

        switchMap(searchTerm => {
          return this.projectService.getProjects().pipe(
            map(r => {
              // stringified projects to avoid projects reference updating issue
              const projects = cloneDeep(r);

              return this.projectService.filterProjects(projects, searchTerm);
            }),
            catchError(() => of([])), // empty list on error
            tap(() => {
              this.projectLoading = false;
            })
          );
        })
      )
    ).subscribe(projectArray => {
      this.projects = projectArray;
    });
  }

  loadEvents() {
    this.events$ = concat(
      this.eventService
        .searchEvents({ name: [''] })
        .pipe(catchError(() => of([]))), // default items
      this.eventsInput$.pipe(
        distinctUntilChanged(),
        debounceTime(300),
        tap(() => (this.eventLoading = true)),

        switchMap(term => {
          const searchQuery = { name: [term ? term : ''] };

          return this.eventService.searchEvents(searchQuery).pipe(
            catchError(() => of([])), // empty list on error
            tap(() => (this.eventLoading = false))
          );
        })
      )
    );
  }

  loadTraining() {
    this.trainingAndCerts$ = concat(
      this.courseService.searchCourses('').pipe(catchError(() => of([]))), // default items
      this.trainingAndCertsInput$.pipe(
        distinctUntilChanged(),
        debounceTime(300),
        tap(() => (this.trainingAndCertLoading = true)),

        switchMap(term => {
          const searchQuery = term ? term : '';

          return this.courseService.searchCourses(searchQuery).pipe(
            catchError(() => of([])), // empty list on error
            tap(() => (this.trainingAndCertLoading = false))
          );
        })
      )
    );
  }

  redirectToGlobal() {
    this.router.navigate(['global', 'project-trends'], {
      queryParamsHandling: 'merge',
    });
  }

  ngOnDestroy() {
    if (this.projectSubscription) {
      this.projectSubscription.unsubscribe();
    }
  }

  setEventMenu(event: string = '') {
    this.eventMenu = [
      {
        title: 'People Analytics',
        icon: 'normal_community_analytics_icon.svg',
        activeIcon: 'selected_community_analytics_icon.svg',
        link: undefined,
        active: false,
        subItems: [
          {
            title: 'Attendees',
            link: `/global/community/events/people/attendees/${event}`,
            active: true,
          },
          {
            title: 'Speakers',
            link: `/global/community/events/people/speakers/${event}`,
            active: false,
          },
        ],
      },
      {
        title: 'Organization Analytics',
        icon: 'normal_project_analytics_icon.svg',
        activeIcon: 'selected_project_analytics_icon.svg',
        link: undefined,
        active: true,
        subItems: [
          {
            title: 'Leaderboard',
            link: `/global/community/events/organization/leaderboard/${event}`,
            active: false,
          },
          {
            title: 'Attendees',
            link: `/global/community/events/organization/attendees/${event}`,
            active: false,
          },
          {
            title: 'Speakers',
            link: `/global/community/events/organization/speakers/${event}`,
            active: false,
          },
        ],
      },
    ];
  }

  setTrainingMenu(): void {
    this.trainingMenu = [
      {
        title: 'People Analytics',
        icon: 'normal_community_analytics_icon.svg',
        activeIcon: 'selected_community_analytics_icon.svg',
        link: undefined,
        active: false,
        subItems: [
          {
            title: 'Training',
            link: `/global/community/training-and-certification/people/training`,
            active: true,
          },
          {
            title: 'Certification',
            link: `/global/community/training-and-certification/people/certification`,
            active: false,
          },
        ],
      },
      {
        title: 'Organization Analytics',
        icon: 'normal_project_analytics_icon.svg',
        activeIcon: 'selected_project_analytics_icon.svg',
        link: undefined,
        active: true,
        subItems: [
          {
            title: 'Leaderboard',
            link: `/global/community/training-and-certification/organization/leaderboard`,
            active: false,
          },
          {
            title: 'Training',
            link: `/global/community/training-and-certification/organization/training`,
            active: false,
          },
          {
            title: 'Certification',
            link: `/global/community/training-and-certification/organization/certification`,
            active: false,
          },
        ],
      },
    ];
  }

  setProjectMenu(projectDetails: any) {
    const isProdEnvironment = environment.datadogEnv === 'production';

    this.projectMenu = [
      {
        title: 'Project Analytics',
        icon: 'normal_project_analytics_icon.svg',
        activeIcon: 'selected_project_analytics_icon.svg',
        link: undefined,
        active: false,
        subItems: [
          {
            title: 'Trends',
            link: `/${this.selectedProject?.slug}/trends`,
            active: false,
          },
          // {
          //   title: 'Webinar Analytics',
          //   link: '',
          //   active: false,
          // },
          {
            title: 'Technical Contributors',
            link: undefined,
            active: false,
            subItems: [
              {
                title: 'Contributor Strength',
                link: `/${this.selectedProject?.slug}/technical-contributors/contributor-strength`,
                active: false,
              },
              {
                title: 'Contributor Acquisition',
                link: `/${this.selectedProject?.slug}/technical-contributors/contributor-acquisition`,
                active: false,
              },
              {
                title: 'Contributor Churn',
                link: `/${this.selectedProject?.slug}/technical-contributors/contributor-churn`,
                active: false,
              },
              // {
              //   title: 'Maintainer Burnout',
              //   link: `/${this.selectedProject?.slug}/technical-contributors/maintainer-burnout`,
              //   active: false,
              //   icon: 'coming_soon.svg',
              // },
              {
                title: 'Organizations',
                link: `/${this.selectedProject?.slug}/technical-contributors/participating-organizations`,
                active: false,
              },
              // ref: #IN-1742 - hidden as mentioned in ticket
              // {
              //   title: 'Unaffiliated Contributors',
              //   link: `/${this.selectedProject?.slug}/technical-contributors/unaffiliated-contributors`,
              //   active: false,
              // },
            ],
          },
          {
            title: 'Code Velocity',
            link: undefined,
            active: false,
            subItems: [
              {
                title: 'Commit Analysis',
                link: `/${this.selectedProject?.slug}/code-velocity/commit-analysis`,
                active: false,
              },
              {
                title: 'Pull Request/Changeset Pipeline',
                link: `/${this.selectedProject?.slug}/code-velocity/pull-request-pipeline`,
                active: false,
              },
              {
                title: 'Issue Request Pipeline',
                link: `/${this.selectedProject?.slug}/code-velocity/issue-request-pipeline`,
                active: false,
                hide: this.projectService.itHasGitOrGerritOnly(),
              },
              // {
              //   title: 'Build Pipeline',
              //   link: `/${this.selectedProject?.slug}/code-velocity/build-pipeline`,
              //   active: false,
              //   icon: 'coming_soon.svg',
              // },
              // {
              //   title: 'Release Pipeline',
              //   link: `/${this.selectedProject?.slug}/code-velocity/release-pipeline`,
              //   active: false,
              //   icon: 'coming_soon.svg',
              // },
            ],
          },
          {
            title: 'Code Base',
            link: undefined,
            active: false,
            subItems: [
              {
                title: 'Repositories',
                link: `/${this.selectedProject?.slug}/code-base/repositories`,
                active: false,
              },
              {
                title: 'Repositories that Need Attention',
                link: `/${this.selectedProject?.slug}/code-base/repositories-that-need-attention`,
                active: false,
              },
              // {
              //   title: 'Repositories with Most Contributor Churn',
              //   link: '',
              //   active: false,
              // },
              // {
              //   title: 'Repositories by Licenses',
              //   link: '',
              //   active: false,
              // },
            ],
          },
          // {
          //   title: 'Chat',
          //   link: undefined,
          //   active: false,
          //   icon: 'coming_soon.svg',
          //   iconTitle: 'Coming soon',
          //   subItems: [
          //     {
          //       title: 'Participant Analytics',
          //       link: `/${this.selectedProject?.slug}/chat/participant-analytics`,
          //       active: false,
          //     },
          //     {
          //       title: 'Channel Analytics',
          //       link: `/${this.selectedProject?.slug}/chat/channel-analytics`,
          //       active: false,
          //     },
          //     {
          //       title: 'Organization Participation',
          //       link: `/${this.selectedProject?.slug}/chat/organization-participation`,
          //       active: false,
          //     },
          //   ],
          // },
          // {
          //   title: 'Email',
          //   link: undefined,
          //   icon: 'coming_soon.svg',
          //   iconTitle: 'Coming soon',
          //   active: false,
          //   subItems: [
          //     {
          //       title: 'Participant Analytics',
          //       link: `/${this.selectedProject?.slug}/email/participant-analytics`,
          //       active: false,
          //     },
          //     {
          //       title: 'Mailinglist Analytics',
          //       link: `/${this.selectedProject?.slug}/email/mailinglist-analytics`,
          //       active: false,
          //     },
          //     {
          //       title: 'Organization Participation',
          //       link: `/${this.selectedProject?.slug}/email/organization-participation`,
          //       active: false,
          //     },
          //   ],
          // },
          {
            title: 'Documentation',
            link: undefined,
            active: false,
            subItems: [
              {
                title: 'Participant Analytics',
                link: `/${this.selectedProject?.slug}/documentation/participant-analytics`,
                active: false,
              },
              {
                title: 'Activity Analysis',
                link: `/${this.selectedProject?.slug}/documentation/activity-analysis`,
                active: false,
              },
              {
                title: 'Organization Participation',
                link: `/${this.selectedProject?.slug}/documentation/organization-participation`,
                active: false,
              },
            ],
          },
          // // {
          // //   title: 'Ecosystem Analytics',
          // //   link: undefined,
          // //   active: false,
          // //   subItems: [
          // //     {
          // //       title: 'Chat',
          // //       link: `/${this.selectedProject?.slug}/ecosystem-analytics/chat`,
          // //       active: false,
          // //     },
          // //     {
          // //       title: 'Email',
          // //       link: `/${this.selectedProject?.slug}/ecosystem-analytics/email`,
          // //       active: false,
          // //     },
          // //     {
          // //       title: 'Documentation ',
          // //       link: `/${this.selectedProject?.slug}/ecosystem-analytics/documentation`,
          // //       active: false,
          // //     },
          // //   ],
          // // },
          // {
          //   title: 'Package Downloads',
          //   link: `/${this.selectedProject?.slug}/package-downloads`,
          //   active: false,
          //   icon: 'coming_soon.svg',
          //   iconTitle: 'Coming soon',
          // },
          // {
          //   title: 'Earned Media',
          //   link: undefined,
          //   icon: 'coming_soon.svg',
          //   active: false,
          //   iconTitle: 'Coming soon',
          //   subItems: [
          //     {
          //       title: 'Readership Analytics',
          //       link: `/${this.selectedProject?.slug}/earned-media/readership-analytics`,
          //       active: false,
          //     },
          //     {
          //       title: 'Earned Mentions',
          //       link: `/${this.selectedProject?.slug}/earned-media/earned-mentions`,
          //       active: false,
          //     },
          //     {
          //       title: 'Share of Voice',
          //       link: `/${this.selectedProject?.slug}/earned-media/share-of-voice`,
          //       active: false,
          //     },
          //     {
          //       title: 'Search Engine Optimization(SEO) Analytics',
          //       link: `/${this.selectedProject?.slug}/earned-media/search-engine-optimization-analytics`,
          //       active: false,
          //     },
          //   ],
          // },
          // {
          //   title: 'Social Media',
          //   link: undefined,
          //   active: false,
          //   icon: 'coming_soon.svg',
          //   iconTitle: 'Coming soon',
          //   subItems: [
          //     {
          //       title: 'Twitter: Participant Analytics',
          //       link: `/${this.selectedProject?.slug}/social-media/twitter-participant-analytics`,
          //       active: false,
          //     },
          //     {
          //       title: 'Twitter: Tweet Analytics',
          //       link: `/${this.selectedProject?.slug}/social-media/tweet-analytics`,
          //       active: false,
          //     },
          //     {
          //       title: 'Twitter: Language Summary',
          //       link: `/${this.selectedProject?.slug}/social-media/twitter-language-summary`,
          //       active: false,
          //     },
          //     {
          //       title: 'Twitter: Shared URL Impressions',
          //       link: `/${this.selectedProject?.slug}/social-media/twitter-shared-url-impressions`,
          //       active: false,
          //     },
          //     {
          //       title: 'Twitter: Hashtag Insights',
          //       link: `/${this.selectedProject?.slug}/social-media/twitter-hashtag-insights`,
          //       active: false,
          //     },
          //   ],
          // },
        ],
      },
      {
        title: 'Community Analytics',
        icon: 'normal_community_analytics_icon.svg',
        activeIcon: 'selected_community_analytics_icon.svg',
        link: undefined,
        active: false,
        hide: !this.projectService.isLFStaffUser,
        subItems: [
          {
            title: 'People',
            link: `/${this.selectedProject?.slug}/community/project/people`,
            active: false,
          },
          // {
          //   title: 'Committees',
          //   link: `/${this.selectedProject?.slug}/community/project/committee`,
          //   active: false,
          // },
          // {
          //   title: 'Organizations',
          //   link: `/${this.selectedProject?.slug}/community/project/organizations`,
          //   active: false,
          // },
        ],
      },
    ];
    this.handleLinksForProjectConnectors();

    if (this.projectMenu && this.projectMenu[0]) {
      const displayOverview = this.displayOverview(projectDetails);

      if (displayOverview) {
        const element = {
          title: 'Overview',
          link: `/${this.selectedProject?.slug}/overview`,
          active: true,
        };

        this.projectMenu[0].subItems?.unshift(element);

        if (this.projectMenu[0].subItems && this.projectMenu[0].subItems[1]) {
          this.projectMenu[0].subItems[1].active = false;
        }

        // this.router.navigate([`/${this.selectedProject?.slug}/overview`], {
        //   queryParamsHandling: 'merge',
        // });
      }
    }
  }

  openProject(project: Project) {
    if (project) {
      this.projectLoading = false;

      if (this.displayOverview(project)) {
        this.router.navigate([project.slug, 'overview'], {
          queryParamsHandling: 'merge',
        });
      } else {
        this.router.navigate([project.slug, 'trends'], {
          queryParamsHandling: 'merge',
        });
      }
    }
  }

  selectEvent(event: Event) {
    if (event) {
      this.eventLoading = false;
      this.setEventMenu(event.name);
      this.eventService.setSelectedEvent(event.name);
      this.router.navigate(
        ['/global/community/events/people/attendees', event.name],
        { queryParamsHandling: 'merge' }
      );
    }
  }

  selectedTraining(e: Event) {
    // ## TODO
    // if (e) {
    //   this.router.navigate(
    //     [`/global/community/training-and-certification/org/${e.name}`, e.name],
    //     { queryParamsHandling: 'merge' }
    //   );
    // }
  }

  clearEvent() {
    this.eventService.setSelectedEvent('');
    this.router.navigate(['/global/community/events/people/attendees'], {
      queryParamsHandling: 'merge',
    });
  }

  selectCourse(course: any) {
    if (course) {
      // TODO: when select Course
    }
  }

  activateNavItem(navItem: SideMenu | MenuSubItem) {
    if (navItem.subItems?.length) {
      navItem.active = !navItem.active;

      return;
    }
    this.resetActiveNavItem(
      [
        ...this.globalMenu,
        ...this.allProjectsMenu,
        ...this.projectMenu,
        ...this.eventMenu,
        ...this.trainingMenu,
      ],
      navItem
    );
    navItem.active = true;
  }

  activateSubNavItem(subnavItem: MenuSubItem) {
    this.resetActiveNavItem(
      [
        ...this.globalMenu,
        ...this.allProjectsMenu,
        ...this.projectMenu,
        ...this.eventMenu,
        ...this.trainingMenu,
      ],
      subnavItem
    );
    subnavItem.active = true;
  }

  resetActiveNavItem(menu: SideMenu[], item: MenuSubItem) {
    menu.forEach(_navItem => {
      if (_navItem.subItems) {
        this.resetSubItems(_navItem, item);
      }

      if (!this.isParentNode(item, _navItem)) {
        _navItem.active = false;
      } else {
        _navItem.active = true;
      }
    });
  }

  collapseSideNav() {
    this.isSideNavCollapsed = !this.isSideNavCollapsed;

    const collapseItems: any = document.getElementsByClassName('collapse-this');

    for (let i = 0; i < collapseItems.length; i++) {
      if (collapseItems[i].style.display !== 'none') {
        collapseItems[i].style.display = 'none';
      } else {
        collapseItems[i].style.display = 'block';
      }
      this.projectService.isSideNavCollapsed.next(this.isSideNavCollapsed);
    }

    setTimeout(() => {
      this.emitWindowResize();
    }, 50);
  }

  private emitWindowResize() {
    const ev = new CustomEvent('resize');

    window.dispatchEvent(ev);
  }

  private resetSubItems(navItem: SideMenu | MenuSubItem, item: MenuSubItem) {
    if (navItem.subItems) {
      navItem.subItems.forEach(subNav => {
        if (!this.isParentNode(item, subNav)) {
          subNav.active = false;
        } else {
          subNav.active = true;
        }

        if (subNav.subItems?.length) {
          this.resetSubItems(subNav, item);
        }
      });
    }
  }

  private isParentNode(
    item: MenuSubItem,
    node: SideMenu | MenuSubItem
  ): boolean {
    if (item === node) {
      return true;
    }

    if (!node.subItems?.length) {
      return false;
    }
    let flag = false;

    for (let index = 0; index < node.subItems.length; index++) {
      const subItem = node.subItems[index];

      if (this.isParentNode(item, subItem)) {
        flag = true;
        break;
      }
    }

    return flag;
  }

  private getNavItemByUrl(
    url: string,
    nodes: SideMenu[] | MenuSubItem[]
  ): SideMenu | MenuSubItem | undefined {
    let result;

    for (let index = 0; index < nodes.length; index++) {
      const node = nodes[index];

      if (url === node.link) {
        return node;
      }

      if (node.subItems?.length) {
        result = this.getNavItemByUrl(url, node.subItems);

        if (result) {
          return result;
        }
      }
    }

    return undefined;
  }

  private handleRouterChange(url: string) {
    if (url.includes('organization-oss-index')) {
      url = url.replace(
        'organization-oss-index',
        'organization-contribution-index'
      );
    }
    const urlWithoutQueryParams = url.split('?')[0];
    const node = this.getNavItemByUrl(urlWithoutQueryParams, [
      ...this.globalMenu,
      ...this.allProjectsMenu,
      ...this.projectMenu,
      ...this.eventMenu,
      ...this.trainingMenu,
    ]);

    if (node && 'icon' in node) {
      // check if is instance of SideMenu
      this.activateNavItem(node);
    } else if (node) {
      this.activateSubNavItem(node);
    }
  }

  private handleLinksForProjectConnectors() {
    // Hide PR and Issue link under code velocity when project has git only connector
    this.projectService.projectConnectors
      .pipe(
        // projectConnectors Obs is always triggering, we need to avoid duplicated calls and leaks
        filter(pc => !!pc?.length),
        distinctUntilChanged((prev, curr) => {
          return JSON.stringify(prev) === JSON.stringify(curr);
        }),
        first()
      )
      .subscribe(pc => {
        if (!pc?.length) {
          return;
        }

        const hasGitConnectorOnly = pc?.length === 1 && pc?.includes('git');
        const hasConfluence = pc?.length > 0 && pc?.includes('confluence');

        const parentLink = this.projectMenu.find(
          m => m.title === 'Project Analytics'
        );
        const codeVelocityNavLink = parentLink?.subItems?.find(
          m => m.title === 'Code Velocity'
        );
        const codeBaseNavLink = parentLink?.subItems?.find(
          m => m.title === 'Code Base'
        );

        const documentationNavLink = parentLink?.subItems?.find(
          m => m.title === 'Documentation'
        );

        if (hasGitConnectorOnly) {
          if (codeVelocityNavLink) {
            const linksToRemove = [
              'Pull Request/Changeset Pipeline',
              'Issue Request Pipeline',
            ];

            codeVelocityNavLink.subItems?.forEach((e, i) => {
              if (hasGitConnectorOnly && linksToRemove.includes(e.title)) {
                e.hide = true;
              }
            });
          }

          if (codeBaseNavLink) {
            const linksToRemove = ['Repositories that Need Attention'];

            codeBaseNavLink.subItems?.forEach((e, i) => {
              if (hasGitConnectorOnly && linksToRemove.includes(e.title)) {
                e.hide = true;
              }
            });
          }
        }

        if (!hasConfluence && documentationNavLink) {
          documentationNavLink.hide = !hasConfluence;
        }
      });
  }

  private displayOverview(project: Project): any {
    const projectsToView = ['cncf', 'lf-ai-foundation'];

    if (project.projects?.length) {
      return false;
    }

    if (project?.parentSlug) {
      if (projectsToView.includes(project.parentSlug)) {
        return true;
      }
    }

    if (project?.rootSlug) {
      if (projectsToView.includes(project.rootSlug)) {
        return true;
      }
    }

    if (projectsToView.includes(project.slug)) {
      return true;
    }
    // if (projectsToView.includes(parentHierarchy.slug)) {
    //   return true;
    // } else if (parentHierarchy.parentHierarchy) {
    //   const data = this.displayOverview(parentHierarchy.parentHierarchy);

    //   if (data) {
    //     return data;
    //   }
    // }

    return undefined;
  }
}
