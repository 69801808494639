import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  YAxisData,
  AxesChartConfig,
  ValueType,
  MarkPoint,
  PieChart,
  PieChartConfig,
  GaugeChart,
  GaugeChartConfig,
  FunnelChart,
  FunnelChartConfig,
  WorldMapChart,
  WorldMapChartConfig,
  PieChartName,
} from '../models';
import { ShortNumberPipe } from '../pipes';
import { ChartsConfigurationService } from './e-chart-config.service';

@Injectable({
  providedIn: 'root',
})
export class AnnotationService {
  constructor(
    private shortNumberPipe: ShortNumberPipe,
    private eChartService: ChartsConfigurationService
  ) {}

  createAnnotation(
    item: YAxisData | PieChart | GaugeChart | FunnelChart | WorldMapChart,
    config:
      | AxesChartConfig
      | PieChartConfig
      | GaugeChartConfig
      | FunnelChartConfig
      | WorldMapChartConfig,
    index: number
  ) {
    if (
      item &&
      item.annotation &&
      config.markPointConfig &&
      config.markPointConfig[index]
    ) {
      const title = config.markPointConfig[index].name;
      const valueKey =
        config.markPointConfig[index].value === ValueType.AVG
          ? 'averageValue'
          : 'totalValue';
      const value = this.shortNumberPipe.transform(
        Math.ceil(item.annotation[valueKey] || 0)
      );
      const changeCondition = !item.annotation.changeFromPreviousPeriod; // true ==> when value is zero
      // item.annotation.changeFromPreviousPeriod === undefined ||
      // item.annotation.changeFromPreviousPeriod === null;
      const percentage = changeCondition
        ? ''
        : Math.ceil(Math.abs(item.annotation.changeFromPreviousPeriod)) + '%';
      const endText = config.markPointConfig[index].endingText;
      const direction = changeCondition
        ? undefined
        : item.annotation.changeFromPreviousPeriod > 0
        ? true
        : false;

      return {
        title: title,
        values: [value, percentage, endText],
        direction: config.markPointConfig[index].isDriftAway
          ? direction === undefined
            ? undefined
            : !direction
          : direction,
        position: config.markPointConfig[index].position,
      };
    } else {
      return null;
    }
  }

  createAnnotationEAxesChart(
    item: YAxisData,
    config: AxesChartConfig,
    index: number
  ) {
    const annotation = this.createAnnotation(item, config, index);

    if (!annotation || !annotation.values[0]) {
      return undefined;
    }

    return this.eChartService.markPoint(
      annotation.title,
      annotation.values,
      annotation.direction,
      annotation.position
    );
  }

  createAnnotationESingleChart(
    item: PieChart | GaugeChart,
    config: PieChartConfig | GaugeChartConfig,
    index: number,
    type?: string
  ) {
    const annotation = this.createAnnotation(item, config, index);

    if (!annotation || !annotation.values[0]) {
      return config.chart?.markPoint;
    }

    if (
      type === 'pie' &&
      config.data?.source === 'api' &&
      config.data.realData ===
        PieChartName.CommitAnalysisDocumentationCommitsChart
    ) {
      const percentage = +(
        ((item as PieChart).dataPoints.filter(
          i => i.name === 'Commits towards documents'
        )[0].value /
          item.annotation.totalValue) *
        100
      ).toFixed(4);

      return this.eChartService.markPointForDocumentCommits(
        annotation.title,
        annotation.values,
        this.shortNumberPipe.transform(percentage),
        annotation.position
      );
    }

    if (type === 'pie') {
      return this.eChartService.markPointWithoutBG(
        annotation.title,
        annotation.values,
        annotation.direction,
        annotation.position
      );
    }

    return this.eChartService.markPoint(
      annotation.title,
      annotation.values,
      annotation.direction,
      annotation.position
    );
  }

  createAnnotationTextOnlyMarkPointWithoutBG(config: PieChartConfig) {
    if (config.markPointConfig && config.markPointConfig[0]) {
      return this.eChartService.textOnlyMarkPointWithoutBG(
        config?.markPointConfig[0]?.name,
        config?.markPointConfig[0]?.position
      );
    }

    return null;
  }

  createAnnotationHighChart(
    item: YAxisData,
    config: AxesChartConfig,
    index: number
  ) {
    const annotation = this.createAnnotation(item, config, index);

    if (!annotation || !annotation.values[0]) {
      return item.markPoint;
    }

    const markpoint: MarkPoint = {
      title: annotation.title,
      value: annotation.values[0],
      isIncrease: annotation.direction,
      percentage: annotation.values[1],
      additionalText: annotation.values[2],
      enable: true,
      position: annotation.position
        ? { left: annotation.position?.x, bottom: annotation.position?.y }
        : undefined,
    };

    return markpoint;
  }

  createAnnotationSingleHighChart(
    item: FunnelChart | WorldMapChart,
    config: FunnelChartConfig | WorldMapChartConfig,
    index: number
  ) {
    const annotation = this.createAnnotation(item, config, index);

    if (!annotation || !annotation.values[0]) {
      return config.chart?.markPoint;
    }

    const markpoint: MarkPoint = {
      title: annotation.title,
      value: annotation.values[0],
      isIncrease: annotation.direction,
      percentage: annotation.values[1],
      additionalText: annotation.values[2],
      enable: true,
      position: annotation.position
        ? { left: annotation.position?.x, bottom: annotation.position?.y }
        : undefined,
    };

    return markpoint;
  }
}
