<lfx-header-v2
  id="lfx-header"
  userefreshtoken="true"
  use-request-id="true"
></lfx-header-v2>
<div id="main-content">
  <div id="app">
    <div class="min-h-[43px]">
      <ng-container *ngTemplateOutlet="cutoutTemplate"></ng-container>
    </div>
    <div class="app-body">
      <lfx-side-nav></lfx-side-nav>
      <div id="router-container">
        <router-outlet></router-outlet>
        <div
          class="mt-auto items-start flex justify-center w-full"
          [ngClass]="{ 'w-[calc(100%-415px)]': isTrendsPage }"
        >
          <lfx-page-footer class="h-30px mt-15px mx-auto"></lfx-page-footer>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #cutoutTemplate>
  <div class="cutout-container">
    <i class="fas fa-info-circle"></i>
    <b> We have a new version of Insights with better UI and more metrics. </b>
    <a
      class="contact-us-link"
      href="https://insights.v3.lfx.linuxfoundation.org"
      target="_blank"
      >Check out here</a
    >
  </div>
</ng-template>
