import { DateRangeFiltersNames } from '@config';
import moment from 'moment';

export class DateTimeFilter {
  selectedDateFilterType!: selectedDateFilterType;
  startDate!: Date;
  endDate!: Date;
  selectedDateRangeName?: { name: string; key: string };
  selectedYear?: number;
  compareTo?: string;
  backendDateFilter: BackendDateFilter;
  constructor() {
    this.backendDateFilter = new BackendDateFilter();
    this.selectedDateFilterType = selectedDateFilterType.dateRange;
    this.selectedDateRangeName = DateRangeFiltersNames[2];
    this.startDate = moment().subtract(1, 'year').startOf('day').toDate();
    this.endDate = moment().endOf('day').toDate();
    this.selectedYear = new Date().getFullYear() - 1;
    this.compareTo = '';
  }
}

export enum selectedDateFilterType {
  'dateRange' = 'DATERANGE',
  'customDate' = 'CUSTOMDATES',
  'fullYear' = 'FULLYEAR',
}

class BackendDateFilter {
  timeFilter!: string;
  fromDate?: string;
  toDate?: string;
  reportDate?: string;
  constructor() {
    this.timeFilter = DateRangeFiltersNames[2].key;
  }
}
