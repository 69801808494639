import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@environment';
import { Observable } from 'rxjs';

import { TableInput, TableServiceGql } from '../gql';
import { Table } from '../models';
import { ProjectService } from './project.service';

@Injectable({
  providedIn: 'root',
})
export class TablesDataService {
  isProdEnvironment = environment.datadogEnv === 'production';

  constructor(
    private tableServiceGql: TableServiceGql,
    private projectService: ProjectService,
    private router: Router
  ) {}

  getTable(tableInput: TableInput): Observable<Table> {
    const isGlobalPage =
      this.router.url === '/' ||
      this.router.url.indexOf('/?') >= 0 ||
      this.router.url.indexOf('/global/') >= 0
        ? true
        : false;
    const applyRepositoriesFilter = this.checkPath();

    if (!applyRepositoriesFilter) {
      tableInput.tableQueryParams.repositoryIds = [];
      tableInput.tableQueryParams.repositoryTags = [];
    }

    if (isGlobalPage) {
      tableInput.tableQueryParams.projectId = undefined;

      return this.tableServiceGql.getTable(tableInput);
    }

    return this.projectService.isProjectOnBoardedAndConfigured(
      this.tableServiceGql.getTable(tableInput)
    );
  }

  private checkPath() {
    const isPath =
      window?.location?.pathname?.endsWith('/code-base/repositories') ||
      window?.location?.pathname?.includes('/code-velocity/');

    return isPath || false;
  }
}
