// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { gql } from 'apollo-angular';

export const CheckUserRoleQuery = gql`
  query CheckUserRoles($checkUserRoleInput: String!) {
    checkUserRoles(checkUserRoleInput: $checkUserRoleInput) {
      hasPermission
    }
  }
`;
