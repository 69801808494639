import * as Highcharts from 'highcharts';

import { WordChartApiDataSource } from './api-data-source';
import { MarkPoint } from './mark-point';
import { WordChartMockDataSource } from './mock-data-source';

export interface WordChartConfig {
  chart: {
    options?: Highcharts.Options;
    seriesName: string;
    markPoint?: MarkPoint;
  };
  data?: WordChartMockDataSource | WordChartApiDataSource;
}
