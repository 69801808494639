import { AxesChartName } from './axes-chart-name';
import { BubbleChartName } from './bubble-chart-name';
import { FunnelChartName } from './funnel-chart-name';
import { GaugeChartName } from './gauge-chart-name';
import { PieChartName } from './pie-chart-name';
import { RadarChartName } from './radar-chart-name';
import { TableName } from './table-name';
import { WordChartName } from './word-chart-name';
import { WorldMapChartName } from './world-map-chart-name';

export interface ApiDataSource {
  source: 'api';
}

export interface TableApiDataSource extends ApiDataSource {
  realData?: TableName;
}

export interface AxesChartApiDataSource extends ApiDataSource {
  realData?: AxesChartName;
}

export interface BubbleChartApiDataSource extends ApiDataSource {
  realData?: BubbleChartName;
}
export interface WorldMapChartApiDataSource extends ApiDataSource {
  realData?: WorldMapChartName;
}

export interface WordChartApiDataSource extends ApiDataSource {
  realData?: WordChartName;
}

export interface FunnelChartApiDataSource extends ApiDataSource {
  realData?: FunnelChartName;
}

export interface PieChartApiDataSource extends ApiDataSource {
  realData?: PieChartName;
}
export interface RadarChartApiDataSource extends ApiDataSource {
  realData?: RadarChartName;
}

export interface GaugeChartApiDataSource extends ApiDataSource {
  realData?: GaugeChartName;
}
