import * as Highcharts from 'highcharts';

import { WorldMapChartApiDataSource } from './api-data-source';
import { MarkPoint, MarkPointConfig } from './mark-point';
import { WorldMapChartMockDataSource } from './mock-data-source';

export interface WorldMapChartConfig {
  chart: {
    options?: Highcharts.Options;
    seriesName: string;
    markPoint?: MarkPoint;
  };
  data?: WorldMapChartMockDataSource | WorldMapChartApiDataSource;
  markPointConfig?: MarkPointConfig[];
}
