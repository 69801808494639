// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { gql } from 'apollo-angular';

export const axesChartQuery = gql`
  query AxesChart($axesChartInput: AxesChartInput!) {
    axesChart(axesChartInput: $axesChartInput) {
      xAxis
      observations
      yAxis {
        name
        data
        annotation {
          changeFromPreviousPeriod
          totalValue
          averageValue
          totalPrograms
        }
      }
    }
  }
`;
