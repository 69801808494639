import { Injectable } from '@angular/core';
import * as echarts from 'echarts';
import { YAxisData } from '../models';
import { ShortNumberPipe } from '../pipes';

@Injectable({
  providedIn: 'root',
})
export class ChartsConfigurationService {
  commonXAxis = {
    type: 'category',
    data: ['1', '2'],
    axisLine: {
      lineStyle: {
        color: '#e0e0e0',
      },
    },
    axisLabel: {
      color: '#333333',
    },
    axisTick: {
      alignWithLabel: true,
    },
  };

  commonYAxis = {
    type: 'value',
    show: true,
    name: '',
    nameLocation: 'middle',
    nameGap: 40,
    nameTextStyle: {
      color: '#333333',
      fontWeight: 600,
      fontFamily: 'Open Sans',
      // padding: 26,
      fontSize: 12,
    },
    axisLine: {
      show: true,
      lineStyle: {
        color: '#e0e0e0',
      },
    },
    axisLabel: {
      color: '#333333',
      formatter: (value: any) => {},
    },
  };

  constructor(private shortNumberPipe: ShortNumberPipe) {}

  setXAxis(xAxisData: string[]) {
    const commonXAxis = this.commonXAxis;

    commonXAxis.data = xAxisData;

    return commonXAxis;
  }

  setYAxis(yAxisName: string) {
    const commonYAxis = this.commonYAxis;

    commonYAxis.name = yAxisName;

    if (!yAxisName.length) {
      commonYAxis.nameGap = 0;
    }

    commonYAxis.axisLabel.formatter = (value: any) => {
      return this.shortNumberPipe.transform(value);
    };

    return commonYAxis;
  }

  tooltipFormatter(isTodayInFilterRange: boolean, data?: YAxisData[]) {
    return {
      trigger: 'item',
      extraCssText: 'z-index: 10;',
      formatter: (series: any) => {
        const xAxisLabel = series.name;
        const yAxisDataLabel = this.shortNumberPipe.transform(series.data);
        const seriesName = series.seriesName;
        const selectedDataSeries = data![series.seriesIndex];

        return `${xAxisLabel}<br/>${seriesName}  &emsp;${yAxisDataLabel} ${
          series.dataIndex === selectedDataSeries.data.length - 1 &&
          isTodayInFilterRange
            ? '<br/> Data is partial'
            : ''
        }`;
      },
      backgroundColor: '#000',
      textStyle: {
        color: '#fff',
        fontWeight: 600,
        fontFamily: 'Open Sans',
        fontSize: 12,
      },
    };
  }

  markPoint(
    title: string,
    values: string[],
    direction?: boolean,
    coordinates?: { x: string; y: string }
  ) {
    const _coordinates = coordinates || { x: '60%', y: '10%' };

    return {
      symbolSize: 1,
      silent: true,
      emphasis: {
        disabled: true,
      },
      label: {
        formatter: `{a|${title}\n}  {b|${values[0] ? values[0] : ''}} ${
          direction !== undefined ? '{c|}' : ''
        }{d|${values[1] ? values[1] : ''}} {e|${
          values[1] && values[2] ? values[2] : ''
        }}`,
        backgroundColor: 'rgba(255,255,255,0.70)',
        shadowColor: 'rgba(0,0,0,0.15)',
        shadowOffsetY: 1,
        shadowOffsetX: 1,
        shadowBlur: 5,
        color: '#333333',
        borderWidth: 1,
        borderRadius: 2,
        padding: [4, 10],
        lineHeight: 26,
        rich: {
          a: {
            align: 'center',
            color: '#333333',
            fontSize: 11,
            fontWeight: 400,
          },
          b: {
            color: '#333333',
            fontSize: 14,
            fontWeight: 400,
            align: 'center',
          },
          c: {
            backgroundColor: {
              image: direction
                ? '/assets/icons/arrow-up.svg'
                : '/assets/icons/arrow-down.svg',
            },
          },
          d: {
            color:
              direction !== undefined
                ? direction
                  ? '#4C9C2E'
                  : '#FF4438'
                : '#539f36',
            fontSize: 11,
            fontWeight: 400,
          },
          e: {
            color: '#807F83',
            fontSize: 11,
          },
        },
      },
      data: [
        {
          name: '',
          y: _coordinates.y,
          x: _coordinates.x,
        },
      ],
    };
  }

  textOnlyMarkPointWithoutBG(
    title: string,
    coordinates?: { x: string; y: string }
  ) {
    const _coordinates = coordinates || { x: '50%', y: '50%' };

    return {
      symbolSize: 1,
      silent: true,
      emphasis: {
        disabled: true,
      },
      label: {
        formatter: `{a|${title}\n} `,
        padding: [4, 10],
        lineHeight: 26,
        rich: {
          a: {
            align: 'center',
            color: '#333333',
            fontSize: 14,
            fontWeight: 600,
          },
        },
      },
      data: [
        {
          name: '',
          y: _coordinates.y,
          x: _coordinates.x,
        },
      ],
    };
  }

  // this is used for the doughnut chart to display data inside
  markPointWithoutBG(
    title: string,
    values: string[],
    direction?: boolean,
    coordinates?: { x: string; y: string }
  ) {
    const _coordinates = coordinates || { x: '60%', y: '10%' };

    return {
      symbolSize: 1,
      silent: true,
      emphasis: {
        disabled: true,
      },
      label: {
        formatter: `{a|${title}\n}  {b|${values[0] ? values[0] : ''}} ${
          direction !== undefined ? '{c|}' : ''
        }{d|${values[1] ? values[1] : ''}} {e|${
          values[1] && values[2] ? values[2] : ''
        }}`,
        padding: [4, 10],
        lineHeight: 26,
        rich: {
          a: {
            align: 'center',
            color: '#333333',
            fontSize: 11,
            fontWeight: 400,
          },
          b: {
            color: '#333333',
            fontSize: 14,
            fontWeight: 400,
            align: 'center',
          },
          c: {
            backgroundColor: {
              image: direction
                ? '/assets/icons/arrow-up.svg'
                : '/assets/icons/arrow-down.svg',
            },
          },
          d: {
            color:
              direction !== undefined
                ? direction
                  ? '#4C9C2E'
                  : '#FF4438'
                : '#539f36',
            fontSize: 11,
            fontWeight: 400,
          },
          e: {
            color: '#807F83',
            fontSize: 11,
          },
        },
      },
      data: [
        {
          name: '',
          y: _coordinates.y,
          x: _coordinates.x,
        },
      ],
    };
  }

  markPointForDocumentCommits(
    title: string,
    values: string[],
    percentage?: number,
    coordinates?: { x: string; y: string }
  ) {
    const _coordinates = coordinates || { x: '60%', y: '10%' };

    return {
      symbolSize: 1,
      silent: true,
      emphasis: {
        disabled: true,
      },
      label: {
        formatter: `{a|${percentage}%\n}  {b|${
          values[0] ? values[0] : ''
        }} {b|${title}}`,
        padding: [4, 10],
        lineHeight: 26,
        rich: {
          a: {
            align: 'center',
            color: '#333333',
            fontSize: 28,
            fontWeight: 400,
          },
          b: {
            color: '#333333',
            fontSize: 11,
            fontWeight: 400,
            align: 'center',
          },
        },
      },
      data: [
        {
          name: '',
          y: _coordinates.y,
          x: _coordinates.x,
        },
      ],
    };
  }

  simpleMarkPoint(
    title: string,
    value: string,
    coordinates?: { x: string; y: string },
    showBG: boolean = true
  ) {
    const _coordinates = coordinates || { x: '60%', y: '10%' };

    return {
      symbolSize: 1,
      silent: true,
      emphasis: {
        disabled: true,
      },
      label: {
        formatter: `{b|${value}\n} {a|${title}}`,
        backgroundColor: 'rgba(255,255,255,0.70)',
        shadowColor: showBG ? 'rgba(0,0,0,0.15)' : 'inherit',
        shadowOffsetY: showBG ? 1 : 0,
        shadowOffsetX: showBG ? 1 : 0,
        shadowBlur: showBG ? 5 : 0,
        color: '#333333',
        padding: [4, 10],
        lineHeight: 26,
        rich: {
          a: {
            align: 'center',
            color: '#333333',
            fontSize: 11,
          },
          b: {
            color: '#333333',
            fontSize: 28,
            align: 'center',
          },
        },
      },
      data: [
        {
          name: '',
          y: _coordinates.y,
          x: _coordinates.x,
        },
      ],
    };
  }

  loadChart(echartObject: any, chartOptions: any, height?: number) {
    if (!echartObject) {
      return;
    }
    let options = chartOptions;

    var myChart = echarts.init(echartObject.nativeElement, undefined, {
      height: height !== undefined ? height : 361.5,
    });

    options && myChart.setOption(options);

    this.legendSelectionChange(myChart);

    return myChart;
  }

  getChartColorSet(dataLength: number): string[] {
    if (dataLength <= 5) {
      return ['#8c4e99', '#4e7ac5', '#54a8aa', '#a7be54', '#e47931'];
    } else if (dataLength <= 7) {
      return [
        '#8c4e99',
        '#4e7ac5',
        '#54a8aa',
        '#8cbc66',
        '#e19e33',
        '#e47931',
        '#e24526',
      ];
    } else if (dataLength <= 10) {
      return [
        '#b58fc2',
        '#a876b1',
        '#4e7ac5',
        '#5196b7',
        '#54a8aa',
        '#8cbc66',
        '#e19e33',
        '#e47931',
        '#e24526',
        '#b7221e',
      ];
    } else if (dataLength <= 20) {
      return [
        '#b58fc2',
        '#a876b1',
        '#6059a7',
        '#5567bb',
        '#4e7ac5',
        '#4a8bcb',
        '#5196b7',
        '#54a8aa',
        '#6bb091',
        '#8cbc66',
        '#a7be54',
        '#cfb73d',
        '#ddab38',
        '#e19e33',
        '#e47931',
        '#e26330',
        '#e24526',
        '#db2518',
        '#b7221e',
        '#94211a',
      ];
    }

    return [
      '#e9ecfb',
      '#ddd7f1',
      '#d1c0e2',
      '#c4a8d0',
      '#b58fc2',
      '#a876b1',
      '#9a63a6',
      '#8c4e99',
      '#6e4b9f',
      '#6059a7',
      '#5567bb',
      '#4e7ac5',
      '#4a8bcb',
      '#5196b7',
      '#51a0b1',
      '#54a8aa',
      '#63a99e',
      '#6bb091',
      '#75b97c',
      '#8cbc66',
      '#a7be54',
      '#bebd47',
      '#cfb73d',
      '#ddab38',
      '#e19e33',
      '#e78b34',
      '#e47931',
      '#e26330',
      '#e24526',
      '#db2518',
      '#b7221e',
      '#94211a',
    ];
  }

  private legendSelectionChange(myChart: echarts.ECharts) {
    // The legend only trigger legendselectchanged event.
    myChart.on('legendselectchanged', function (params: any) {
      const selected = Object.values(params.selected).filter(
        val => val === true
      );

      // State if none of the legend is selected then reselect the last legend.
      if (selected.length === 0) {
        myChart.setOption({ animation: false });

        // Re-select what the user unselected.
        myChart.dispatchAction({
          type: 'legendSelect',
          name: params.name,
        });

        myChart.setOption({ animation: true });
      }
    });
  }
}
