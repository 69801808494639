import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { TabCardsServiceGql, TabCardsInput } from '../gql';
import { TabCards } from '../models';
import { ProjectService } from './project.service';

@Injectable({
  providedIn: 'root',
})
export class TabCardsDataService {
  constructor(
    private tabCardsServiceGql: TabCardsServiceGql,
    private router: Router,
    private projectService: ProjectService
  ) {}

  getTabCards(tabCardsInput: TabCardsInput): Observable<TabCards[]> {
    const isGlobalPage =
      this.router.url === '/' ||
      this.router.url.indexOf('/?') >= 0 ||
      this.router.url.indexOf('/global/') >= 0
        ? true
        : false;
    const applyRepositoriesFilter = this.checkPath();

    if (!applyRepositoriesFilter) {
      tabCardsInput.chartsQueryParams.repositoryIds = [];
      tabCardsInput.chartsQueryParams.repositoryTags = [];
    }

    if (isGlobalPage) {
      return this.tabCardsServiceGql.getTabCards(tabCardsInput);
    }

    return this.projectService.isProjectOnBoardedAndConfigured(
      this.tabCardsServiceGql.getTabCards(tabCardsInput)
    );
  }

  private checkPath() {
    const isPath =
      window?.location?.pathname?.endsWith('/code-base/repositories') ||
      window?.location?.pathname?.includes('/code-velocity/');

    return isPath || false;
  }
}
