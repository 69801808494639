// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { gql } from 'apollo-angular';

export const gaugeChartQuery = gql`
  query GaugeChart($gaugeChartInput: GaugeChartInput!) {
    gaugeChart(gaugeChartInput: $gaugeChartInput) {
      value
      observations
    }
  }
`;
