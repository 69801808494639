import { Injectable } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { DateRangeFiltersNames } from '@lfx/config';
import { DateTimeFilter, selectedDateFilterType } from '@models';
import moment from 'moment';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DateFilterService {
  dateFilter$!: Observable<DateTimeFilter>;
  queryParamListener = true;
  private dateFilterSubject = new BehaviorSubject<DateTimeFilter>(
    new DateTimeFilter()
  );

  private dateFilterLastValue = new DateTimeFilter();

  constructor(private router: Router, private route: ActivatedRoute) {
    this.dateFilter$ = this.dateFilterSubject.asObservable();
    route.queryParams.subscribe(p => {
      if (this.queryParamListener && p.selectedDateFilterType) {
        const dateFilter = new DateTimeFilter();

        dateFilter.selectedDateFilterType = p.selectedDateFilterType;

        if (p.selectedDateFilterType === selectedDateFilterType.dateRange) {
          const selectedName = DateRangeFiltersNames.find(range => {
            return range.key === p.selectedDateRangeKey;
          });

          if (selectedName) {
            dateFilter.selectedDateRangeName = selectedName;
          }
        } else {
          dateFilter.selectedDateFilterType = selectedDateFilterType.dateRange;
          dateFilter.selectedDateRangeName = DateRangeFiltersNames[2];
        }
        // else if (
        //   p.selectedDateFilterType === selectedDateFilterType.customDate
        // ) {
        //   dateFilter.startDate = new Date(p.startDate);
        //   dateFilter.endDate = new Date(p.endDate);
        // } else if (
        //   p.selectedDateFilterType === selectedDateFilterType.fullYear
        // ) {
        //   dateFilter.selectedYear = p.selectedYear;
        //   const year = moment(`01/01/${p.selectedYear}`, 'DD/MM/YYYY');

        //   dateFilter.startDate = year.startOf('year').toDate();
        //   dateFilter.endDate = year.endOf('year').toDate();
        // }

        this.applyFilter(dateFilter);
      }
    });
  }

  changeQuery(dateFilter: DateTimeFilter) {
    const queryParams: Params = {
      selectedDateFilterType: dateFilter.selectedDateFilterType,
    };

    if (
      dateFilter.selectedDateFilterType === selectedDateFilterType.dateRange
    ) {
      queryParams.selectedDateRangeKey = dateFilter.selectedDateRangeName?.key;
    } else if (
      dateFilter.selectedDateFilterType === selectedDateFilterType.customDate
    ) {
      queryParams.startDate = dateFilter.startDate;
      queryParams.endDate = dateFilter.endDate;
    } else if (
      dateFilter.selectedDateFilterType === selectedDateFilterType.fullYear
    ) {
      queryParams.selectedYear = dateFilter.selectedYear;
    }
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: queryParams,
    });
  }

  applyDateFilter(dateFilter: DateTimeFilter) {
    this.changeQuery(dateFilter);
    this.queryParamListener = false;
    this.applyFilter(dateFilter);
  }

  applyFilter(dateFilter: DateTimeFilter) {
    this.buildBackendDateFilter(dateFilter);
    this.dateFilterLastValue = dateFilter;
    this.dateFilterSubject.next(dateFilter);
  }

  getLastValue() {
    return this.dateFilterLastValue;
  }

  isTodayInDateRange(): boolean {
    const dateFilter = this.getLastValue();

    if (
      dateFilter.selectedDateFilterType === selectedDateFilterType.dateRange
    ) {
      return (
        dateFilter.selectedDateRangeName?.key !== DateRangeFiltersNames[2].key
      );
    }

    return (
      moment(dateFilter.startDate).startOf('day').toDate().getTime() <=
        new Date().getTime() &&
      moment(dateFilter.endDate).endOf('day').toDate().getTime() >=
        new Date().getTime()
    );
  }

  private buildBackendDateFilter(dateFilter: DateTimeFilter) {
    dateFilter.backendDateFilter.reportDate = undefined;

    switch (dateFilter.selectedDateFilterType) {
      case selectedDateFilterType.dateRange:
        dateFilter.backendDateFilter.timeFilter =
          dateFilter.selectedDateRangeName!.key;
        dateFilter.backendDateFilter.fromDate = undefined;
        // this.convertDateToGMTInEpochSeconds(dateFilter.startDate);
        dateFilter.backendDateFilter.toDate = undefined;
        // this.convertDateToGMTInEpochSeconds(dateFilter.endDate);
        break;
      case selectedDateFilterType.customDate:
        dateFilter.backendDateFilter.timeFilter =
          selectedDateFilterType.customDate as any;
        dateFilter.backendDateFilter.fromDate =
          this.convertDateToGMTInEpochSeconds(dateFilter.startDate);
        dateFilter.backendDateFilter.toDate =
          this.convertDateToGMTInEpochSeconds(dateFilter.endDate);
        break;
      case selectedDateFilterType.fullYear:
        dateFilter.backendDateFilter.timeFilter =
          selectedDateFilterType.fullYear as any;
        dateFilter.backendDateFilter.fromDate =
          this.convertDateToGMTInEpochSeconds(dateFilter.startDate);
        dateFilter.backendDateFilter.toDate =
          this.convertDateToGMTInEpochSeconds(dateFilter.endDate);
        dateFilter.backendDateFilter.reportDate =
          this.convertDateToGMTInEpochSeconds(
            new Date(`12/31/${dateFilter.endDate.getFullYear()} 23:59:59`)
          );
        break;
    }
  }

  private convertDateToGMTInEpochSeconds(date: Date) {
    const GMTDate = new Date(
      Date.UTC(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours(),
        date.getMinutes(),
        date.getSeconds()
      )
    );

    return Math.floor(GMTDate.getTime() / 1000).toString();
  }
}
