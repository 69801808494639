import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { CourseServiceGql } from '../gql';
import { Course } from '../models';

@Injectable({
  providedIn: 'root',
})
export class CourseService {
  constructor(private courseServiceGql: CourseServiceGql) {}

  searchCourses(name: string): Observable<Course[]> {
    return this.courseServiceGql.searchCourses(name);
  }
}
