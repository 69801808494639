import { Injectable } from '@angular/core';
import { GaugeChart, GaugeChartName, ChartsQueryParams } from '@models';
import { Apollo } from 'apollo-angular';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { gaugeChartQuery } from '../queries';

interface GaugeChartResult {
  gaugeChart: GaugeChart;
}

export interface GaugeChartInput {
  gaugeChartName: GaugeChartName;
  chartsQueryParams: ChartsQueryParams;
}

@Injectable({
  providedIn: 'root',
})
export class GaugeChartServiceGql {
  constructor(private apollo: Apollo) {}

  getGaugeChart(gaugeChartInput: GaugeChartInput): Observable<GaugeChart> {
    return this.apollo
      .query<GaugeChartResult>({
        query: gaugeChartQuery,
        fetchPolicy: 'network-only',
        variables: {
          gaugeChartInput: gaugeChartInput,
        },
      })
      .pipe(
        catchError(error => {
          if (
            error &&
            error.graphQLErrors &&
            error.graphQLErrors[0] &&
            error.graphQLErrors[0].extensions &&
            error.graphQLErrors[0].extensions.code === 'UNAUTHENTICATED'
          ) {
            return throwError(() => new Error('UNAUTHENTICATED'));
          }

          console.log(error);

          return of(null);
        }),
        map((res: any) => {
          if (!res) {
            return null;
          }

          return res.data.gaugeChart;
        })
      );
  }
}
