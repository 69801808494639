// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT
export interface SearchEventsQueryParams {
  status?: string[];
  projectName?: string[];
  name?: string[];
  projectId?: string[];
  eventId?: string[];
  year?: number;
  start?: string;
  pageSize?: number;
  offset?: number;
  filter?: string;
}
