// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { gql } from 'apollo-angular';

export const tableQuery = gql`
  query Table($tableInput: TableInput!) {
    table(tableInput: $tableInput) {
      tableData
      metaData
    }
  }
`;
