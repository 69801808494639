import * as Highcharts from 'highcharts';

import { FunnelChartApiDataSource } from './api-data-source';
import { MarkPoint, MarkPointConfig } from './mark-point';
import { FunnelChartMockDataSource } from './mock-data-source';

export interface FunnelChartConfig {
  chart: {
    options?: Highcharts.Options;
    markPoint?: MarkPoint;
  };
  data?: FunnelChartMockDataSource | FunnelChartApiDataSource;
  markPointConfig?: MarkPointConfig[];
}
