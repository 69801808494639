// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { gql } from 'apollo-angular';

export const bubbleChartQuery = gql`
  query BubbleChart($bubbleChartInput: BubbleChartInput!) {
    bubbleChart(bubbleChartInput: $bubbleChartInput) {
      observations
      dataPoints {
        name
        value
      }
    }
  }
`;
