// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { gql } from 'apollo-angular';

export const projectsSummaryQuery = gql`
  query ProjectsSummary(
    $projectsSummaryInput: ProjectsSummaryQueryParamsInput
  ) {
    projectsSummary(projectsSummaryInput: $projectsSummaryInput) {
      projectsSummaryData
      metaData
    }
  }
`;
