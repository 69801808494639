// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { gql } from 'apollo-angular';

export const projectRepositoryHealthQuery = gql`
  query GetProjectRepoHistory($projectId: String) {
    getProjectRepoHistory(projectId: $projectId) {
      aggregations {
        name
        buckets {
          bucketkey
          key
          value
        }
      }
    }
  }
`;
