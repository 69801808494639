// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { gql } from 'apollo-angular';

export const projectConnectorsQuery = gql`
  query ProjectConnectors($salesforceId: String) {
    projectConnectors(salesforceId: $salesforceId) {
      projectConnectorsData
    }
  }
`;
