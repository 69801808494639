import { Component } from '@angular/core';

@Component({
  selector: 'lfx-page-footer',
  templateUrl: './page-footer.component.html',
  styleUrls: ['./page-footer.component.scss'],
})
export class PageFooterComponent {
  links = [
    {
      name: '© Linux Foundation',
      url: '',
      isClickable: false,
      isText: true,
    },
    {
      name: '|',
      url: '',
      isClickable: false,
      isText: false,
    },
    {
      name: 'Terms',
      url: 'https://www.linuxfoundation.org/terms/',
      isClickable: true,
      isText: false,
    },
    {
      name: '|',
      url: '',
      isClickable: false,
      isText: false,
    },
    {
      name: 'Privacy',
      url: 'https://linuxfoundation.org/privacy-policy/',
      isClickable: true,
      isText: false,
    },
    {
      name: '|',
      url: '',
      isClickable: false,
      isText: false,
    },
    {
      name: 'Policies',
      url: 'https://linuxfoundation.org/policies/',
      isClickable: true,
      isText: false,
    },
  ];

  constructor() {}
}
