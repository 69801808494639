import { Injectable } from '@angular/core';
import { TableName } from '@models';

import { BehaviorSubject, Observable } from 'rxjs';
import { PaginationService } from './pagination.service';
import { TableService } from './table.service';

@Injectable({
  providedIn: 'root',
})
export class FilterService {
  filter$!: Observable<{ filter: any; tableName: TableName } | undefined>;
  private filterSubject = new BehaviorSubject<
    { filter: any; tableName: TableName } | undefined
  >(undefined);

  private filterMap: Map<TableName, any> = new Map();

  constructor(
    private tableService: TableService,
    private paginationService: PaginationService
  ) {
    this.filter$ = this.filterSubject.asObservable();
  }

  filterChange(param: { filter: any; tableName: TableName }) {
    this.paginationService.resetPagination(param.tableName);
    this.filterMap.set(param.tableName, param.filter);
    this.tableService.updateActiveTable(param.tableName);
    this.filterSubject.next(param);
  }

  getFilterByTableName(tableName: TableName) {
    return this.filterMap.get(tableName);
  }

  resetFilterByTableName(tableName: TableName) {
    return this.filterMap.delete(tableName);
  }
}
