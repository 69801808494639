// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { gql } from 'apollo-angular';

export const projectHealthRepositoriesQuery = gql`
  query ProjectHealthRepositories($projectId: String) {
    projectHealthRepositories(projectId: $projectId) {
      data {
        lastScan
        repositoryId
        repositoryName
        repositoryUrl
        status
        checks
        metricsEvaluated
        metrics {
          documentation
          license
          bestPractices
          security
          legal
          global
        }
      }
    }
  }
`;
