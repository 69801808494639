import { Injectable } from '@angular/core';
import { TableName } from '../models';

@Injectable({
  providedIn: 'root',
})
export class TableService {
  private activeTableName?: TableName;
  constructor() {}

  updateActiveTable(tableName: TableName) {
    this.activeTableName = tableName;
  }

  getActiveTable() {
    return this.activeTableName;
  }
}
