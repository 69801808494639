import { MarkPoint } from './mark-point';

export interface AxesChart {
  xAxis: string[];
  yAxis: YAxisData[];
  observations?: string[];
}

export interface YAxisData {
  name: string;
  data: number[];
  annotation?: any;
  markPoint?: any | MarkPoint;
}
