// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import {
  projectHealthBestPracticesScoreQuery,
  projectHealthDetailsQuery,
  projectHealthRepositoriesQuery,
  projectHealthRepositoryDetailsQuery,
  projectRepositoryHealthQuery,
} from '../queries';

@Injectable({
  providedIn: 'root',
})
export class ProjectHealthServiceGql {
  constructor(private apollo: Apollo) {}

  getProjectHealthDetails(projectId: string): Observable<any> {
    return this.apollo
      .query<any>({
        query: projectHealthDetailsQuery,
        fetchPolicy: 'network-only',
        variables: {
          projectId,
        },
      })
      .pipe(
        catchError(error => {
          if (
            error &&
            error.graphQLErrors &&
            error.graphQLErrors[0] &&
            error.graphQLErrors[0].extensions &&
            error.graphQLErrors[0].extensions.code === 'UNAUTHENTICATED'
          ) {
            return throwError(() => new Error('UNAUTHENTICATED'));
          }

          return of(null);
        }),
        map((res: any) => {
          if (!res) {
            return null;
          }

          return res.data.projectDetails;
        })
      );
  }

  getProjectHealthBestPracticesScore(projectId: string): Observable<any> {
    return this.apollo
      .query<any>({
        query: projectHealthBestPracticesScoreQuery,
        fetchPolicy: 'network-only',
        variables: {
          projectId,
        },
      })
      .pipe(
        catchError(error => {
          if (
            error &&
            error.graphQLErrors &&
            error.graphQLErrors[0] &&
            error.graphQLErrors[0].extensions &&
            error.graphQLErrors[0].extensions.code === 'UNAUTHENTICATED'
          ) {
            return throwError(() => new Error('UNAUTHENTICATED'));
          }

          return of(null);
        }),
        map((res: any) => {
          if (!res) {
            return null;
          }

          return res.data.getBestPractices?.metrics;
        })
      );
  }

  getProjectHealthRepositories(projectId: string): Observable<any> {
    return this.apollo
      .query<any>({
        query: projectHealthRepositoriesQuery,
        fetchPolicy: 'network-only',
        variables: {
          projectId,
        },
      })
      .pipe(
        catchError(error => {
          if (
            error &&
            error.graphQLErrors &&
            error.graphQLErrors[0] &&
            error.graphQLErrors[0].extensions &&
            error.graphQLErrors[0].extensions.code === 'UNAUTHENTICATED'
          ) {
            return throwError(() => new Error('UNAUTHENTICATED'));
          }

          return of(null);
        }),
        map((res: any) => {
          if (!res) {
            return null;
          }

          return res.data.projectHealthRepositories?.data;
        })
      );
  }

  getProjectHealthRepositoryDetailsByRepositoryId(
    repoId: string
  ): Observable<any> {
    return this.apollo
      .query<any>({
        query: projectHealthRepositoryDetailsQuery,
        fetchPolicy: 'network-only',
        variables: {
          repoId,
        },
      })
      .pipe(
        catchError(error => {
          if (
            error &&
            error.graphQLErrors &&
            error.graphQLErrors[0] &&
            error.graphQLErrors[0].extensions &&
            error.graphQLErrors[0].extensions.code === 'UNAUTHENTICATED'
          ) {
            return throwError(() => new Error('UNAUTHENTICATED'));
          }

          return of(null);
        }),
        map((res: any) => {
          if (!res) {
            return null;
          }

          return res.data.projectHealthRepositoryDetailsByRepositoryId?.data;
        })
      );
  }

  getProjectRepositoryHealth(projectId: string): Observable<any> {
    return this.apollo
      .query<any>({
        query: projectRepositoryHealthQuery,
        fetchPolicy: 'network-only',
        variables: {
          projectId,
        },
      })
      .pipe(
        catchError(error => {
          if (
            error &&
            error.graphQLErrors &&
            error.graphQLErrors[0] &&
            error.graphQLErrors[0].extensions &&
            error.graphQLErrors[0].extensions.code === 'UNAUTHENTICATED'
          ) {
            return throwError(() => new Error('UNAUTHENTICATED'));
          }

          return of(null);
        }),
        map((res: any) => {
          if (!res) {
            return null;
          }

          return res.data.getProjectRepoHistory?.aggregations;
        })
      );
  }
}
