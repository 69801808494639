export interface SubProject {
  name: string;
  link: string;
}

export interface Foundation {
  id: string;
  logoUrl: string;
  name: string;
  slug: string;
}
export interface Project {
  favourite: boolean; // local param
  repositories: number; // local param
  commits: string; // local param
  subProjects?: SubProject[]; // local param

  id: string;
  name: string;
  slug: string;
  description: string;
  projectType: ProjectType;
  type: string;
  enabledServices: string[];
  parent: any;
  projectLogo: string;
  endDate?: string;
  foundation: Foundation;
  projects?: Project[];
  categories?: any;
  isOnBoarded: boolean;
  parentSlug?: string;
  rootSlug?: string;
}

export enum ProjectType {
  Project = 'Project',
  ProjectGroup = 'Project Group',
}

export enum TypeName {
  Foundation = 'Foundation',
  Project = 'Project',
}

export const ProjectStatus = {
  Active: 'Active',
};

export const ProjectEntityType = {
  Internal: 'Internal Allocation',
};

export interface Connectors {
  hasGitOnly?: boolean;
  hasGithub?: boolean;
  hasGerrit?: boolean;
  hasGitHubOrJiraOrBugZilla?: boolean;
  hasConfluence?: boolean;
}
