// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT
export interface SearchProjectsQueryParams {
  pageSize?: number;
  offset?: number;
  orderBy?:
    | 'createddate'
    | 'name'
    | 'status'
    | 'renewalType'
    | 'projectSector'
    | 'projectType'
    | 'executiveDirector'
    | 'programManager';
  sortDir?: 'asc' | 'desc';
  name?: string[];
  description?: string[];
  status?: string[];
  projectType?: 'ProjectGroup' | 'Project';
  projectSector?: string[];
  repository?: string[];
  slug?: string[];
  type?: string[];
  id?: string[];
  enabledServices?: string[];
  rootProjects?: boolean;
  parentHierarchy?: boolean;
  testRecord?: boolean;
  hierarchyLevels?: boolean;
}
